/**
 * Generate style values according company name
 * @param companySlug
 */

export const generateBrandingValues = (companySlug) => {
  let logoFilename;
  let companyName;
  let companyUrl;
  let faviconIcon;
  let classroomUrl;
  let companyColor;

  switch (companySlug) {
    case 'enpp':
      logoFilename = "enpp.svg";
      companyName = "ENPP";
      companyUrl = "https://enppeduca.com";
      faviconIcon = "favicon_enpp.ico";
      classroomUrl = "https://aula.enppeduca.com";
      companyColor = '#e6263e';
      break;
    case 'ipappg':
      logoFilename = "ipappg.svg";
      companyName = "IPAPPG";
      companyUrl = "https://ipappg.edu.pe";
      faviconIcon = "favicon_ipappg.png";
      classroomUrl = "https://aula-ipappg.enppeduca.com";
      companyColor = '#182641';
      break;
    case 'pragmma':
      logoFilename = "pragmma.svg";
      companyName = "Pragmma";
      companyUrl = "https://pragmma.com";
      faviconIcon = "favicon_pragmma.png";
      classroomUrl = "https://aula-pragmma.enppeduca.com";
      companyColor = '#006cf2';
      break;
    default:
      logoFilename = "";
      companyName = "";
      companyUrl = "";
      faviconIcon = "";
      companyColor = '';
  }

  return {
    logoFilename: logoFilename,
    companyName: companyName,
    companyUrl: companyUrl,
    faviconIcon: faviconIcon,
    classroomUrl: classroomUrl,
    companyColor: companyColor
  };
};

/**
 * Set page favicon
 * @param icon
 */
export const setFavicon = (icon) => {
  let link = document.querySelector("link[rel=icon]");
  if (!link) {
    link = document.createElement("link");
    link.rel = "icon";
    document.getElementsByTagName("head")[0].appendChild(link);
  }
  link.setAttribute("href", `imgs/logos/${icon}`);
};
