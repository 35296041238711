import StudentFormAndBankAccounts from "../StudentFormAndBankAccounts/StudentFormAndBankAccounts";
import { useSelector } from "react-redux";
import { selectLanguage } from "../../../store/appInfoSlicer";
import { initializeTranslator } from "../../../helpers/language.helper";
import { useParams } from "react-router-dom";
import "./StudentFormPage.scss";

/**
 * Component
 * @returns {JSX.Element}
 */
const StudentFormPage = (props) => {
  // get language value from Redux state
  const language = useSelector(selectLanguage);

  // initialize translator with current language
  const t = initializeTranslator(language);

  // get route parameters
  const params = useParams();

  // ---------------------------------------
  // return component

  return (
    <div className="row justify-content-center m-0 student-form-page-component">
      <div className="col-12 col-sm-10 col-lg-7 pt-5 pb-5">
        <div className="title-wrapper">
          <h1 className="font-main">{t("studentData")}</h1>
        </div>
        <StudentFormAndBankAccounts
          companyUrl={props.companyUrl}
          showCourseSelector={props.showCourseSelector}
          showTermsConditions={false}
          courseCode={params.courseCode}
          showBankAccounts={props.showCourseSelector}
          companyName={props.companyName}
          submitDataButtonLabel={t("submitData")}
          onSubmitData={() => {}}
          onPageNumberChange={() => {}}
        />
      </div>
    </div>
  );
};

export default StudentFormPage;
