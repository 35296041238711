const TermsConditionsPragmma = (props) => {
    return (
        <div className="terms-conditions-component p-3">

            <h2>Política de contrataciones </h2>

            <p>Esta política de devoluciones, solo es aplicable si ya has
                comprado en PRAGMMA, a través de nuestro sitio web. Por eso, el presente
                documento contiene los términos de las políticas de devoluciones, bajo los
                cuales en PRAGMMA nos comprometemos a respetar y cuidar tus derechos como
                consumidor, según los lineamientos establecidos en el código de protección al
                consumidor; así que, tenemos la satisfacción de informar los aspectos legales,
                tus derechos y obligaciones desde el momento en que compras en esta web. </p>

            <h2>Política de devoluciones </h2>

            <p>El internet también tenemos el deber de respetar las
                normas que tutelan sus derechos y los nuestros, por eso, en Defensa de los
                Consumidores y Usuarios te informamos que, en lo referido a cursos, por ser un
                producto digital, sin soporte material de entrega presencial, se realizan
                devoluciones, solo bajo las siguientes situaciones, además debes de tener
                presente lo siguiente:</p>

            <p>Del cliente:<br/>
                Desde el momento en que un usuario realiza una compra en PRAGMMA, pasa a
                convertirse de un usuario visitante a un cliente.</p>

            <p>De los cursos digitales<br/>
                El cliente desde el momento que realiza la compra de los cursos de descarga
                digital que son de venta final, es decir, los que al ser pagados
                automáticamente se realiza la entrega digitalmente, una vez facilitado el
                acceso a curso digital es tuyo para siempre para tu uso y disfrute por lo que
                no podemos ofrecer devolución del importe pagado.</p>

            <p>Solo los cursos y/o productos que especifiquen claramente
                en la descripción del mismo que tienen garantía de devolución son susceptibles
                de dicha devolución si se cumplen las condiciones citadas en la misma garantía.</p>

            <p>Esta siempre será claramente visible en la página de
                compra y descripción del producto, servicio o curso por lo que el comprador
                puede y debe leer la garantía antes de realizar el pago.</p>

            <p>Si has adquirido un producto digital por error y es
                demostrable que no lo has descargado o accedido aún, puedes pedir la
                devolución. En este caso escribe a wsucasaire@pragmma.com con tu número de
                pedido para solicitarlo.</p>

            <h2>Descargo de responsabilidad </h2>

            <p>En reciprocidad a tu confianza, somos totalmente honestos
                contigo, explicándote a detalle todo lo que sucede desde el momento en que
                contratas los servicios de PRAGMMA o uno de mis infoproductos, a través del
                sitio web.</p>

            <p>Primero debes saber que no creemos ni propiciamos promesas
                de hacerte rico fácil y rápido y sin ningún esfuerzo de tu parte.</p>

            <p>Somos conscientes que todo éxito para lograr tus objetivos
                conlleva un trabajo detrás y los resultados no solo dependen de nosotros, sino
                también del tiempo que empleas y si pones en práctica o no nuestros lo
                aprendido en cada una de las lecciones del curso y recomendaciones.</p>

            <p>Sin embargo, por sí solas no pueden ofrecerte resultados,
                si no las usas estratégicamente como te enseñamos y de la misma forma si solo
                aplicamos nuestros consejos y no tomas en cuenta las herramientas que te
                sugerimos, no podemos garantizar los mismos resultados.</p>

            <h2>Propiedad intelectual </h2>

            <p>El sitio web de propiedad de PRAGMMA y todo su contenido
                (incluyendo – pero no limitado a – posts, imprimibles, ebooks, cursos, vídeos,
                etc.) contienen propiedad intelectual de propiedad de PRAGMMA, incluidas marcas
                de comercio, derechos de autor, información propietaria y otros derechos.</p>

            <p>Por lo tanto, de los productos que adquieres no puedes modificar,
                publicar, transmitir, participar en la transferencia o venta, crear obras
                derivativas, plagiar, copiar, hacer pasar el trabajo como tuyo, distribuir,
                exhibir, reproducir, o de ninguna manera explotar de ninguna forma y en ningún
                formato ninguna parte del contenido de este sitio, sus servicios o cualquier
                propiedad intelectual, en todo ni en parte, sin nuestro consentimiento previo.</p>

            <p>El contenido que incluye cada uno de los cursos de PRAGMMA
                es de autoría de sus tutores y solo ellos pueden disponer de los derechos
                morales y considerar y se exhiben en la plataforma de PRAGMMA por la cesión de
                derechos patrimoniales sobre el contenido creado exclusivamente para PRAGMMA.</p>

            <p>A consecuencia en PRAGMMA si te encuentras transgrediendo
                los derechos de propiedad intelectual, nos reservamos el derecho de remover
                inmediatamente de nuestros cursos, sin tener la obligación de reembolso, si
                eres descubierto violando nuestra propiedad intelectual y demandarte antes los
                tribunales correspondientes.</p>

            <h2>Del servicio de post venta </h2>

            <p>Este servicio es brindado por nuestro equipo de atención y
                bienestar del consumidor y nació con la finalidad de brindar una mejor
                experiencia al cliente y atender las necesidades que tienen nuestros clientes
                después de haber realizado compras en nuestras webs.</p>

            <p>El personal asignado para la atención post venta, es
                asignado aleatoriamente en PRAGMMA y no será exclusivo de una persona en
                específico, por lo tanto, el usuario puede darse por satisfecho, con la
                prestación del servicio prestado por cualquier persona asignada de nuestro
                equipo de atención y satisfacción del usuario.</p>

            <p>Si tienes alguna pregunta sobre mi disclaimer de productos
                y servicios, la información que te he recopilado en línea, las prácticas de mi
                sitio o sus relaciones con este sitio web, contáctame en correo
                electrónico:&nbsp;<a href="mailto:wsucasaire@pragmma.com">wsucasaire@pragmma.com</a></p>

            <p>Recuerda que solo los cursos y/o productos que
                especifiquen claramente en la descripción del mismo que tienen garantía de
                devolución son susceptibles de dicha devolución si se cumplen las condiciones
                citadas en la misma garantía.</p>

            <p>En otros casos, la devolución solo será aceptada si se
                cumplen todas las siguientes condiciones: el curso fué comprado por error, no
                se descargó ni consumió ningún tipo de contenido ni ninguna clase, han pasado
                menos de 7 días naturales desde la compra hasta la solicitud de devolución. Si
                alguna de estas condiciones no se cumple, no será posible concretar la
                devolución.</p>

            <p>Si tienes alguna pregunta sobre mi disclaimer de cursos, la
                información que te he recopilado en línea, las prácticas de mi sitio o sus
                relaciones con este sitio web, contáctame en: Correo electrónico: <a
                    href="mailto:wsucasaire@pragmma.com">wsucasaire@pragmma.com </a>
            </p>

        </div>
    );
};

export default TermsConditionsPragmma;
