import axios from "axios";
import { getEndpoint } from "./endpoint.helper";

/**
 * Fetch gateway token
 * @returns {Promise<object>}
 */
export const fetchGatewayValues = async (
  gatewayName,
  currency,
  language,
  company,
  courseCode,
  student,
  timeoutCallback,
  withInstallments = false,
  installmentNumber = 1,
  certificates = [],
  studentId = 0
) => {
  if (!company || !courseCode) return;

  // generate API endpoint
  let endpoint = getEndpoint("gatewayToken").replace(
    ":gatewayName",
    gatewayName
  );

  // perform request to fetch course data
  try {
    let response = await axios({
      method: "post",
      url: endpoint,
      timeout: 1000 * 60, // Wait for 60 seconds
      data: {
        currency: currency,
        language: language,
        company: company,
        courseCode: courseCode,
        student: student,
        withInstallments: withInstallments,
        installmentNumber: installmentNumber,
        certificates: certificates,
        studentId: studentId,
      },
    });
    // update state with response data
    return response.data;
  } catch (ex) {
    console.log(ex);
    timeoutCallback();
    return null;
  }
};
