import { useSelector } from "react-redux";
import { selectLanguage } from "../../../store/appInfoSlicer";
import { initializeTranslator } from "../../../helpers/language.helper";
import { companies } from "../../../store/enums";
import { useEffect, useState } from "react";
import "./Footer.scss";

/**
 * Component
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const Footer = (props) => {
  // ---------------------------------------
  // set state values

  const [urlTerms, setUrlTerms] = useState("");
  const [urlPrivacy, setUrlPrivacy] = useState("");
  const [urlCookies, setUrlCookies] = useState("");
  const [urlFaq, setUrlFaq] = useState("");
  const [urlComplaints, setUrlComplaints] = useState("");

  // ---------------------------------------
  // Lifecycle hooks

  // execute when component has been UPDATED
  useEffect(() => {
    if (props.companyName === companies.enpp) {
      setUrlTerms("https://www.enppeduca.com/terminos-condiciones/");
      setUrlPrivacy("https://www.enppeduca.com/politicas-privacidad/");
      setUrlCookies("https://www.enppeduca.com/politica-cookies/");
      setUrlFaq("https://www.enppeduca.com/preguntas-frecuentes/");
      setUrlComplaints("https://librodereclamacionesperu.com/773431127.html");
    }

    if (props.companyName === companies.ipappg) {
      setUrlTerms("https://ipappg.edu.pe/terminos-y-condiciones.html");
      setUrlPrivacy("https://ipappg.edu.pe/politica-privacidad.html");
      setUrlCookies("https://ipappg.edu.pe/politica-cookies.html");
      setUrlFaq("");
      setUrlComplaints("https://librodereclamacionesperu.com/65431777.html");
    }

    if (props.companyName === companies.pragmma) {
      setUrlTerms("https://pragmma.com/terminos-condiciones");
      setUrlPrivacy("https://pragmma.com/politicas-privacidad");
      setUrlCookies("https://pragmma.com/politica-cookies");
      setUrlFaq("");
      setUrlComplaints("https://librodereclamacionesperu.com/formulario.php?id=65431777");
    }
  }, [props.companyName]);

  // get language value from Redux state
  const language = useSelector(selectLanguage);

  // initialize translator with current language
  const t = initializeTranslator(language);

  return (
    <footer className="footer-component">
      <div className="container-lg font-main">
        <div className="row">
          <div className="col-12 col-lg-10 text-center text-lg-start menu-wrapper">
            {props.companyName ? (
              <ul className="menu text-center">
                <li>
                  <a href={urlTerms}>{t("termsConditions")}</a>
                </li>
                <li>
                  <a href={urlPrivacy}>{t("privacyPolicy")}</a>
                </li>
                <li>
                  <a href={urlCookies}>{t("cookiesPolicy")}</a>
                </li>
                {urlFaq ? (
                  <li>
                    <a href={urlFaq}>{t("faq")}</a>
                  </li>
                ) : null}
                <li>
                  <a href={urlComplaints}>{t("complaintsLog")}</a>
                </li>
              </ul>
            ) : null}
          </div>
          <div className="col-12 col-lg-2 text-center text-lg-end copyright-wrapper">
            <div className="copyright">
              &copy; {new Date().getFullYear()}{" "}
              {props.companyName ? props.companyName.toUpperCase() : ""}
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
