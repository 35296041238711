import {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {selectLanguage} from "../../../store/appInfoSlicer";
import {initializeTranslator} from "../../../helpers/language.helper";
import {deleteEnrollment, deletePaymentLog, fetchEnrollments, fetchEnrollmentInvoice} from "./helper";
import SelectMenu from "../../core/SelectMenu/SelectMenu";
import RoundButton from "../../core/RoundButton/RoundButton";
import SidebarPopup from "../SidebarPopup/SidebarPopup";
import FileExporter from "../toolbox/FileExporter/FileExporter";
import Pagination from "../../core/Pagination/Pagination";
import Spinner from "../../core/Spinner/Spinner";
import ActionsMenu from "../../core/ActionsMenu/ActionsMenu";
import ModalDialog from "../../core/ModalDialog/ModalDialog";
import EnrollmentStatusForm
  from "../toolbox/EnrollmentStatusForm/EnrollmentStatusForm";
import InvoiceIssuing from '../toolbox/InvoiceIssuing/InvoiceIssuing';  
import InvoiceDetails from '../toolbox/InvoiceDetails/InvoiceDetails';  
import {useParams} from "react-router-dom";
import ToastNotification, {
  notificationIcons,
} from "../../core/ToastNotification/ToastNotification";
import {companies, paymentMethods} from "../../../store/enums";
import Message, {messageIcons} from "../../core/Message/Message";
import {formatTimestampToDateString} from "../helpers/helpers";
import "./Enrollments.scss";

/**
 * Component
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const Enrollments = (props) => {

  const language = useSelector(selectLanguage);
  const t = initializeTranslator(language);
  const { company } = useParams();

  // ---------------------------------------
  // set state values

  const [isENPP, setIsENPP] = useState(false);
  const [sidebarIsVisible, setSidebarIsVisible] = useState(false);
  const [invoiceIssuingSidebarIsVisible, setInvoiceIssuingSidebarIsVisible] = useState(false);
  const [invoiceDetailsSidebarIsVisible, setInvoiceDetailsSidebarIsVisible] = useState(false);
  const [activeInvoice, setActiveInvoice] = useState(null);
  const [invoiceTypeTitle, setInvoiceTypeTitle] = useState('');
  const [enrollments, setEnrollments] = useState({ items: [], pagesCount: 1 });
  const [page, setPage] = useState(1);
  const [criteria, setCriteria] = useState("");
  const [criteriaIsDirty, setCriteriaIsDirty] = useState(false);
  const [courseCode, setCourseCode] = useState("");
  const [status, setStatus] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showFilterInfo, setShowFilterInfo] = useState(false);
  const [editStatuDialogIsVisible, setEditStatuDialogIsVisible] = useState(false);
  const [activeEnrollment, setActiveEnrollment] = useState(null);
  const [companyStyles, setCompanyStyles] = useState({});
  const [editStatusTitle, setEditStatusTitle] = useState('');
  const [editStatus, setEditStatus] = useState(true);

  // notification

  const [notificationTitle, setNotificationTitle] = useState("");
  const [notificationMessage, setNotificationMessage] = useState("");
  const [notificationIsVisible, setNotificationIsVisible] = useState(false);

  // confirmation

  const [deletionConfirmationIsVisible, setDeletionConfirmationIsVisible] =
    useState(false);

  // Lifecycle hooks
  // ---------------------------------------

  useEffect(() => {
    if (company === companies.enpp) {
      setIsENPP(true);
    }
  }, [company]);


  // hook to be executed when component has been UPDATED,
  // when page, courseCode or status filter change fetch search results
  useEffect(
    () => {
      fetchEnrollmentsFromServer();
    }, // changes in criteria are not detected automatically
    // because it would fetch search results everytime a
    // key was typed
    [page, courseCode, props.courses, status]
  );

  // hook to be executed when component has been UPDATED,
  // when criteria as been cleared and is dirty
  // (it has been changed by user), fetch search results
  useEffect(() => {
    if (criteriaIsDirty && !criteria) {
      fetchEnrollmentsFromServer();
    }

    // update component state,
    // update flag to indicate criteria is dirty
    setCriteriaIsDirty(true);
  }, [criteria]);

  // ---------------------------------------
  // Functions

  const handleKeyDownSearchInput = async (e) => {

    if (e.key === 'Enter' ) {

      onSearch()
    }
  }

  /**
   * Fetch enrollments from server
   * @returns {Promise<void>}
   */
  const fetchEnrollmentsFromServer = async () => {

    // update component state

    setIsLoading(true);

    // fetch enrollments from server

    let _enrollments = await fetchEnrollments(
      props.companyName,
      page,
      criteria,
      courseCode,
      status,
      ""
    );

    // when items did not load, initialized it with an empty array

    if (!_enrollments.items) {
      _enrollments.items = [];
    }

    // add courses information to enrollments
    
    _enrollments.items = _enrollments.items.map(enrollment => {
      const matchingCourse = props.courses.find(c => c.code == enrollment.course_code);
      return {
        ...enrollment,
        course: matchingCourse ? matchingCourse : {}
      };
    });

    // update component state
    setEnrollments(_enrollments);

    // if a filter is set, show filter information

    if (criteria || courseCode || status) {
      // update component state
      setShowFilterInfo(true);
    }

    // update component state
    setIsLoading(false);
  };

  /**
   * Event handler for Close event in sidebar
   */
  const onSidebarClose = () => {
    // update component state
    setSidebarIsVisible(false);
  };

  /**
   * Event handler for Click event in clear filters button
   */
  const onClearFiltersClick = () => {
    // update component state
    setCriteria("");
    setCourseCode("");
    setStatus("");
    setPage(1);
    setShowFilterInfo(false);
  };

  /**
   * Event handler for Search event
   */
  const onSearch = () => {
    // refresh enrollments
    fetchEnrollmentsFromServer();
  };

  /**
   * Event handler for PageChange event
   */
  const onPageChange = (_page) => {
    // update compoent state
    setPage(_page);
  };

  /**
   * Event handler for Close event in Modal Dialog when
   * data was submitted successfully
   */
  const onModalDialogCloseAfterSave = () => {
    // update component state
    setEditStatuDialogIsVisible(false);

    // notify user
    showNotification("", t("dataSentSuccessfully"));

    // refresh enrollments
    fetchEnrollmentsFromServer();
  };

  /**
   * Show notification
   */
  const showNotification = (title, message) => {
    // update component state
    setNotificationTitle(title);
    setNotificationMessage(message);
    setNotificationIsVisible(true);
  };

  /**
   * Callback to be called after notification has been closed
   */
  const onNotificationClose = () => {
    setNotificationIsVisible(false);
  };

  /**
   * Event handler for ActiveEnrollmentChange event
   */
  const onActiveEnrollmentChange = (_enrollment, title, editStatus) => {
    // update component state
    setEditStatusTitle(title);
    setActiveEnrollment(_enrollment);
    setEditStatuDialogIsVisible(true);
    setEditStatus(editStatus);
  };

  /**
   * Show message to confirm item deletion
   */
  const confirmItemDeletion = (_enrollment) => {
    setActiveEnrollment(_enrollment);
    setDeletionConfirmationIsVisible(true);
  };

  /**
   * Show issue invoice sidebar
   * @param _enrollment
   */
  const showBillingInformation = async (_enrollment) => {

    setActiveEnrollment(_enrollment);
    let invoice = await fetchEnrollmentInvoice(_enrollment.enrollment_id);
    
    // Check whether the enrollment has an invoice or not

    if (invoice) {

      const number = invoice.invoice_number.padStart(4, '0');
      const title = invoice.invoice_type == 'boleta'      
        ? `Boleta de Venta ${number}`
        : `Factura ${number}`

      setInvoiceTypeTitle(title);
      setActiveInvoice(invoice);
      setInvoiceDetailsSidebarIsVisible(true);

    } else {
      
      setActiveInvoice(null);
      setInvoiceIssuingSidebarIsVisible(true);
    }
  }

  /**
   * Delete enrollment or payment log
   */
  const deleteEnrollmentOrPaymentLog = async () => {
    // update component state
    setIsLoading(true);

    // delete enrollment (student-course)
    if (activeEnrollment.enrollment_id) {
      await deleteEnrollment(activeEnrollment.enrollment_id);
    } else {
      // delete payment_log
      await deletePaymentLog(activeEnrollment.payment_log_id);
    }

    // update component state
    setDeletionConfirmationIsVisible(false);

    // refresh enrollments
    await fetchEnrollmentsFromServer();
  };

  useEffect(() => {
    if (isENPP) {
      setCompanyStyles({});
    }
  }, [isENPP]);

  // ---------------------------------------
  // return component

  return (
    <div className="enrollments-component container-lg">

      {/* Loading spinner */}
      {isLoading ? <Spinner /> : null}

      <div className="row">
        {/*-----------------------------------------------------------------
            Search area */}

        <div className="col-12">
          
          {/* Title */}

          <div className="section-title-wrapper">
            <h1>{t("enrollments")}</h1>
          </div>

          {/* Search input wrapper */}

          <div className="search-field-wrapper">
            <img className="icon" src="imgs/icons/search.svg" alt="" />
            <input
              type="text"
              value={criteria}
              onChange={(e) => setCriteria(e.target.value)}
              onKeyDown={handleKeyDownSearchInput}
              placeholder={t("searchByStudentNameEmail")}
            />
            {criteria ? (
              <img
                onClick={onSearch}
                className="search"
                src="imgs/icons/arrow-right-red.svg"
                alt=""
              />
            ) : null}
          </div>
        </div>

        {/*-----------------------------------------------------------------
            Filter */}

        <div className="col-12">
          <div className="row filter-wrapper">
            <div className="col-12 col-md-6 col-lg-4 col-xl-3">
              {/* Course filter */}
              <span className="control-label">{t("courseDiploma")}</span>
              <SelectMenu
                description={t("selectAnOption")}
                backgroundColor={"white"}
                descriptionStyle={{ color: "#94989C", fontSize: "0.9375rem" }}
                selectedItem={null}
                optionSelectedCallback={(v) => setCourseCode(v)}
                filterIsvisible={true}
                options={props.coursesOptions}
              />
            </div>
            <div className="col-12 col-md-6 col-lg-4 col-xl-3">
              {/* Status filter */}
              <span className="control-label">{t("filterByStatus")}</span>
              <SelectMenu
                description={t("selectAnOption")}
                backgroundColor={"white"}
                descriptionStyle={{ color: "#94989C", fontSize: "0.9375rem" }}
                selectedItem={null}
                optionSelectedCallback={(v) => setStatus(v)}
                options={[
                  { value: "", text: t("all") },
                  {
                    value: "pending-installments",
                    text: t("paymentWithInstallments"),
                  },
                  { value: "pending", text: t("pending") },
                  { value: "payed", text: t("payed") },
                ]}
              />
            </div>

            {/* Empty column space */}

            <div className="d-none d-xl-block col-xl-3" />

            <div
              className="col-12 col-md-6 col-lg-4 col-xl-3"
              style={{ paddingTop: "2.2rem" }}
            >
              {/* Download button */}

              <RoundButton
                onClick={() => setSidebarIsVisible(true)}
                width={"100%"}
                height={"2.5rem"}
                outline={false}
                content={t("downloadReport")}
              />
            </div>
          </div>
        </div>

        {/*-----------------------------------------------------------------
            Search results summary */}

        <div className="col-12">
          <div
            className="inline-notification default"
            dangerouslySetInnerHTML={{
              __html: t("showingResults").replace(
                "{0}",
                `<strong>${enrollments.itemsTotalCount}</strong>`
              ),
            }}
          />
        </div>

        {/* Button to clear filters */}

        <div className="col-12">
          {showFilterInfo ? (
            <button
              type="button"
              onClick={onClearFiltersClick}
              className="button clear-filter"
            >
              <img src="imgs/icons/close-red.svg" alt="" />
              {t("clearFilters")}
            </button>
          ) : (
            <div className="pt-4" />
          )}
        </div>

        {/*-----------------------------------------------------------------
            Table */}

        <div className="col-12">
          {/* Table wrapper */}
          <div className="content-wrapper">
            <table className="table">
              <thead>
                <tr>
                  <th className="column-student" scope="col" style={{}}>
                    {t("name")}
                  </th>
                  <th className="column-course" scope="col">
                    {t("courseDiploma")}
                  </th>
                  { company !== companies.pragmma
                      ? <th className="column-date" scope="col">
                        {t("date")}
                      </th>
                      : null
                  }

                  <th className="column-amount" scope="col">
                    {t("amount")}
                  </th>

                  { company !== companies.pragmma
                      ? <th className="column-status" scope="col">
                        {t("status")}
                      </th>
                      : null
                  }
                  { company !== companies.pragmma
                    ? <th className="column-status" scope="col">
                        {t("certificates")}
                      </th>
                    : null
                  }
                  { company === companies.pragmma
                      ? <th className="column-category" scope="col">
                        Categoría
                      </th>
                      : null
                  }
                  <th className="column-actions" scope="col">
                    {t("actions")}
                  </th>
                </tr>
              </thead>
              <tbody>
                {enrollments.items.map(e => {
                  return (
                    <tr key={e.payment_log_id}>
                      <td className="column-student">
                        {e.student}
                      </td>
                      <td className="column-course">
                        {e.course_name}
                        <br/>
                        <span className="chip purple">
                        {e.course_mode 
                          ? e.course_mode.charAt(0).toUpperCase() + e.course_mode.slice(1)
                          : null
                        }
                        </span>
                      </td>

                      { company !== companies.pragmma
                          ? <>{e.payed === 1 ? (
                                <td className="column-date">
                                  {!!e.operation_datetime
                                      ? formatTimestampToDateString(e.operation_datetime)
                                      : formatTimestampToDateString(e.created_at)}
                                </td>
                            ) : (
                                <td className="column-date"></td>
                            )}</>
                          : null
                      }

                      {e.amount ? (
                        <td className="column-amount">S/. {e.amount}</td>
                      ) : (
                        <td className="column-amount"></td>
                      )}
                      { company !== companies.pragmma
                          ? <td className="column-status">
                            {/* Status icon */}
                            <div
                                className={
                                  e.payed
                                      ? "status-icon green me-2"
                                      : e.installment_number >= 2
                                          ? "status-icon blue me-2"
                                          : "status-icon red me-2"
                                }
                            />
                            {e.payed
                                ? t("payed")
                                : e.installment_number >= 2
                                    ? t("paymentInInstallments")
                                    : t("pending")}
                          </td>
                          : null
                      }

                      { company !== companies.pragmma
                          ? <td className="column-certificates">

                            {/* Certificates icon */}
                            <div
                                className={
                                  e.certificates
                                      ? "status-icon green me-2"
                                      : ""
                                }
                            />
                            {e.certificates
                                ? t("payed")
                                : ''
                            }
                          </td>
                          : null
                      }
                      { company === companies.pragmma
                        ? <td className="column-category text-capitalize">
                            {e.course.category}
                          </td>
                        : null
                      }
                      <td className="column-actions ps-3 pt-3 border-0">
                        <div className="d-flex justify-content-center">
                        <ActionsMenu
                          options={[
                            // edit status option is going to be available only when enrollment is not paid yet
                            {
                              text: t("editStatus"),
                              isEnabled: !e.payed,
                              callback: () => onActiveEnrollmentChange(e, t('editStatus'), true),
                            },
                            {
                              text: t('billing'),
                              isEnabled: e.payed,
                              callback: () => showBillingInformation(e)
                            }  
                            ,
                            company === companies.pragmma
                              ? null
                              : {
                                  text: t("addCertificates"),
                                  isEnabled: e.payed,
                                  callback: () => onActiveEnrollmentChange(e, t('addCertificates'), false),
                                },
                            {
                              text: t("seeDetails"),
                              isEnabled: true,
                              callback: () => {
                                props.activeEnrollmentChanged(e);
                              },
                            },
                            // delete option is active only when payment method is bank transfer
                            {
                              text: t("delete"),
                              isEnabled:
                                e.payment_method === paymentMethods.transfer,
                              callback: () => {
                                confirmItemDeletion(e);
                              },
                            },
                          ]}
                        />
                        </div>
                        
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {/*-----------------------------------------------------------------
        Pagination  */}

      <div className="row">
        <div className="col-12 d-flex justify-content-center pagination-wrapper">
          <Pagination
            pageChange={(pageNumber) => onPageChange(pageNumber)}
            pagesCount={enrollments.pagesCount}
            activePage={page}
          />
        </div>
      </div>

      {/* ---------------------------------------
        Components for notifications, dialogs, popups, etc */}

      {/* Modal dialog to edit enrollment status */}

      {editStatuDialogIsVisible ? (
        <ModalDialog
          panelStyle={{width: '80%'}}
          close={() => setEditStatuDialogIsVisible(false)}
          content={
            <EnrollmentStatusForm
              title={editStatusTitle}
              editStatus={editStatus}
              companyName={props.companyName}
              closeModal={() => setEditStatuDialogIsVisible(false)}
              closeModalAfterSave={onModalDialogCloseAfterSave}
              enrollment={activeEnrollment}
              showNotification={showNotification}
            />
          }
        />
      ) : null}

      {/* Notification toast */}

      <ToastNotification
        icon={notificationIcons.check}
        title={notificationTitle}
        message={notificationMessage}
        isVisible={notificationIsVisible}
        closeCallback={onNotificationClose}
      />

      {/* Sidebar popup for reports exports */}

      <SidebarPopup
        isVisible={sidebarIsVisible}
        closeCallback={onSidebarClose}
        title={t("downloadReport")}
        content={
          <FileExporter
            courses={props.courses}
            companyName={company}
          />
        }
      />

      {/* Sidebar popup for invoice issuing */}  

      <SidebarPopup
        isVisible={invoiceIssuingSidebarIsVisible}
        closeCallback={() => setInvoiceIssuingSidebarIsVisible(false)}
        title={t('issueInvoice')}
        content={
          activeEnrollment 
            ? <InvoiceIssuing
                closeCallback={() => setInvoiceIssuingSidebarIsVisible(false)} 
                enrollment={activeEnrollment}/> 
            : null
        }
      />

      {/* Sidebar popup for invoice details and voiding */}

      <SidebarPopup 
        isVisible={invoiceDetailsSidebarIsVisible}
        closeCallback={() => setInvoiceDetailsSidebarIsVisible(false)}
        title={invoiceTypeTitle}
        content={
          activeInvoice 
          ? <InvoiceDetails 
              closeCallback={() => setInvoiceDetailsSidebarIsVisible(false)}  
              invoice={activeInvoice} /> 
          : null
        }
      />

      {/* Modal dialog to confirm item deletion */}

      {deletionConfirmationIsVisible ? (
        <Message
          icon={messageIcons.error}
          title={t("delete")}
          showCloseButton={true}
          onClose={() => setDeletionConfirmationIsVisible(false)}
          description={t("deleteConfirmation")}
          okText={t("delete")}
          okCallback={() => deleteEnrollmentOrPaymentLog()}
        />
      ) : null}
    </div>
  );
};

export default Enrollments;
