/**
 * Verfify whether email structure is valid or not
 * @param email
 * @returns {boolean}
 */
export const mailLooksValid = (email) => {
  // regex base on  RFC 2822
  let regex =
    /[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?/;
  return regex.test(email);
};

/**
 * Validation if provided values have content
 * @param values
 */
export const valuesHaveContent = (values) => {
  let valid = true;

  values.forEach((value) => {
    if (!value) valid = false;
  });

  return valid;
};
