import { atom } from 'recoil';

export const toastValuesState = atom({
    key: 'toastValuesState',
    default: {
        icon: '',
        title: '',
        message: '',
        isVisible: false,
        closeCallback: () => {}
    },
});
