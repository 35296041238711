const telephoneCodes = {
  argentina: "54",
  bolivia: "591",
  brazil: "55",
  chile: "56",
  colombia: "57",
  "costa-rica": "506",
  ecuador: "593",
  "el-salvador": "503",
  espana: "34",
  eeuu: "1",
  guyana: "592",
  grenada: "1-473",
  guatemala: "502",
  haiti: "509",
  honduras: "504",
  jamaica: "1-876",
  mexico: "52",
  nicaragua: "505",
  panama: "507",
  paraguay: "595",
  peru: "51",
  "puerto-rico": "1-787",
  "republica-dominicana": "1-809",
  suriname: "597",
  uruguay: "598",
};

export default telephoneCodes;
