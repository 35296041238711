import { initializeTranslator } from "../../../../helpers/language.helper";
import { useSelector } from "react-redux";
import { selectLanguage } from "../../../../store/appInfoSlicer";
import { useParams } from "react-router-dom";
import {companies} from "../../../../store/enums";

/**
 * Component
 * @returns {JSX.Element}
 * @constructor
 */
const YapeInformation = () => {

  const params = useParams();

  // get contact email address

  let email;
  if (params.company == companies.ipappg) {
    email = process.env.REACT_APP_IPAPPG_CONTACT_EMAIL;
  }

  if (params.company == companies.enpp) {
    email = process.env.REACT_APP_ENPP_CONTACT_EMAIL;
  }

  // get language value from Redux state
  
  const language = useSelector(selectLanguage);

  // initialize translator with current language

  const t = initializeTranslator(language);
  const yapeDescription = t("yapeDescription").replaceAll('__EMAIL__', email);

  // return component

  return (
    <div>

      <p className="mt-4 d-block description"
         dangerouslySetInnerHTML={{ __html: yapeDescription }} />

      <div className="qrcode-wrapper mt-5">
        <img
          src={`imgs/qrcode_yape_${params.company}.png`}
          style={{ width: "10.625rem" }}
          alt=""
        />
      </div>
    </div>
  );
};

export default YapeInformation;
