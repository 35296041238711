import { goTo } from "./url.helper";
import { default as en } from "../assets/langs/en";
import { default as es } from "../assets/langs/es";
import { storageKeys } from "../store/enums";

/**
 * Detect and redirect page according browser language
 * @param {string} defaultLang default language code
 * @param {Array} available available website languages
 * @param redirect
 */
export const detect = (defaultLang, available, redirect) => {
  let navigatorLanguage = window.navigator.language.substring(0, 2);
  let pageLanguage = getPageLanguage();
  let userPreferredLanguage = getStoredLanguage();

  if (!userPreferredLanguage) {
    if (pageLanguage !== navigatorLanguage) {
      // redirect to navigator language
      // if it is available
      let langIsAvailable = available.indexOf(navigatorLanguage) > -1;
      if (langIsAvailable)
        changeLanguage(navigatorLanguage, defaultLang, redirect);
    }
  }
};

/**
 * Save and redirect to language
 * @param {string} defaultLang default language two digits code: en, es, pt, etc...
 * @param {string} language two digits code: en, es, pt, etc...
 * @param {boolean} redirect
 */
export const changeLanguage = (language, defaultLang, redirect) => {
  // default language code it is empty in url
  if (defaultLang === language) language = "";

  // store preferred language
  storeLanguage(language);

  // redirect to language base url
  if (redirect) goTo(language);
};

/**
 * Get language code from html element
 * @returns {string}
 */
export const getPageLanguage = () => {
  let lang = document.querySelector("html").getAttribute("lang");

  return lang ? lang.substr(0, 2) : null;
};

/**
 * Get saved preferred language
 */
export const getStoredLanguage = () => {
  return localStorage.getItem(storageKeys.language);
};

/**
 * Store preferred language
 */
export const storeLanguage = (lang) => {
  localStorage.setItem(storageKeys.language, lang);

  // save app language in global variable, to be
  // used in external (outside React) Javascript files
  window.REACT_APP_LANGUAGE = getStoredLanguage();
};

/**
 * Return a function to retrieve language
 * strings from an specific language
 * @param lang
 * @returns {function(*): *}
 */
export const initializeTranslator = (lang) => {
  let languageStrings;
  if (lang === "es") languageStrings = es;
  if (lang === "en") languageStrings = en;

  return (key) => {
    return languageStrings ? languageStrings[key] || key : key;
  };
};
