import { currencies, languages } from "../store/enums";

/**
 * Get currency symbol from currency code, return currency
 * code in lowercase  if no symbol is found
 * @returns {string}
 */
export const getCurrencySymbol = (currencyCode) => {
  let currencySymbol;
  switch (currencyCode.toLowerCase()) {
    case currencies.pen:
      currencySymbol = "S/";
      break;
    case currencies.usd:
      currencySymbol = "US$";
      break;
    case currencies.mxn:
      currencySymbol = "MXN";
      break;
    default:
      currencySymbol = currencyCode.toLowerCase();
      break;
  }

  return currencySymbol;
};

/**
 * Get currency name from currency code
 */
export const getCurrencyName = (currencyCode, language) => {
  let name;

  if (currencyCode === currencies.pen && language === languages.es) {
    name = "Soles";
  }

  if (currencyCode === currencies.pen && language === languages.en) {
    name = "Peruvian Soles";
  }

  if (currencyCode === currencies.usd && language === languages.es) {
    name = "Dólares";
  }

  if (currencyCode === currencies.usd && language === languages.en) {
    name = "Dollars";
  }

  if (currencyCode === currencies.mxn && language === languages.es) {
    name = "Pesos Mexicanos";
  }

  if (currencyCode === currencies.mxn && language === languages.en) {
    name = "Mexican pesos";
  }

  return name;
};
