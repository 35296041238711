import "moment/locale/es";
import "./CipCode.scss";

/**
 * Component
 * @returns {JSX.Element}
 * @constructor
 */
const CipCode = (props) => {

  // return component

  return (
    <div className="cipcode-component font-main row justify-content-center m-0">
      <iframe src={props.cipUrl} frameBorder="0" />
    </div>
  );
};

export default CipCode;
