import { useSelector } from "react-redux";
import { selectCurrency, selectLanguage } from "../../../store/appInfoSlicer";
import { initializeTranslator } from "../../../helpers/language.helper";
import "./TotalPrice.scss";

/**
 * Component
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const TotalPrice = (props) => {
  let symbol = props.symbol || "S/";

  // get values from Redux state
  const language = useSelector(selectLanguage);
  const currency = useSelector(selectCurrency);

  // initialize translator with current language
  const t = initializeTranslator(language);

  let styleInteger = {
    fontSize: props.sizeInteger ?? "1.60829375rem",
  };

  let styleDecimal = {
    fontSize: props.sizeDecimal ?? "1.2rem",
  };

  // separate price in integer and decimal parts
  // to show them in different elements
  let priceParts = ["", ""];
  if (props.price) {
    priceParts = (+props.price).toFixed(2).split(".");
    if (typeof priceParts[1] == "undefined") priceParts.push("00");
  }

  // return component
  return (
    <div style={styleInteger} className="total-price-component">
      {symbol} {priceParts[0]}
      <span style={styleDecimal} className="decimal">
        .{priceParts[1].substring(0, 2)}
      </span>
    </div>
  );
};

export default TotalPrice;
