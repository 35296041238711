import {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {selectLanguage} from "../../../store/appInfoSlicer";
import {initializeTranslator} from "../../../helpers/language.helper";
import RoundButton from "../../core/RoundButton/RoundButton";
import SelectMenu from "../../core/SelectMenu/SelectMenu";
import {submitCourse} from "../../../helpers/courses.helper";
import ToastNotification, {
  notificationIcons,
} from "../../core/ToastNotification/ToastNotification";
import "./CourseDetails.scss";
import {companies} from "../../../store/enums";
import ToggleSwitch from "../../core/ToggleSwitch/ToggleSwitch";
import {courseSchema} from "../../../schemas/courseSchema";
import {useParams} from "react-router-dom";


/**
 * 
 * Component
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const CourseDetails = ({
  course,
  backButtonClicked,
  categories,
  types,
  companyName,
}) => {


  // get values from Redux state

  const language = useSelector(selectLanguage);

  // initialize translator with current language

  const t = initializeTranslator(language);

  // get route parameters

  const params = useParams();

  // ---------------------------------------
  // set state values

  const [categoriesOptions, setCategoriesOptions] = useState([]);
  const [typesOptions, setTypesOptions] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(0);
  const [selectedType, setSelectedType] = useState(0);
  const [selectedMode, setSelectedMode] = useState('');
  const [notificationIsVisible, setNotificationIsVisible] = useState(false);
  const [validationMessage, setValidationMessage] = useState(null);
  const [validationNotificationIsVisible,
  setValidationNotificationIsVisible] = useState(false);

  let [courseData, setCourseData] = useState(course)

  // ---------------------------------------
  // Lifecycle hooks

  // hook to be executed when component has been UPDATED

  useEffect(() => {
    showCourseValues();
  }, [course]);

  // hook to be executed when component has been UPDATED

  useEffect(() => {

    // Generate options for course categories

    if (categories) {
      let _categoriesOptions = [];
      categories.forEach((category) => {
        _categoriesOptions.push({
          text: category.name.replaceAll("-", " ").toUpperCase(),
          value: category.id,
        });
      });

      // update component state

      setCategoriesOptions(_categoriesOptions);
    }

    // Generate options for course types

    if (types) {
      let _typesOptions = [];
      types.forEach((type) => {
        _typesOptions.push({
          text: type.name.replaceAll('-', ' ').toUpperCase(),
          value: type.id,
        });
      });

      // update component state

      setTypesOptions(_typesOptions);
    }

    showCourseValues();

  }, [categories, types]);

  // ---------------------------------------
  // functions

  const showCourseValues = () => {
    setSelectedType(course.type_id);
    setSelectedCategory(course.category_id);
    setSelectedMode(course.mode);
  };

  const saveCourse = async () => {
    
    try {

      // Validation

      await courseSchema.validate(courseData);

      // Prepare and submit course to be saved

      const companyIds = {
        [companies.enpp]: 1,
        [companies.ipappg]: 2,
        [companies.pragmma]: 3,
      };

      let { success, message } = await submitCourse({
        ...courseData,
        category_id: selectedCategory,
        type_id: selectedType,
        company_id: companyIds[params.company],
        mode: selectedMode
      });

    
      // show notification and rediret to courses list

      if (success) {

        setNotificationIsVisible(true);
        setTimeout(() => {
          
          setNotificationIsVisible(false);
          window.location.reload();

        }, 3000);

      } else {
        showError(message)
      }

    } catch (ex) {
      console.log(ex)
      if (ex.errors) {
        showError(ex.errors.join(',<br/>'))
      }
    }
  };

  /**
   * Show notification with error message
   */
  const showError = (message) => {

    if (message == 'item_already_exists') {
      message = 'Ya existe otro curso con el mismo nombre y modalidad'
    }

    setValidationMessage(message);
    setValidationNotificationIsVisible(true);
    setTimeout(() => setValidationNotificationIsVisible(false), 2000);
  }

  /**
   * Handle changes of form inputs
   * @param event
   */
  const handleChange = (event) => {

    let { name, value, type } = event.target;

    if (type == 'checkbox') {
      value = event.target.checked ?? false
    }

    setCourseData({
      ...courseData,
      [name]: value
    });
  };

  // ---------------------------------------
  // return component

  return (
    <div className="course-details-component container-lg">

      {/*-----------------------------------------------------------------
        Title */}

      <div className="row section-title-wrapper">

        {/* Title area */}

        <div className="col-12 col-md-6 d-flex align-content-center align-items-center">
          <img
            className="back-button"
            onClick={backButtonClicked}
            src="imgs/icons/arrow-left-red.svg"
            alt=""
          />
          {course.name
              ? <h1>{t("courseDetails").replace("{0}", course.name)}</h1>
              : <h1>{t('new')} {t('course')}</h1>
          }

        </div>

        {/* Column empty space */}

        <div className="d-none d-md-block col-md-3" />

        {/* Edit button area */}

        <div className="col-12 col-md-3">
          <RoundButton
            onClick={() => saveCourse()}
            width={"100%"}
            height={"2.5rem"}
            outline={false}
            content={t('saveChanges')}
          />
        </div>
      </div>

      <div className="row">

        {/*-----------------------------------------------------------------
            Content */}


        <div className="col-12">

          <form className="content-wrapper details-panel default-form">
            <div className="row mb-3">
              <div className="col-12 col-md-4 mb-4 pb-2">
                <span className="label">
                  {t('code')}
                </span>
                <div className="input-wrapper w-75">
                  <input
                      name="code"
                      readOnly
                      value={courseData.code}
                      onChange={handleChange}
                  />
                </div>

              </div>
              <div className="col-12 col-md-4 mb-4 pb-2">
                <span className="label">
                  {t('active')}
                </span>
                <br/>
                <ToggleSwitch
                    optionLabels={['Sí', 'No']}
                    color="tomato"
                    id="is-active-switch"
                    name="is_active"
                    checked={courseData.is_active}
                    onChange={handleChange}/>
              </div>
              <div className="col-12 col-md-4 mb-4 pb-2">
                <span className="label">
                  {t('showInPreenrollment')}
                </span>
                <br/>
                <ToggleSwitch
                    optionLabels={['Sí', 'No']}
                    color="tomato"
                    id="is-featured-switch"
                    name="is_featured"
                    checked={courseData.is_featured}
                    onChange={handleChange}/>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-4 mb-4 pb-2">
                <span className="label">{t("name")}</span>
                <br/>
                <div className="input-wrapper w-75">
                  <input
                      name="name"
                      value={courseData.name}
                      onChange={handleChange}
                  />
                </div>
              </div>

              <div className="col-12 col-md-4 mb-4 pb-2">
                <span className="label">{t("salePrice")} (S/)</span>
                <br/>
                <div className="input-wrapper w-75">
                  <input
                      name="price"
                      value={courseData.price}
                      onChange={handleChange}
                  />
                </div>
              </div>

              <div className="col-12 col-md-4 mb-4 pb-2">

              </div>
            </div>

            <div className="row">
              <div className="col-12 col-md-4 mb-4 pb-2">
                <span className="label">{t("category")}</span>

                <div className="w-75">
                  <SelectMenu
                      description={t("selectAnOption")}
                      selectedItem={{value: course.category_id}}
                      optionSelectedCallback={(category) => {
                        setSelectedCategory(category);
                      }}
                      options={categoriesOptions}
                  />
                </div>
              </div>

              <div className="col-12 col-md-4 mb-4 pb-2">
                <span className="label">{t("type")}</span>
                <div className="w-75">
                  <SelectMenu
                      description={t("selectAnOption")}
                      selectedItem={{value: courseData.type_id}}
                      optionSelectedCallback={type => {
                        setSelectedType(type);
                      }}
                      options={typesOptions}
                  />
                </div>
              </div>


              <div className="col-12 col-md-4 mb-4 pb-2">
                <span className="label">{t("mode")}</span>
                <div className="w-75">
                  <SelectMenu
                      description={t('selectAnOption')}
                      selectedItem={{value: course.mode}}
                      optionSelectedCallback={mode => {
                        setSelectedMode(mode);
                      }}
                      options={[
                        {value: "grabado", text: "Grabado"},
                        {value: "vivo", text: "En vivo"},
                        {value: "presencial", text: "Presencial"},
                      ]}
                  />
                </div>
              </div>

            </div>
          </form>
        </div>
      </div>

      {/* Notification toast */}

      {notificationIsVisible ? (
          <ToastNotification
              icon={notificationIcons.check}
              title={t("saved")}
              message={t("dataSentSuccessfully")}
              isVisible={notificationIsVisible}
              closeCallback={() => {
                setNotificationIsVisible(false);
              }}
          />
      ) : null}

      {/* Notification toast */}

      <ToastNotification
          icon={notificationIcons.error}
          title={t('couldNotSave')}
          message={validationMessage}
          isVisible={validationNotificationIsVisible}
          closeCallback={() => setValidationNotificationIsVisible(false)}
      />
    </div>
  );
};

export default CourseDetails;
