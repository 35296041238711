import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  generateBrandingValues,
  setFavicon,
} from "../../../helpers/branding.helper";
import { fetchCourse, loadCssVariablesFile } from "./helpers";
import { useDispatch, useSelector } from "react-redux";
import {
  changeCurrency, changeUserHasSetCurrency,
  selectCurrency,
  selectLanguage,
} from "../../../store/appInfoSlicer";
import { initializeTranslator } from "../../../helpers/language.helper";
import {companies, sections, storageKeys} from "../../../store/enums";
import { getOriginValues } from "../../../helpers/url.helper";
import Header from "../../core/Header/Header";
import Content from "../Content/Content";
import Confirmation from "../Confirmation/Confirmation";
import Spinner from "../../core/Spinner/Spinner";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import ErrorPage from "../../core/ErrorPage/ErrorPage";
import CipCode from "../PaymentWithoutCard/PagoEfectivo/CipCode/CipCode";
import StudentFormPage from "../StudentFormPage/StudentFormPage";
import Footer from "../Footer/Footer";
import "./CheckoutPage.scss";
import SubHeader from "../SubHeader/SubHeader";

/**
 * Component
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const CheckoutPage = (props) => {

  const dispatch = useDispatch();

  // get route parameters
  const params = useParams();

  // get values from Redux state
  const language = useSelector(selectLanguage);
  const currency = useSelector(selectCurrency);

  // get values from origin, and update currency if necessary,
  // only when a favorite currency is not already set

  const originValues = getOriginValues(params.origin);
  if (currency !== originValues.currency &&
      !localStorage.getItem(storageKeys.currency)) {

    // update values in Redux state

    dispatch(changeCurrency(originValues.currency));
    dispatch(changeUserHasSetCurrency(true));

    // reload page

    window.location.reload();
  }

  // initialize translator with current language
  const t = initializeTranslator(language);

  // ---------------------------------------
  // set state values

  const [course, setCourse] = useState({});
  const [courseExists, setCourseExists] = useState(true);
  const [transactionSummary, setTransactionSummary] = useState({});
  const [transactionIsFinished, setTransactionIsFinished] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [pagoEfectivoCipCodeIsVisible, setPagoEfectivoCipCodeIsVisible] =
    useState(false);
  const [pagoEfectivoAmount, setPagoEfectivoAmount] = useState(0);
  const [pagoEfectivoCipCode, setPagoEfectivoCipCode] = useState("");
  const [pagoEfectivoCipUrl, setPagoEfectivoCipUrl] = useState("");
  const [pagoEfectivoExpiration, setPagoEfectivoExpiration] = useState("");

  // ---------------------------------------
  // get company name from url, set logo filename,
  // and background color according company

  let { logoFilename, companyUrl, classroomUrl, faviconIcon } =
    generateBrandingValues(params.company);

  let confirmationMessage =
    params.company === companies.ipappg
      ? t("successfulPaymentMessageIpappg")
      : t("successfulPaymentMessage");

  // load CSS variables
  if (params.company) loadCssVariablesFile(params.company);

  // ---------------------------------------
  // lifecycle hooks

  // execute when component is MOUNTED
  useEffect(() => {
    // set favicon according
    setFavicon(faviconIcon);
  }, []);

  // execute when component is UPDATED
  useEffect(() => {
    // perform requests to fetch course data
    (async () => {
      let course = await fetchCourse(
        params.company,
        params.courseCode,
        currency,
        params.studentId,
        params.installmentNumber
      );

      // update loading flag
      setIsLoading(false);
      // udpate course data
      setCourse(course);
      if (!course.name) setCourseExists(false);
    })();
  }, [params.company, params.courseCode]);

  // ---------------------------------------
  // callback for props

  const onTransactionFinished = (
    purchaseNumber,
    amount,
    currency,
    brand,
    card,
    transactionDate
  ) => {
    setTransactionSummary({
      purchaseNumber: purchaseNumber,
      amount: amount,
      currency: currency,
      brand: brand,
      card: card,
      transactionDate: transactionDate,
    });
    // udpate state to show Transaction page
    setTransactionIsFinished(true);
  };

  const onCipCodeGenerated = (cipCode, cipUrl, amount, expiration) => {
    // update component state
    setPagoEfectivoCipCode(cipCode);
    setPagoEfectivoCipUrl(cipUrl);
    setPagoEfectivoAmount(amount);
    setPagoEfectivoExpiration(expiration);
    setPagoEfectivoCipCodeIsVisible(true);
  };

  // ---------------------------------------
  // return component

  return (
    <div>
      <Header
        logoFilename={logoFilename}
        companyUrl={companyUrl}
        companyName={params.company}
      />

      {params.company !== companies.pragmma ?
          <SubHeader
          courseExists={courseExists}
          courseCode={params.courseCode} />
          : null}

      {/* Navigation breadcrumb */}
      {courseExists ? <Breadcrumb companyUrl={companyUrl} /> : null}

      {/* Page title */}
      {!pagoEfectivoCipCodeIsVisible &&
      courseExists &&
      params.section === sections.checkout ? (
        <div className="title-wrapper">
          <h1 className="font-main">{t("confirmPurchase")}</h1>
        </div>
      ) : null}

      {/* Loading spinner */}
      {isLoading ? <Spinner /> : null}

      {/* show payment information when transaction is not finished */}
      {!transactionIsFinished &&
      !pagoEfectivoCipCodeIsVisible &&
      courseExists &&
      params.section === sections.checkout ? (
        <Content
          transactionSummary={transactionSummary}
          name={course.name}
          price={course.price}
          course={course}
          companyUrl={companyUrl}
          companyName={params.company}
          onCipCodeGenerated={onCipCodeGenerated}
          onTransactionFinished={onTransactionFinished}
        />
      ) : null}

      {/* when transaction is finished show confirmation */}
      {transactionIsFinished && courseExists ? (
        <Confirmation
          companyUrl={companyUrl}
          classroomUrl={classroomUrl}
          courseName={course.name}
          confirmationMessage={confirmationMessage}
          transactionSummary={transactionSummary}
        />
      ) : null}

      {/* PagoEfectivo CIP code */}
      {pagoEfectivoCipCodeIsVisible && courseExists ? (
        <CipCode
          companyName={params.company}
          cipCode={pagoEfectivoCipCode}
          cipUrl={pagoEfectivoCipUrl}
          expiration={pagoEfectivoExpiration}
          amount={pagoEfectivoAmount}
        />
      ) : null}

      {/* Student form for enrollment */}
      {params.section === sections.preMatricula ||
      params.section === sections.completarMatricula ? (
        <StudentFormPage
          showCourseSelector={params.section === sections.preMatricula}
          companyUrl={companyUrl}
          companyName={params.company}
        />
      ) : null}

      {/* when course does not exist, show error page */}
      {params.section !== sections.preMatricula &&
      params.section !== sections.completarMatricula &&
      !courseExists ? (
        <ErrorPage companyUrl={companyUrl} />
      ) : null}

      <Footer companyName={params.company} />
    </div>
  );
};

export default CheckoutPage;
