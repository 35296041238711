import "./TermsModal.scss";
import TermsConditionsIPAPPG from "../TermsConditionsIPAPPG/TermsConditionsIPAPPG";
import TermsConditionsENPP from "../TermsConditionsENPP/TermsConditionsENPP";
import { companies } from "../../../../store/enums";
import TermsConditionsPragmma
  from "../TermsConditionsPragmma/TermsConditionsPragmma";

/**
 * Component
 * @returns {JSX.Element}
 * @constructor
 */
const TermsModal = (props) => {
  // return component
  return (
    <div className="terms-modal-component">
      <div className="modal d-block" tabIndex="-1">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">
                Política de Términos y condiciones{" "}
                {props.companyName.toUpperCase()}
              </h5>
              <button
                type="button"
                onClick={props.hide}
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body overflow-auto">
              {props.companyName === companies.enpp ? <TermsConditionsENPP /> : null }
              {props.companyName === companies.pragmma ? <TermsConditionsPragmma /> : null }
              {props.companyName === companies.ipappg ? <TermsConditionsIPAPPG /> : null }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TermsModal;
