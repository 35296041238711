import { banks } from "../../../store/enums";
import moment from "moment";

/**
 * Get Bank name
 * @param key bank name in kebab case
 */
export const getBankName = (key) => {
  if (!key) return '';

  if (key == banks.bancoNacion) {
    return "Banco de la nación";
  } else if (key == banks.pagoLinkBCP) {
    return "Pagolink - BCP";
  } else if (key == banks.yapeBCP) {
    return "Yape - BCP";
  } else if (key == banks.plin) {
    return 'Plin';
  } else {
    return key.toUpperCase();
  }
};

/**
 * Convert unix timestamp to date, and format date
 * @param unixTimestamp
 * @returns {string}
 */
export const formatTimestampToDateString = (unixTimestamp) => {
  if (!unixTimestamp) return '';

  let miliseconds = unixTimestamp * 1000;
  let date = new Date(miliseconds);
  return moment(date).format("DD/MM/YYYY");
};

/**
 * Convert unix timestamp to date, and format date
 * @param unixTimestamp
 * @returns {string}
 */
export const formatTimestampToDatetimeString = (unixTimestamp) => {
  if (!unixTimestamp) return '';

  let miliseconds = unixTimestamp * 1000;
  let date = new Date(miliseconds);
  return moment(date).format("DD/MM/YYYY HH:mm");
};

/**
 * Split string parts with separator
 */
export const splitString = (string, separator = ',') => {

  return typeof string === 'string'
      ? string.split(separator)
      : []
}

/**
 * Generate Unix timestamp from date object
 * @param {object} date
 * @returns {number} unix timestamp (seconds)
 */
export const generateUnixTimestamp = (date) => {

  if (!date) return 0;

  if (date.getTime) {

    return Math.floor(date.getTime() / 1000);

  } else {
    return 0;
  }
}
