import {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {selectLanguage} from "../../../../store/appInfoSlicer";
import {initializeTranslator} from "../../../../helpers/language.helper";
import {
  createPaymentLogForInstallment,
  submitCertificatesForEnrollment,
  updateEnrollmentStatus,
  uploadImageToS3,
} from "./helpers";
import {valuesHaveContent} from "../../../../helpers/validation.helper";
import {banks, currencies, uploadTypes} from "../../../../store/enums";
import {getBankName} from "../../helpers/helpers";
import DatePicker from "react-datepicker";
import RoundButton from "../../../core/RoundButton/RoundButton";
import Spinner from "../../../core/Spinner/Spinner";
import "react-datepicker/dist/react-datepicker.css";
import "./EnrollmentStatusForm.scss";
import CertificateSelector
  from "../../../checkout/Items/CertificateSelector/CertificateSelector";
import SelectMenu from "../../../core/SelectMenu/SelectMenu";
import {fetchCourse} from "../../../checkout/CheckoutPage/helpers";

/**
 * Component
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const EnrollmentStatusForm = (props) => {
  // get values from Redux state
  const language = useSelector(selectLanguage);

  // initialize translator with current language
  const t = initializeTranslator(language);

  // ---------------------------------------
  // set state values

  const [bank, setBank] = useState("");
  const [bankOperationNumber, setBankOperationNumber] = useState("");
  const [amount, setAmount] = useState("");
  const [selectedFile, setSelectedFile] = useState("");
  const [previewSrc, setPreviewSrc] = useState(null);
  const [formIsValid, setFormIsValid] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [operationDatetime, setOperationDatetime] = useState(new Date());
  const [certificates, setCertificates] = useState([]);
  const [chosenCertificates, setChosenCertificates] = useState([]);


  // ---------------------------------------
  // Lifecycle hooks

  useEffect(() => {

    if (props.enrollment) {

      (async () => {
        const enrollment = props.enrollment;
        const course = await fetchCourse(
            enrollment.company, enrollment.course_code, currencies.pen
        );
        // Generate certificates options list

        setCertificates(course.certificates.map(c => {
          return {
            value: c.id,
            text: `${c.name} PEN ${c.price.toFixed(2)}`
          }
        }))
      })()
    }

  }, [props.enrollment])

  // execute when component is UPDATED
  useEffect(() => {
    
    // form validation
    let _formIsValid = valuesHaveContent([bankOperationNumber, bank, amount]);

    // update component state
    setFormIsValid(_formIsValid);

  }, [bankOperationNumber, bank, amount]);

  // ---------------------------------------
  // Functions

  /**
   * Event handler for Changed event on file input
   */
  const onFileChanged = async (event) => {
    const file = event.target.files[0];
    const limit = 10 * 1000000; // 10MB in bytes
    if (file) {
      if (file.type === "image/jpeg" || file.type === "image/jpg") {
        if (file.size < limit) {
          setPreviewSrc(URL.createObjectURL(file));
          setSelectedFile(file);
        } else {
          // show notification on parent component:
          // file is too big
          props.showNotification(
            t("fileIsNotAllowed"),
            t("fileSizeIsBiggerThanLimit")
          );
        }
      } else {
        // show notification on parent component:
        // only JPG format is allowed
        props.showNotification(t("fileIsNotAllowed"), t("onlyJPGisAllowed"));
      }
    }
  };


  /**
   * Event handler for SuccessfulUpload event
   */
  const finishSaveOperation = () => {
    // update state to hide spinner
    setIsLoading(false);

    // close modal dialog and notify user
    // that updated has been performed correctly
    props.closeModalAfterSave();
  };

  /**
   * Event handler for FailedUpload event
   */
  const onFailedUpload = () => {
    // update state to hide spinner
    setIsLoading(false);

    // show notification on parent component:
    props.showNotification("", t("savedButFileNotUploaded"));

    // close modal
    props.closeModal();
  };

  /**
   * Event handler for Click event in EditStatus button
   */
  const onEditStatusClick = async () => {

    // update state to show spinner

    setIsLoading(true);

    let originalOperationNumber = props.enrollment.operation_number;

    // when an installment it is going to be payed, create its payment log
    // first since user is not going to  fill enrollment form again

    if (props.enrollment.installment_number >= 2) {
      let student = {
        id: props.enrollment.student_id,
        first_name: props.enrollment.first_name,
        last_name: props.enrollment.last_name,
        email: props.enrollment.email,
        mobile: props.enrollment.mobile,
      };
      originalOperationNumber = await createPaymentLogForInstallment(
        props.enrollment.installment_number,
        student,
        props.companyName,
        props.enrollment.course_code
      );
    }

    // submit request to change enrollment status to payed

    let { updated, operationNumber } = await updateEnrollmentStatus(
      props.enrollment,
      originalOperationNumber,
      bankOperationNumber,
      operationDatetime,
      bank,
      +amount
    );
    // if there is a selected image, upload the image to S3 bucket

    if (updated && selectedFile) {
      let filename = `${bank}-${operationNumber}`;

      await uploadImageToS3(
          selectedFile,
          filename,
          uploadTypes.courseBankInvoice,
          finishSaveOperation,
          onFailedUpload
      );
    } else if (updated) {
      finishSaveOperation()
    }
  };

  /**
   * Event handler for Click event in Save certificate button
   */
  const onSaveCertificateClick = async () => {

    // update state to show spinner

    setIsLoading(true);

    // submit request to save certificate

    let saved = await submitCertificatesForEnrollment(
        props.enrollment, bankOperationNumber,
        operationDatetime, bank, +amount, chosenCertificates
    );

    // if there is a selected image, upload the image to S3 bucket

    if (selectedFile) {
      let filename = `${bank}-${bankOperationNumber}`;

      await uploadImageToS3(
          selectedFile,
          filename,
          uploadTypes.certificateBankInvoice,
          finishSaveOperation,
          onFailedUpload
      );

    } else if (saved) {
        finishSaveOperation()
    }
  };

  const onChangeAmount = (e) => {
    const re = /^[1-9]\d*(\.\d+)?$/;

    // if value is not blank, then test the regex

    if (e.target.value === '' || re.test(e.target.value)) {
      setAmount(e.target.value)
    }
  }

  // ---------------------------------------
  // return component

  return (
    <div className="enrollment-status-form-component">
      {/* Loading spinner */}
      {isLoading ? <Spinner /> : null}

      <form className="default-form">
        <div className="col-12 text-center">
          <h2>{ props.title }</h2>
          <p
            dangerouslySetInnerHTML={{
              __html: t("youAreAboutToChangeStatus").replace(
                "{0}",
                props.enrollment.student
              ),
            }}
          />
        </div>
        <div className="row">
          <div className="col-6">
            <label>{t("operationNumber")}</label>
            <div className="input-wrapper mb-4 mt-2">
              <input
                  type="text"
                  value={bankOperationNumber}
                  onChange={(e) => setBankOperationNumber(e.target.value)}
                  placeholder={t("typeHere")}
              />
            </div>
          </div>
          <div className="col-6">
            <label>{t("bank")}</label>
            <div className="input-wrapper mb-4 mt-2">
              <select
                  value={bank}
                  className="form-select"
                  onChange={(e) => setBank(e.target.value)}
                  placeholder={t("typeHere")}
              >
                <option value="" />
                <option value={banks.bancoNacion}>
                  {getBankName(banks.bancoNacion)}
                </option>
                <option value={banks.bbva}>{getBankName(banks.bbva)}</option>
                <option value={banks.bcp}>{getBankName(banks.bcp)}</option>
                <option value={banks.interbank}>
                  {getBankName(banks.interbank)}
                </option>
                <option value={banks.scotiabank}>
                  {getBankName(banks.scotiabank)}
                </option>
                <option value={banks.pagoLinkBCP}>
                  {getBankName(banks.pagoLinkBCP)}
                </option>
                <option value={banks.plin}>
                  {getBankName(banks.plin)}
                </option>
                <option value={banks.yapeBCP}>
                  {getBankName(banks.yapeBCP)}
                </option>
              </select>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col">
            <label>{t("invoiceDate")}</label>
            <div className="input-wrapper mb-4 mt-2">
              <DatePicker
                  selected={operationDatetime}
                  onChange={(date) => setOperationDatetime(date)}
                  timeInputLabel={t("time")}
                  dateFormat="dd/MM/yyyy HH:mm"
                  showTimeInput
              />
            </div>
          </div>
          <div className="col">
            <label>{t("amount")}</label>
            <div className="input-wrapper input-group mb-4 mt-2">
              {/* input-group */}
              <span className="input-group-text">S/.</span>
              <input
                  value={amount}
                  onChange={(e) => onChangeAmount(e)}
                  placeholder={t("typeHere")}
              />
            </div>
          </div>
          {!props.editStatus
            ? <div className="col">
                <label className="mb-2">{t("certificates")}</label>
                <SelectMenu
                    options={certificates}
                    description={t("selectAnOption")}
                    optionSelectedCallback={(values) => setChosenCertificates(values)}
                    multiselection={true}></SelectMenu>
              </div>
            : null
          }
        </div>


        <div className="col-12">
          {/* Area to drop image */}
          {!previewSrc ? (
            <div className="image-drop-area">
              {/* Upload icon */}
              <div className="icon d-flex justify-content-center align-items-center">
                <img src="imgs/icons/upload-file.svg" alt="" />
              </div>

              {/* Title */}
              <h2>{t("invoiceImage")}</h2>

              {/* File input and custom Choose button: File input
                        is shown invisible on top of button in order to
                        show file selector when user clicks on custom button */}
              <div className="input-file-wrapper">
                <input
                  type="file"
                  name="input-file-image"
                  onChange={onFileChanged}
                />
                {/* This button does not need a click event handler
                            since is the File Input which is gonna open
                            the file selecto r*/}
                <RoundButton
                  width={"100%"}
                  height={"2.5rem"}
                  outline={true}
                  content={t("chooseAFile")}
                />
              </div>

              {/* Max file size notice */}
              <p className="notice">{t("maximumSize")}: 10MB</p>
            </div>
          ) : null}

          {/* Image preview area */}
          {previewSrc ? (
            <div className="image-preview-wrapper">
              <img className="icon" src="imgs/icons/check.svg" alt="" />
              <img className="preview" src={previewSrc} alt="" />

              <button
                onClick={() => setPreviewSrc(null)}
                type="button"
                className="delete"
              >
                <img src="imgs/icons/trash-empty-red.svg" alt="" />
              </button>
            </div>
          ) : null}
        </div>
        <div className="col-12" />
        <div className="col-12">
          {props.editStatus
              ? <RoundButton
                  onClick={() => onEditStatusClick()}
                  isDisabled={!formIsValid}
                  width={"100%"}
                  height={"3rem"}
                  outline={false}
                  content={t("editStatus")}
              />
              : null
          }

          {!props.editStatus
            ? <RoundButton
                  onClick={() => onSaveCertificateClick()}
                  isDisabled={!formIsValid}
                  width={"100%"}
                  height={"3rem"}
                  outline={false}
                  content={t('saveChanges')}
              />
              : null
          }

        </div>
      </form>
    </div>
  );
};

export default EnrollmentStatusForm;
