import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectLanguage } from "../../../store/appInfoSlicer";
import { initializeTranslator } from "../../../helpers/language.helper";
import "./SelectMenu.scss";

/**
 * Component
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const SelectMenu = (props) => {
  const checked = "imgs/icons/checkbox/check-fill.svg";
  const unchecked = "imgs/icons/checkbox/check-empty.svg";

  // get values from Redux state
  const language = useSelector(selectLanguage);

  // initialize translator with current language
  const t = initializeTranslator(language);

  // ---------------------------------------
  // set state values

  const [menuIsVisible, setMenuIsVisible] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedItems, setSelectedItems] = useState([]);
  const [items, setItems] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);
  const [filter, setFilter] = useState("");
  const [selectedItemInitialized, setSelectedItemInitialized] = useState(false);

  // lifecycle hooks
  // ---------------------------------------

  // hook to be executed when component has been UPDATED
  useEffect(() => {
    if (
      !selectedItemInitialized &&
      props.options.length > 0 &&
      props.selectedItem
    ) {
      // find selected item in options, since
      // sometimes only value is provided
      // without text

      let option = props.options.find(
        (o) => o.value === props.selectedItem.value
      );
      if (option) {
        setSelectedItem(option);
      } else {
        setSelectedItem(props.selectedItem);
      }

      setSelectedItemInitialized(true);
    }
  }, [props.options, props.selectedItem]);

  // hook to be executed when component has been UPDATED
  useEffect(() => {
    generateItemsCollection();
  }, [props.options]);

  // hook to be executed when component has been UPDATED
  useEffect(() => {
    // execute option selected callback
    let _selectedValues = [];
    selectedItems.forEach((item) => {
      _selectedValues.push(item.value);
    });

    if (props.multiselection) {
      props.optionSelectedCallback(_selectedValues);
    }

    // refresh items collection
    generateItemsCollection();
  }, [selectedItems]);

  // ---------------------------------------
  // functions

  /**
   * Event handler for click event in menu options
   */
  const onOptionClick = (item) => {
    // update component state
    setMenuIsVisible(false);
    setFilter("");

    if (props.multiselection) {
      // save selected option
      let selectedItem = selectedItems.find((i) => i.value === item.value);
      // when item is already selected, remove
      // it from selected collection
      if (selectedItem) {
        setSelectedItems(selectedItems.filter((i) => i.value !== item.value));
      } else {
        setSelectedItems([...selectedItems, item]);
      }
    } else {
      // save selected option
      setSelectedItem(item);
      // execute option selected callback
      props.optionSelectedCallback(item.value);
    }
  };

  /**
   * Generate items collection, update Checked
   * property if there are selected items
   */
  const generateItemsCollection = () => {
    let _items = [];
    props.options.forEach((option) => {
      let selectedItem = selectedItems.find((i) => i.value === option.value);
      option.checked = !!selectedItem;
      _items.push(option);
    });
    setItems(_items);
    setFilteredItems(_items);
  };

  /**
   * Filter items according search criteria
   */
  const filterItems = (filter) => {
    // update component state
    setFilter(filter);

    if (filter) {
      let _filtered = items.filter((item) => {
        return item.text.toLowerCase().includes(filter.toLowerCase());
      });
      setFilteredItems(_filtered);
    } else {
      setFilteredItems(items);
    }
  };

  // ---------------------------------------
  // return component

  return (
    <div
      className={menuIsVisible ? "select-wrapper active" : "select-wrapper"}
      style={{ background: props.backgroundColor }}
    >
      {/* Select menu description */}
      {selectedItems.length === 0 ? (
        <div
          onClick={() => {
            setMenuIsVisible(!menuIsVisible);
          }}
          style={props.descriptionStyle}
          className="description"
        >
          {/* if item is selected show its text, show description otherwise */}
          {selectedItem ? selectedItem.text : props.description}
        </div>
      ) : null}

      {/* Button to clear selected items */}
      {selectedItems.length > 0 && props.multiselection ? (
        <button type="button" className="button clear-selected">
          <span className="count">{selectedItems.length}</span>
          {t("selected")}
          <img
            onClick={() => setSelectedItems([])}
            src="imgs/icons/close-red.svg"
            alt=""
          />
        </button>
      ) : null}

      <img
        onClick={() => {
          setMenuIsVisible(!menuIsVisible);
        }}
        className="arrow down"
        src="imgs/icons/arrow-down.png"
        alt=""
      />

      <img
        onClick={() => {
          setMenuIsVisible(!menuIsVisible);
        }}
        className="arrow up"
        src="imgs/icons/arrow-up.png"
        alt=""
      />

      {/* Menu */}
      {items ? (
        <ul className="menu">
          {/* Filter area */}
          {props.filterIsvisible ? (
            <li className="filter">
              <input
                type="text"
                value={filter}
                onChange={(e) => filterItems(e.target.value)}
                name="menu-filter"
                placeholder={t("filter") + "..."}
              />
            </li>
          ) : null}

          {/* Render menu options */}
          {filteredItems.map((o) => {
            return (
              <li
                onClick={() => onOptionClick(o)}
                key={o.value}
                value={o.value}
              >
                {/* When multiselection is enabled, shows checkboxes */}
                {props.multiselection ? (
                  <img
                    className="check"
                    alt=""
                    src={o.checked ? checked : unchecked}
                  />
                ) : null}

                {/* Menu option's text */}
                {o.text}
              </li>
            );
          })}
        </ul>
      ) : null}
    </div>
  );
};

export default SelectMenu;
