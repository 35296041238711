import { useState, useEffect } from "react";
import "./SubHeader.scss";
import { useParams } from "react-router-dom";
import { companies } from "../../../store/enums";
import {fetchCourses} from "../../../helpers/courses.helper";

function SubHeader({ courseExists }) {

  // get route parameters
  const params = useParams();

  const [priceIsInitialized, setPriceIsInitialized] = useState(false);
  const [renderPremiumPrice, setRenderPremiumPrice] = useState(0);
  const [renderPremiumText, setRenderPremiumText] = useState("");
  const [availableToShow, setAvailableToShow] = useState(false);
  const [currentCourse, setCurrentCourse] = useState(null);
  const [symbol, setSymbol] = useState("");

  const listCodeCourseToHideSubheader = [
    '133', '143', '144', '294', '298', '299', // Premium ENPP
    '1000', '1001', '1002' // Plus IPAPPG
  ];

  const { courseCode, company } = useParams();

  useEffect(() => {
    (async () => {
      if (!priceIsInitialized) {
        let _courses = await fetchCourses(company);
        setCurrentCourse(_courses.companyCourses.find(c => c.code == courseCode))
        // 133 and 1000 are the codes of "Premium Individual" and "Plus Individual respectevely"
        let premium = _courses.companyCourses.find(c => c.code == 133 || c.code == 1000 )

        const premiumText =
          company === companies.enpp
           ? "Recuerda que también puedes comprar nuestro pack premium, con el cual tendrás acceso a este y más de 100 diplomados por 1 año a tan solo"
           : 'Recuerda que también puedes acceder a IPAPPG PLUS, con el cual podrás certificarte en este y más de 30 diplomados por 1 año a tan solo'

        if (premium) {
          setRenderPremiumText(premiumText);
          setSymbol("S/.");
          setRenderPremiumPrice(+premium.price);
          setPriceIsInitialized(true)
        }
      }
    })();
  }, [priceIsInitialized, company, courseCode])


  useEffect(() => {
    if (currentCourse) {
      let isPremium = listCodeCourseToHideSubheader.includes(currentCourse.code);
      if (company === companies.enpp && !isPremium) {
        setAvailableToShow(true);
      }
      if (company === companies.ipappg && !isPremium && currentCourse.type === 'diplomado') {
        setAvailableToShow(true);
      }
    }
  }, [company, currentCourse]);

  return (
    <>
      {courseExists && availableToShow && renderPremiumPrice > 0 ? (
        <div className={company === companies.enpp ? 'bg-dark text-white container-subheader' : 'subheader-gradient container-subheader'}>
          <div className="container">
            <div className="d-flex justify-content-between pt-3">
              <div className="row">
                <div className="col-md-8">
                  <p className="mr-4">
                    {`${renderPremiumText} ${symbol} ${renderPremiumPrice.toFixed(
                      2
                    )}`}
                  </p>
                </div>
                <div className="col-md-1" />
                <div className="col-md-3 pt-2 button-container-subheader">
                  <a
                    href={ company === companies.enpp
                        ? 'https://enppeduca.com/membresia'
                        : 'https://ipappg.edu.pe/plus.html' }
                    className="round-button-component-subheader"
                  >
                    { company === companies.enpp
                      ? 'Comprar pack premium'
                      : 'Comprar pack plus'
                    }
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
}

export default SubHeader;
