import axios from "axios";
import { getEndpoint } from "../../../helpers/endpoint.helper";

/**
 * Fetch configuration values
 * @returns {Promise<{boolean}>}
 */
export const fetchConfiguration = async (companyName) => {
  const endpoint = getEndpoint("configuration_company").replace(
    ":company",
    companyName
  );
  let config = {};
  console.log(endpoint, companyName);
  try {
    let response = await axios({
      method: "get",
      url: endpoint,
      withCredentials: true,
    });

    config = response.data;
  } catch (ex) {
    console.log(ex);
  }

  return config;
};

/**
 * Submit configuration values to server
 * @returns {Promise<boolean>}
 */
export const submitConfigurationValues = async (values) => {
  const endpoint = getEndpoint("configuration");
  let saved = false;

  try {
    let response = await axios({
      method: "put",
      url: endpoint,
      withCredentials: true,
      data: values,
    });

    saved = response.data.success;
  } catch (ex) {
    console.log(ex);
  }

  return saved;
};

/**
 * Perform request to delete courses cache file
 * @returns {Promise<boolean>}
 */
export const deleteCacheFile = async () => {
  const endpoint = getEndpoint("courses-cache");
  let deleted = false;

  try {
    let response = await axios({
      method: "delete",
      url: endpoint,
      withCredentials: true,
    });

    deleted = response.data.deleted;
  } catch (ex) {
    console.log(ex);
  }

  return deleted;
};
