const TermsConditionsENPP = (props) => {
  return (
    <div className="terms-conditions-component p-3">
      <h3 className=" text-center" align="center">
        INDICE
      </h3>

      <ol start="1" type="1">
        <li className=" text-justify">
          <span>
            Política de Contrataciones - descripción general de los bienes{" "}
          </span>
        </li>
        <li className=" text-justify">
          <span>
            Política de devoluciones, reembolso y cancelación de servicios
            adquiridos{" "}
          </span>
        </li>
        <li className=" text-justify">
          <span>Política de recepción de reclamos</span>
        </li>
        <li className=" text-justify">
          <span>Política de privacidad de Información al consumidor</span>
        </li>
      </ol>

      <h3>
        <a name="_heading=h.gjdgxs" />
        <span>1.</span>
        <span>POLÍTICA DE CONTRATACIONES</span>
      </h3>

      <p>
        <span>
          Esta política de contrataciones, solo es aplicable si ya has comprado
          en ENPP SAC, a través de nuestro sitio web. Por eso, el presente
          documento contiene los términos de las políticas de devoluciones, bajo
          los cuales en ENPP SAC nos comprometemos a respetar y cuidar tus
          derechos como consumidor, según los lineamientos establecidos en el
          Código de Protección y Defensa del Consumidor; así que, tenemos la
          satisfacción de informar los aspectos legales, tus derechos y
          obligaciones desde el momento en que compras en esta web.
        </span>
      </p>

      <h3>
        <a name="_heading=h.30j0zll" />
        <span>Identificación del vendedor</span>
      </h3>

      <p>
        <span>
          En virtud de lo establecido en la Ley Nº 29571, de Código de
          Protección y Defensa del Consumidor, se ofrece la siguiente
          información:
        </span>
      </p>

      <p>
        <span className="bullet">●</span>
        <span>
          La Razón Social del proveedor es: Escuela Nacional De Políticas
          Públicas Sociedad Anónima Cerrada - ENAPP S.A.C
        </span>
      </p>

      <p>
        <span className="bullet">●</span>
        <span>El RUC: 20601046793</span>
      </p>

      <p>
        <span className="bullet">●</span>
        <span>
          La dirección del domicilio fiscal está registrado en Perú: Av.
          República de Chile 476, Oficina 201, distrito de Jesús María, Lima
        </span>
      </p>

      <p>
        <span className="bullet">●</span>
        <span>Número de contacto: +51 946554339</span>
      </p>

      <p>
        <span className="bullet">●</span>
        <u>
          <span>Correo de contacto: wsucasaire@backofficelima.com.pe</span>
        </u>
      </p>

      <h3>
        <a name="_heading=h.1fob9te" />
        <span>Objeto del Contrato</span>
      </h3>

      <p>
        <span>
          La presente política de contrataciones tiene por objeto regular la
          relación contractual de compra venta nacida entre ENPP SAC y el
          cliente en el momento en que este acepta durante el proceso de
          contratación online la casilla correspondiente.
        </span>
      </p>

      <p>
        <span>
          Esta web ofrece la posibilidad de contratar cursos en formato digital
          y descargables. Desde el momento de la aceptación, el usuario adquiere
          la condición de cliente de ENPP SAC.
        </span>
      </p>

      <p>
        <span>
          La relación contractual de compraventa conlleva la entrega, a cambio
          de un precio determinado y públicamente expuesto a través del sitio
          web.
        </span>
      </p>

      <p>
        <span>
          Como condición para contratar alguno de los cursos ofrecidos por ENPP
          SAC, el Usuario debe contactar a través de la web https://enpp.edu.pe
          y proporcionar la información correspondiente a su solicitud
          completando el formulario correspondiente.
        </span>
      </p>

      <h3>
        <a name="_heading=h.3znysh7" />
        <span>2.</span>
        <span>POLÍTICA DE DEVOLUCIONES, REEMBOLSO Y COOKIES</span>
      </h3>

      <h3>
        <a name="_heading=h.2et92p0" />
        <span>
          El internet también tenemos el deber de respetar las normas que
          tutelan sus derechos y los nuestros, por eso, en Defensa de los
          Consumidores y Usuarios te informamos que, en lo referido a cursos,
          por ser un producto digital, sin soporte material de entrega
          presencial, se realizan devoluciones, solo bajo las siguientes
          situaciones, además debes de tener presente lo siguiente:
        </span>
      </h3>

      <p>
        <u>
          <span>Del cliente:</span>
        </u>
      </p>

      <p>
        <span>
          Desde el momento en que un usuario realiza una compra en ENPP SAC,
          pasa a convertirse de un usuario visitante a un cliente.
        </span>
      </p>

      <p>
        <u>
          <span>El uso de Cookies:</span>
        </u>
      </p>

      <p>
        <span>
          Al momento de navegar por nuestra web, se almacenan “cookies” en tu
          ordenador, por eso debes leer nuestra política de cookies para ampliar
          información sobre el uso de estas y cómo puedes gestionarlas.
        </span>
      </p>

      <p>
        <u>
          <span>Cursos digitales</span>
        </u>
      </p>

      <p>
        <span>
          El cliente desde el momento que realiza la compra de los cursos de
          descarga digital que son de venta final, es decir, los que al ser
          pagados automáticamente se realiza la entrega digitalmente, una vez
          facilitado el acceso a curso digital es tuyo para siempre para tu uso
          y disfrute por lo que no podemos ofrecer devolución del importe
          pagado.
        </span>
      </p>

      <p>
        <span>
          Solo los cursos y/o productos que especifiquen claramente en la
          descripción del mismo que tienen garantía de devolución son
          susceptibles de dicha devolución si se cumplen las condiciones citadas
          en la misma garantía.
        </span>
      </p>

      <p>
        <span>
          Esta siempre será claramente visible en la página de compra y
          descripción del producto, servicio o curso por lo que el comprador
          puede y debe leer la garantía antes de realizar el pago.
        </span>
      </p>

      <p>
        <span>
          Una vez matriculado no se acepta cambios de diplomados, programas o
          cursos. El tiempo de acceso al aula virtual será de 1 año.{" "}
        </span>
      </p>

      <p>
        <span>
          El alumno obtendrá su certificado del diplomado, programa o curso de
          forma digital y automática en la misma aula virtual luego de rendir su
          examen en el momento que él lo desee. De solicitar su certificado en
          físico (opcional) tendrá un costo de envío para Lima 10 soles y
          Provincias 20 soles. En caso de solicitar el duplicado en físico este
          tendrá el costo de 50 soles
        </span>
      </p>

      <p>
        <span>
          Si has adquirido un producto digital por error y es demostrable que no
          lo has descargado o accedido aún, puedes pedir la devolución. En este
          caso escribe a <u>wsucasaire@backofficelima.com.pe</u> con tu número
          de pedido para solicitarlo.
        </span>
      </p>

      <p>
        <u>
          <span>Aplicación de Devoluciones y Plazos de atención:</span>
        </u>
      </p>

      <p>
        <span>
          De solicitar la devolución por motivos ajenos a la escuela se le
          aplica una penalidad del 25% de lo abonado por gastos administrativos.{" "}
        </span>
      </p>

      <p>
        <span>
          El cliente tras enviar solicitud al correo electrónico del vendedor,
          podrá esperar plazo atención de respuesta como máximo 10 días útiles
          de parte de nuestra área de Cobranzas al correo registrado.
        </span>
      </p>

      <h3>
        <a name="_heading=h.tyjcwt" />
        <u>
          <span>Descargo de responsabilidad</span>
        </u>
      </h3>

      <p>
        <span>
          En reciprocidad a tu confianza, somos totalmente honestos contigo,
          explicándote a detalle todo lo que sucede desde el momento en que
          contratas los servicios de ENPP SAC o uno de mis infoproductos, a
          través del sitio web.
        </span>
      </p>

      <p>
        <span>
          Primero debes saber que no creemos ni propiciamos promesas de hacerte
          rico fácil y rápido y sin ningún esfuerzo de tu parte.
        </span>
      </p>

      <p>
        <span>
          Somos conscientes que todo éxito para lograr tus objetivos conlleva un
          trabajo detrás y los resultados no solo dependen de nosotros, sino
          también del tiempo que empleas y si pones en práctica o no nuestros lo
          aprendido en cada una de las lecciones del curso y recomendaciones.
        </span>
      </p>

      <p>
        <span>
          Sin embargo por sí solas no pueden ofrecerte resultados, si no las
          usas estratégicamente como te enseñamos y de la misma forma si solo
          aplicamos nuestros consejos y no tomas en cuenta las herramientas que
          te sugerimos, no podemos garantizar los mismos resultados.
        </span>
      </p>

      <h3>
        <a name="_heading=h.3dy6vkm" />
        <u>
          <span>Propiedad intelectual</span>
        </u>
      </h3>

      <p>
        <span>
          El sitio web de propiedad de ENPP SAC y todo su contenido (incluyendo
          – pero no limitado a – posts, imprimibles, ebooks, cursos, vídeos,
          etc.) contienen propiedad intelectual de propiedad de ENPP SAC,
          incluidas marcas de comercio, derechos de autor, información
          propietaria y otros derechos.
        </span>
      </p>

      <p>
        <span>
          Por lo tanto, de los productos que adquieres no puedes modificar,
          publicar, transmitir, participar en la transferencia o venta, crear
          obras derivativas, plagiar, copiar, hacer pasar el trabajo como tuyo,
          distribuir, exhibir, reproducir, o de ninguna manera explotar de
          ninguna forma y en ningún formato ninguna parte del contenido de este
          sitio, sus servicios o cualquier propiedad intelectual, en todo ni en
          parte, sin nuestro consentimiento previo.
        </span>
      </p>

      <p>
        <span>
          El contenido que incluye cada uno de los cursos de ENPP SAC es de
          autoría de sus tutores y solo ellos pueden disponer de los derechos
          morales y considerar y se exhiben en la plataforma de ENPP SAC por la
          cesión de derechos patrimoniales sobre el contenido creado
          exclusivamente para ENPP SAC.
        </span>
      </p>

      <p>
        <span>
          A consecuencia en ENPP SAC si te encontramos transgrediendo los
          derechos de propiedad intelectual, nos reservamos el derecho de
          remover inmediatamente de nuestros cursos, sin tener la obligación de
          reembolso, si eres descubierto violando nuestra propiedad intelectual
          y demandarte antes los tribunales correspondientes.
        </span>
      </p>

      <h3>
        <a name="_heading=h.1t3h5sf" />
        <span>Del servicio de post venta</span>
      </h3>

      <p>
        <span>
          Este servicio es brindado por nuestro equipo de atención y bienestar
          del consumidor y nació con la finalidad de brindar una mejor
          experiencia al cliente y atender las necesidades que tienen nuestros
          clientes después de haber realizado compras en nuestras webs.
        </span>
      </p>

      <p>
        <span>
          El personal asignado para la atención post venta, es asignado
          aleatoriamente en ENPP SAC y no será exclusivo de una persona en
          específico, por lo tanto, el usuario puede darse por satisfecho, con
          la prestación del servicio prestado por cualquier persona asignada de
          nuestro equipo de atención y satisfacción del usuario.
        </span>
      </p>

      <p>
        <span>
          Si tienes alguna pregunta sobre mi disclaimer de productos y
          servicios, la información que te he recopilado en línea, las prácticas
          de mi sitio o sus relaciones con este sitio web, contáctame en correo
          electrónico:
          <u>wsucasaire@backofficelima.com.pe</u>
        </span>
      </p>

      <p>
        <span>
          Recuerda que solo los cursos y/o productos que especifiquen claramente
          en la descripción del mismo que tienen garantía de devolución son
          susceptibles de dicha devolución si se cumplen las condiciones citadas
          en la misma garantía.
        </span>
      </p>

      <p>
        <span>
          En otros casos, la devolución solo será aceptada si se cumplen todas
          las siguientes condiciones: el curso fue comprado por error, no se
          descargó ni consumió ningún tipo de contenido ni ninguna clase, han
          pasado menos de 7 días naturales desde la compra hasta la solicitud de
          devolución. Si alguna de estas condiciones no se cumple, no será
          posible concretar la devolución.
        </span>
      </p>

      <p>
        <span>
          Si tienes alguna pregunta sobre mi disclaimer de cursos, la
          información que te he recopilado en línea, las prácticas de mi sitio o
          sus relaciones con este sitio web, contáctame en: Correo electrónico:{" "}
        </span>
        <span>
          <a href="mailto:wsucasaire@backofficelima.com.pe">
            <span>wsucasaire@backofficelima.com.pe</span>
          </a>
        </span>
      </p>

      <p>
        <span>3.</span>
        <span>POLÍTICA DE RECLAMOS/QUEJA</span>
      </p>

      <p>
        <span>
          El cliente ante cualquier inconformidad además de la atención de
          nuestro servicio Postventa, podrá generar su propio reclamo o queja
          dependiendo de la casuística en el siguiente enlace
          <a href="http://librodereclamacionesperu.com/65431777.html">
            <span>http://librodereclamacionesperu.com/65431777.html</span>
          </a>{" "}
          o ubicar su enlace en la parte inferior de la página oficial donde
          deberá completar los campos solicitados. Para lo cual se le generará
          un código de reclamo que podrá verificar su historial en el buscador
          del enlace, así como podrá obtener respuesta dependiendo la severidad
          de la inconformidad, sea queja para aquella disconformidad
          directamente relacionada con los servicios brinda este proveedor y
          queja para todo lo directamente relacionado a la atención preventa o
          postventa. Siendo los plazos de atención para reclamos hasta 7 días
          útiles, mientras que para quejas hasta 7 días hábiles.
        </span>
      </p>

      <h3>
        <a name="_heading=h.4d34og8" />
        <span>4. POLÍTICA DE PRIVACIDAD </span>
      </h3>

      <p></p>

      <p>
        <span>
          La política de privacidad en https://www.enpp.edu.pe es conforme lo
          dispuesto en la normativa vigente de protección de tus derechos, en
          aplicación de la Ley Nº 29733 que regula la Protección de Datos
          Personales, su reglamento y la Ley N° 28493 que regula el uso de
          correo electrónico comercial no solicitado de la legislación peruana;
          así que, puedes estar tranquilo que nuestro sitio respeta totalmente
          tus derechos.
        </span>
      </p>

      <h3>
        <a name="_heading=h.2s8eyo1" />
        <span>Identificación del responsable de los datos personales</span>
      </h3>

      <p>
        <span>
          El dominio y el sitio Web https://www.enpp.edu.pe/ es propiedad de
          ENPP SAC, identificado con RUC: 20601046793, en Lima – Perú, en lo
          siguiente ENPP SAC, manifiesta en la presente POLÍTICA DE PRIVACIDAD
          que, la presente es con la finalidad de darte a conocer el tipo de
          tratamiento de los datos personales que recolectamos.
        </span>
      </p>

      <p>
        <span>
          Este aviso de política de privacidad, regula el acceso, navegación y
          utilización de ENPP SAC, se reserva el derecho a modificar la
          presentación, la configuración y el contenido del mismo, así como las
          condiciones requeridas para su acceso y uso. El acceso y uso de los
          contenidos de ENPP SAC después de la entrada en vigor de sus
          modificaciones o cambios suponen la aceptación de los mismos.
        </span>
      </p>

      <p>
        <span>
          ENPP SAC, se compromete a garantizar el derecho fundamental a la
          protección de datos personales de los usuarios registrados en nuestra
          lista de correos, clientes, y demás usuarios, con ello he implementado
          mecanismos y medidas técnicas, organizativas y legales que me permiten
          proteger y resguardar su información personal. De esta forma, ENPP SAC
          se alinea a la Ley No 29733. Ley de Protección de Datos Personales.
        </span>
      </p>

      <p>
        <span>
          En tal sentido, te informamos que los responsables de los datos
          personales es, ENPP SAC, y solo recolectamos los datos que nos
          proporcionas voluntariamente y serán almacenados por MAILCHIMP, que es
          la plataforma de email marketing que usamos actualmente, este
          proveedor de email marketing se encuentra ubicado en Atlanta y la
          hemos elegido por su seriedad con el tratamiento de tus datos
          personales, para saber más de ellos y su política de privacidad puedes
          leerla .
        </span>
      </p>

      <p>
        <span>
          No obstante, el tratamiento de dichos datos se efectuará únicamente
          con tu consentimiento previa confirmación de la suscripción en la
          casilla de verificación y posterior aceptación del doble optin que te
          llega a tu bandeja de entrada y serán usados para enviar los regalos
          que te prometimos, además proporcionarte información exclusiva y
          promociones, mantener contacto directo con los usuarios de la web,
          según lo contemplado en la ley de Protección de datos Personales y su
          reglamento en Perú.
        </span>
      </p>

      <h3>
        <a name="_heading=h.17dp8vu" />
        <span>¿Cómo se recolectan tus datos personales?</span>
      </h3>

      <p>
        <u>
          <span>En los formularios de suscripción a contenidos:</span>
        </u>
      </p>

      <p>
        <span>
          En ENPP SAC, vas a encontrar varios formularios para activar la
          suscripción, en los cuales puedes elegir suscribirte según tus
          preferencias y necesidades, por ello es necesario que sepas que uso el
          doble optin, por lo tanto cada vez que ingreses tus datos debes
          verificar si te llegó el correo de confirmación de suscripción tanto
          en tu bandeja de spam o de promociones si usas gmail, posterior a tu
          confirmación te va a llegar automáticamente el regalo por el cual te
          suscribiste, además de ello por favor te pido que añadas el correo{" "}
          <u>wsucasaire@backofficelima.com.pe</u> a tu lista de remitentes
          permitidos, para poder estar en contacto contigo, si no verificas el
          email nunca te va a allegar nuestro regalo que solicitaste, además
          quiero que sepas que no guardamos las direcciones que no se han
          confirmado, por lo tanto, no te llegará nada que no nos solicites
          previamente.
        </span>
      </p>

      <p>
        <u>
          <span>Función de comentarios del contenido:</span>
        </u>
      </p>

      <p>
        <span>
          Si abrimos comentarios en algún contenido, cuenta con un formulario
          que puedes usar para publicar tus comentarios o preguntas, por lo
          tanto, antes de ser públicos los comentarios pasan por un filtro de
          aprobación, además de ello tienes la opción de elegir si se guarda o
          no tu nombre, correo electrónico y sitio web en este navegador para la
          próxima vez que hagas un comentario, si quieres recibir un email con
          los siguientes comentarios a la entrada, y si deseas recibir o no un
          email con cada nueva entrada, además los datos que proporcionas para
          realizar comentarios solo son exclusivamente usados para ese fin.
        </span>
      </p>

      <p>
        <u>
          <span>El uso de Cookies:</span>
        </u>
      </p>

      <p>
        <span>
          Al momento de navegar por nuestra web, se almacenan “cookies” en tu
          ordenador, por eso debes leer nuestra política de cookies para ampliar
          información sobre el uso de estas y cómo puedes gestionarlas.
        </span>
      </p>

      <p>
        <u>
          <span>Al activar tu suscripción, debes saber que:</span>
        </u>
      </p>

      <p>
        <span>
          Desde tu suscripción donde nos facilitas tu nombre de usuario y correo
          electrónico, asumimos que los datos personales facilitados a través de
          los diferentes formularios son veraces, puedes comunicar cualquier
          modificación de los mismos. Asimismo asumimos que toda la información
          facilitada corresponde a tu situación real, que está puesta al día y
          es exacta.
        </span>
      </p>

      <h3>
        <a name="_heading=h.3rdcrjn" />
        <u>
          <span>Legitimidad de los datos recogidos</span>
        </u>
      </h3>

      <p>
        <span>
          ENPP SAC recoge y utiliza muchos tipos de información procedentes de
          un amplio rango de fuentes para diferentes propósitos.
        </span>
      </p>

      <p>
        <span>
          Entre ellos se encuentran incluidos, pero no limitados, a los
          siguientes:
        </span>
      </p>

      <p>
        <span className="bullet">●</span>
        <span>
          Promover y prestar los servicios contratados, siempre salvaguardando
          la privacidad de los interesados
        </span>
      </p>

      <p>
        <span className="bullet">●</span>
        <span>
          Innovar y mejorar los servicios que ofrecemos a los interesados
          ofreciéndoles una experiencia de uso más personalizada, así como la
          oferta de cursos y los datos recolectados sean de fácil acceso del
          titular de los datos. servicios que mejor se ajuste a sus necesidades
          y hábitos del interesado.
        </span>
      </p>

      <p>
        <span className="bullet">●</span>
        <span>
          Seleccionar y gestionar a nuestros empleados y colaboradores.
        </span>
      </p>

      <p>
        <span className="bullet">●</span>
        <span>
          Eventualmente, recoger información con otros fines, que será
          comunicado de forma simple y transparente, dando siempre al cliente la
          posibilidad de oponerse a los nuevos tratamientos, siempre de acuerdo
          con la legislación aplicable.
        </span>
      </p>

      <h3>
        <a name="_heading=h.26in1rg" />
        <span>Seguridad e integridad de los datos personales</span>
      </h3>

      <p>
        <span>
          Gestionamos y almacenamos de forma responsable la información de
          nuestros clientes, manteniéndola segura.
        </span>
      </p>

      <p>
        <span>
          Nuestra política de privacidad establece los niveles de seguridad
          requeridos dentro de los procesos de creación, lanzamiento de nuestros
          cursos e inscripción de nuestros alumnos en los mismos, para preservar
          la protección y la integridad de los datos de nuestros clientes e
          interesados.
        </span>
      </p>

      <p>
        <span>
          Esta Política se persigue la adopción de acciones destinadas a
          preservar los cuatro componentes básicos de la seguridad aplicados a
          la información:
        </span>
      </p>

      <p>
        <span className="bullet">●</span>
        <span>
          Confidencialidad: Permitimos el acceso a los datos y a nuestros
          sistemas solo a aquellas personas debidamente autorizadas siguiendo el
          “principio de necesidad de uso”.
        </span>
      </p>

      <p>
        <span className="bullet">●</span>
        <span>
          Integridad: Preservamos la exactitud de la información y de los
          sistemas contra cualquier tipo de alteración, pérdida o destrucción,
          ya sea de forma accidental o fraudulenta.
        </span>
      </p>

      <p>
        <span className="bullet">●</span>
        <span>
          Disponibilidad: Establecemos los mecanismos necesarios para que la
          información y los datos recolectados sean de fácil acceso del titular
          de los datos.
        </span>
      </p>

      <p>
        <span className="bullet">●</span>
        <span>
          Auditabilidad: Facilitamos que cualquier acción o transacción pueda
          ser relacionada unívocamente asegurando el cumplimiento de controles
          clave establecidos en las correspondientes normativas.
        </span>
      </p>

      <h3>
        <a name="_heading=h.lnxbz9" />
        <span>
          Ejercicio del derecho de acceso, rectificación, cancelación u
          oposición
        </span>
      </h3>

      <p>
        <span>
          Asimismo, puedes ejercer tu derecho de acceso, rectificación,
          cancelación, oposición y otros contemplados en la ley de Protección de
          Datos Personales, a través de los procedimientos implementados por
          ENPP SAC, para la atención de los mismos. Para tal efecto, ponemos a
          su disposición el correo electrónico:{" "}
          <u>wsucasaire@backofficelima.com.pe</u>, para que puedas requerir
          información sobre los procedimientos en mención.
        </span>
      </p>

      <p>
        <span>
          Finalmente, hago de tu conocimiento que puedes acudir ante la
          Autoridad Nacional de Protección de Datos Personales en vía de
          reclamación o al Poder Judicial, o en la entidad correspondiente si te
          encuentras fuera del Perú, cuando ENPP SAC deniegue total o
          parcialmente el ejercicio de los derechos establecidos en la normativa
          de protección de datos personales.
        </span>
      </p>

      <p>
        <span>
          Si tienes alguna pregunta sobre mi política de privacidad, la
          información que hemos recopilado en línea, las prácticas de este sitio
          o sus relaciones con este sitio web, contáctanos:
        </span>
      </p>

      <p>
        <span>
          Correo electrónico: <u>wsucasaire@backofficelima.com.pe</u>
        </span>
      </p>
    </div>
  );
};

export default TermsConditionsENPP;
