import axios from "axios";
import { getEndpoint } from "../../../helpers/endpoint.helper";

// set global variables

window.dlocalInstance = null;
window.dLocalFields = null;
window.dLocalCardInput = null;
window.dLocalExpirationInput = null;
window.dLocalCvvInput = null;

/**
 * Initiailze dLocal Smart Inputs
 * @param gatewayToken
 * @param generatedCallback function to be called after inputs has been generated
 * @param cardNumberErrorCallback
 * @param cardExpiryErrorCallback
 * @param cardCvcErrorCallback
 * @param langs
 */
export const initializeDlocalForm = (
  gatewayToken,
  generatedCallback,
  cardNumberErrorCallback,
  cardExpiryErrorCallback,
  cardCvcErrorCallback,
  langs
) => {
  // instance dLocal library
  let apiKey = gatewayToken.tokens.apiKey;
  window.dlocalInstance = window.dlocal(apiKey);

  // instance fields

  window.dLocalFields = window.dlocalInstance.fields({
    fonts: [
      {
        cssSrc:
          "https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap",
      },
    ],
    locale: "es", // es
    country: "MX", // MX
  });

  // CSS style for inputs

  const style = {
    base: {
      width: "100%",
      fontSize: "16px",
      fontFamily: "'Montserrat', sans-serif",
      lineHeight: "18px",
      fontSmoothing: "antialiased",
      fontWeight: 500,
      color: "#252525",
      "::placeholder": {
        color: "#788086",
      },
      iconColor: "#adbfd3",
      autofilled: {
        color: "#000000",
      },
    },
  };

  // initialize card inputs

  window.dLocalCardInput = window.dLocalFields.create("pan", {
    style: style,
    placeholder: langs.placeholder,
  });

  window.dLocalExpirationInput = window.dLocalFields.create("expiration", {
    style: style,
    placeholder: "MM/AAAA",
  });

  window.dLocalCvvInput = window.dLocalFields.create("cvv", {
    style: style,
    placeholder: langs.placeholder,
  });

  // set event handlers
  setEventHandlersForCardInputs(
    generatedCallback,
    cardNumberErrorCallback,
    cardExpiryErrorCallback,
    cardCvcErrorCallback
  );

  // mount inputs  elements

  window.dLocalCardInput.mount(document.getElementById("card-number-wrapper"));
  window.dLocalExpirationInput.mount(
    document.getElementById("card-expiry-wrapper")
  );
  window.dLocalCvvInput.mount(document.getElementById("cvc-wrapper"));
};

/**
 * Tokenize card data with dLocal library, and submit it to backend
 */
export const tokenizeCardAndFinishDlocalTransaction = (
  company,
  operationNumber,
  amount,
  courseCode,
  firstName,
  lastName,
  email,
  installments,
  currency,
  successCallback,
  errorCallback
) => {
  // tokenize card
  window.dlocalInstance
    .createToken(window.dLocalCardInput, {
      name: `${firstName} ${lastName}`,
    })
    .then((result) => {
      // if card was tokenized, submit it to backend to finish transaction
      if (result.token) {
        // submit card token to finish transaction
        let endpoint = getEndpoint("finishTransaction").replace(
          ":gatewayName",
          "dlocal"
        );

        // peform POST request to finish transaction
        axios({
          url: endpoint,
          method: "post",
          data: {
            operationNumber: operationNumber,
            company: company,
            cardToken: result.token,
          },
        }).then((res) => {
          if (res.data.status) {
            // when status is PAID, payment has been finished successfully
            if (res.data.status === "PAID") {
              // format transaction datetime 2021-10-06T21:05:25.000+000
              // to a shorter format: 2021-10-06 21:05
              let transactionDatetime = res.data.approved_date
                .substring(0, 16)
                .replace("T", " ");
              let cardMask = `**** **** **** ${res.data.card.last4}`;
              let brand;
              // generate card brand
              switch (res.data.card.brand) {
                case "VI":
                  brand = "visa";
                  break;
                case "VD":
                  brand = "visa";
                  break;
                case "MC":
                  brand = "mastercard";
                  break;
                case "MD":
                  brand = "mastercard";
                  break;
              }
              successCallback(
                operationNumber,
                amount,
                currency,
                brand,
                cardMask,
                transactionDatetime
              );
            } else {
              // operation has been rejected
              errorCallback(res.data.status_detail);
            }
          } else {
            // transaction error
            errorCallback(res.data.code + " " + res.data.message);
          }
        });
      }
    })
    .catch((result) => {
      console.log(result.error.message);
    });
};

/**
 * Set event handlers for card inputs
 * @param generatedCallback function to be called after inputs has been generated
 * @param cardNumberErrorCallback
 * @param cardExpiryErrorCallback
 * @param cardCvcErrorCallback
 */
const setEventHandlersForCardInputs = (
  generatedCallback,
  cardNumberErrorCallback,
  cardExpiryErrorCallback,
  cardCvcErrorCallback
) => {
  // event handler for Change event in Card number
  window.dLocalCardInput.on("change", (e) => {
    if (e.error) cardNumberErrorCallback(e.error.message);
    else cardNumberErrorCallback("");
  });

  // event handler for Ready event in Card number
  window.dLocalCardInput.on("ready", (e) => {});

  // event handler for Change event in expiration date
  window.dLocalExpirationInput.on("change", (e) => {
    if (e.error) cardExpiryErrorCallback(e.error.message);
    else cardExpiryErrorCallback("");
  });

  // event handler for Ready event in expiration date
  window.dLocalExpirationInput.on("ready", (e) => {});

  // event handler for Change event in Card number
  window.dLocalCvvInput.on("change", (e) => {
    if (e.error) cardCvcErrorCallback(e.error.message);
    else cardCvcErrorCallback("");
  });

  // event handler for Ready event in expiration date
  window.dLocalCvvInput.on("ready", (e) => {
    generatedCallback();
  });
};

/**
 * Clear card inputs
 */
const clearCardForm = () => {
  window.dLocalCardInput.clear();
  window.dLocalExpirationInput.clear();
  window.dLocalCvvInput.clear();
};
