import { getEndpoint } from "../../../helpers/endpoint.helper";
import axios from "axios";

/**
 * Calculate price after discount
 * @param price
 * @param discountRate
 * @returns {*}
 */
export const calculatePrice = (price, discountRate) => {
  if (!isNaN(price) || !isNaN(discountRate)) return 0;

  if (discountRate && price) {
    return price - (discountRate * price) / 100;
  } else {
    return price;
  }
};

/**
 * Update discount rate of provided course
 * @param courseCode
 * @param discountRate
 */
export const updateCourseDiscountRate = async (courseCode, discountRate) => {
  let endpoint = getEndpoint("course-discount");

  try {
    let response = await axios({
      method: "post",
      url: endpoint,
      withCredentials: true,
      data: {
        courseCode: courseCode,
        discountRate: discountRate,
      },
    });

    return response.data.success;
  } catch (ex) {
    return false;
  }
};

/**
 * Check whether course information is complete or not
 */
export const isCourseInformationComplete = (course) => {

  let complete = true;

  if (!course.type_id) {
    complete = false;
  }

  if (!course.category_id) {
    complete = false;
  }

  if (!course.mode) {
    complete = false;
  }

  if (!course.is_active) {
    complete = false
  }

  return complete;
};
