import { useSelector } from "react-redux";
import { selectLanguage } from "../../../store/appInfoSlicer";
import { initializeTranslator } from "../../../helpers/language.helper";
import "./Breadcrumb.scss";

/**
 * Component
 * @returns {JSX.Element}
 * @constructor
 */
const Breadcrumb = (props) => {
  // get language value from Redux state
  const language = useSelector(selectLanguage);

  // initialize translator with current language
  const t = initializeTranslator(language);

  return (
    <div className="breadcrumb-component d-flex align-items-center font-main">
      <a
        onClick={() => {
          window.history.back();
        }}
        className="ms-2 ms-md-0 me-3 me-md-0"
        style={{ cursor: "pointer" }}
      >
        <img src="imgs/arrow.svg" alt="" />
      </a>
      <span className="ms-4 d-none d-sm-block">{t("return")} / </span>
      <span className="ms-1 active">{t("confirmPurchase")}</span>
    </div>
  );
};

export default Breadcrumb;
