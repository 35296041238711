import axios from "axios";
import {
  mailLooksValid,
  valuesHaveContent,
} from "../../../../helpers/validation.helper";
import provinces from "../../../../store/provinces";
import districts from "../../../../store/districts";
import departments from "../../../../store/departments";
import { getEndpoint } from "../../../../helpers/endpoint.helper";

/**
 * Generate years list for birthdate
 */
export const generateYearsList = () => {
  let years = [];
  let currentYear = new Date().getFullYear();
  for (let i = currentYear; i >= 1950; i--) {
    years.push(i);
  }

  return years;
};

/**
 * Generate years list for birthdate
 */
export const generateDaysList = (year, month) => {
  let isLeapYear = +year % 4 === 0;
  let daysCount = [
    0,
    31,
    isLeapYear ? 29 : 38,
    31,
    30,
    31,
    30,
    31,
    31,
    30,
    31,
    30,
    31,
  ];

  let daysList = [];
  for (let i = 1; i <= daysCount[+month]; i++) {
    daysList.push(i >= 10 ? i : `0${i}`);
  }

  return daysList;
};

/**
 * Validate form
 */
export const isPersonalFormValid = (
  dni,
  firstName,
  lastName,
  email,
  mobile
) => {
  // check whether required fields have content
  let requiredValid = valuesHaveContent([
    dni,
    firstName,
    lastName,
    email,
    mobile,
  ]);

  // mobile number should have at least 9 numbers
  if (mobile.length < 9) requiredValid = false;

  // email address looks valid
  let emailLooksValid = mailLooksValid(email);

  return {
    form: requiredValid && emailLooksValid,
    email: emailLooksValid,
  };
};

/**
 * Validate form
 */
export const isLocationFormValid = (
  filteredGiftCourses,
  department,
  province,
  giftCourse,
  howDidYouHearAboutUs,
  isIpappgPlus
) => {

  let values = [
    department,
    province,
    howDidYouHearAboutUs,
  ];

  // Gift course is required for courses
  // other than IPAPPG Plus and only when
  // there is a list of gift courses

  if (!isIpappgPlus && filteredGiftCourses.length)
    values.push(giftCourse)

  // check whether required fields have content

  let requiredValid = valuesHaveContent(values);

  return {
    form: requiredValid,
  };
};

/**
 * Get departament id using its name
 * @returns {*|string}
 */
export const getDepartamentId = (name) => {
  let departament = departments.find((departament) => {
    return departament.name.toUpperCase() === name.toUpperCase();
  });

  return departament ? departament.id : "";
};

/**
 * Get departament name using its id
 * @returns {*|string}
 */
export const getDepartamentName = (id) => {
  let departament = departments.find((departament) => {
    return departament.id === id;
  });

  return departament ? departament.name : "";
};

/**
 * Get province id using its name
 * @returns {*|string}
 */
export const getProvinceId = (name) => {
  let province = provinces.find((province) => {
    return province.name.toUpperCase() === name.toUpperCase();
  });

  return province ? province.id : "";
};

/**
 * Get province name using its id
 * @returns {*|string}
 */
export const getProvinceName = (id) => {
  let province = provinces.find((province) => {
    return province.id === id;
  });

  return province ? province.name : "";
};

/**
 * Get district id using its name
 * @returns {*|string}
 */
export const getDistrictId = (name) => {
  let district = districts.find((district) => {
    return district.name.toUpperCase() === name.toUpperCase();
  });

  return district ? district.id : "";
};

/**
 * Get district name using its id
 * @returns {*|string}
 */
export const getDistrictName = (id) => {
  let district = districts.find((district) => {
    return district.id === id;
  });

  return district ? district.name : "";
};

/**
 * Filter provinces list according department id
 * @returns array
 */
export const filterProvinces = (departmentId) => {
  if (!departmentId) return [];

  return provinces.filter((p) => {
    return p.department_id === departmentId;
  });
};

/**
 * Filter districts list according province id
 * @returns array
 */
export const filterDistricts = (provinceId) => {
  if (!provinceId) return [];

  return districts.filter((d) => {
    return d.province_id === provinceId;
  });
};

/**
 * Submit student data to server, to update its values
 * @returns {Promise<boolean>}
 */
export const submitStudent = async (student, companyName) => {
  // generate API endpoint
  let endpoint = getEndpoint("students");

  try {
    let response = await axios({
      method: "put",
      url: endpoint,
      data: {
        student: student,
        companyName: companyName,
      },
    });

    return response.data.success;
  } catch (ex) {
    console.log(ex);
    return false;
  }
};
