import axios from "axios";
import { currencies, paymentMethods } from "../../../store/enums";
import { getEndpoint } from "../../../helpers/endpoint.helper";

/**
 * Get payment gateway according currency,
 * when currency is PEN or USD use NIUBIZ payment gateway,
 * for MXN use dLocal payment gateway instead
 */
export const getPaymentMethodForCards = (currency) => {
  let paymentMethod;
  if (currency === currencies.pen || currency === currencies.usd) {
    paymentMethod = paymentMethods.niubiz;
  }

  if (currency === currencies.mxn) {
    paymentMethod = paymentMethods.dlocal;
  }

  return paymentMethod;
};

/**
 * Submit request to register student
 * @returns {Promise<void>}
 */
export const submitRegistrationRequest = async (
  purchaseNumber,
  amount,
  paymentMethod,
  registrationFailedCallback
) => {
  // generate API endpoint
  let endpoint = getEndpoint("students");

  // perform request to register student
  try {
    let response = await axios({
      method: "post",
      url: endpoint,
      data: {
        purchaseNumber: purchaseNumber,
        amount: amount,
        paymentMethod: paymentMethod,
      },
    });

    // when student registration failed,
    // execute callback
    if (!response.data.success)
      registrationFailedCallback(response.data.message);
  } catch (e) {
    console.log(e);
  }
};
