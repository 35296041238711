import { useSelector } from "react-redux";
import { selectLanguage } from "../../../../store/appInfoSlicer";
import { useEffect, useState } from "react";
import { initializeTranslator } from "../../../../helpers/language.helper";
import ModalDialogTitle from "../../../core/ModalDialogTitle/ModalDialogTitle";
import ModalDialogDescription from "../../../core/ModalDialogDescription/ModalDialogDescription";
import RoundButton from "../../../core/RoundButton/RoundButton";
import "./InstallmentReminderConfirmation.scss";

/**
 * Component
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const InstallmentReminderConfirmation = ({
  studentName,
  onSubmitPaymentReminder,
  onCancel,
}) => {
  // get values from Redux state
  const language = useSelector(selectLanguage);

  // initialize translator with current language
  const t = initializeTranslator(language);

  // ---------------------------------------
  // set state values

  const [description, setDescription] = useState("");

  // ---------------------------------------
  // Lifecycle hooks

  // execute when component is MOUNTED
  useEffect(() => {
    let _description = t("reminderWillBeSentToStudent").replace(
      "{0}",
      `<br><strong>"${studentName}"</strong>`
    );
    setDescription(_description);
  }, []);

  // ---------------------------------------
  // return component

  return (
    <div className="installment-reminder-confirmation-component">
      <ModalDialogTitle title={t("areYouSureSendInstallmentReminder")} />

      <ModalDialogDescription description={description} />

      {/* Submit button */}
      <div className="buttons-wrapper">
        <RoundButton
          onClick={() => onSubmitPaymentReminder()}
          width={"100%"}
          height={"3rem"}
          outline={false}
          content={t("submitReminder")}
        />
        <br />
        <br />
        {/* Cancel button */}
        <RoundButton
          onClick={() => onCancel()}
          width={"100%"}
          height={"3rem"}
          outline={true}
          content={t("cancel")}
        />
      </div>
    </div>
  );
};

export default InstallmentReminderConfirmation;
