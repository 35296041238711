import {getEndpoint} from "./endpoint.helper";
import axios from "axios";

export class Invoice {

    /**
     * Load invoicing configuration for company 
     */
    static async loadConfiguration(company) {

        let endpoint = getEndpoint('invoice-config')
            .replace(':companyName', company);

        try {
            // perform request

            let response = await axios({
                url: endpoint,
                method:  'get',
                withCredentials: true,
            });

            if (response.status === 200) {
                return response.data
            }

        } catch (ex) {
        
            return null;
        }
    }

    /**
     * Perform a request to issue an invoice
     * @returns {Promise<{success: boolean, message: null}>}
     */
    static async issue (
        company, invoice, enrollmentId, customer
    )  {

        let success = true;
        let message = null;
        let createdInvoice = null;
        let endpoint = getEndpoint('invoicing')
            .replace(':company', company)
            .replace(':invoiceType', invoice.type)
            .replace(':enrollmentId', enrollmentId);

        try {
            // perform request

            let response = await axios({
                url: endpoint,
                data: { customer, invoice },
                method:  'post',
                withCredentials: true,
            });

            if (response.status === 201) {
                createdInvoice = response.data
            }

        } catch (ex) {
            console.log(ex);
            success = false;
            if (ex.response)
                message = ex.response.data.error_code;
        }

        return { success, message, createdInvoice };

    }
}
