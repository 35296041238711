import "./PriceDialog.scss";
import ModalDialogTitle from "../../../core/ModalDialogTitle/ModalDialogTitle";
import ModalDialogDescription from "../../../core/ModalDialogDescription/ModalDialogDescription";
import { useSelector } from "react-redux";
import { selectLanguage } from "../../../../store/appInfoSlicer";
import { initializeTranslator } from "../../../../helpers/language.helper";
import SelectMenu from "../../../core/SelectMenu/SelectMenu";
import { updateCourseDiscountRate } from "../helper";
import Spinner from "../../../core/Spinner/Spinner";
import { useState } from "react";
import RoundButton from "../../../core/RoundButton/RoundButton";

const priceTypes = Object.freeze({
  sale: "sale",
  preSale: "preSale",
});

/**
 * Component
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const PriceDialog = ({ course, onCourseUpdated, onFail }) => {
  // get values from Redux state
  const language = useSelector(selectLanguage);

  // initialize translator with current language
  const t = initializeTranslator(language);

  // set dialog description
  let description = t("youAreAboutToChangeCoursePrice").replace(
    "{0}",
    `<strong>"${course.name}"</strong>`
  );

  // ---------------------------------------
  // set state values

  const [isLoading, setIsLoading] = useState(false);
  const [defaultDiscountRate, setDefaultRate] = useState(8);
  const [priceType, setPriceType] = useState(priceTypes.sale);

  // ---------------------------------------
  // functions

  /**
   * Update course discount rate
   * @returns {Promise<void>}
   */
  const updateDiscountRate = async () => {
    // update state to show spinner
    setIsLoading(true);

    // set discount Rate
    let discountRate = 0;
    if (priceType === priceTypes.preSale) {
      discountRate = defaultDiscountRate;
    }

    // submit request to change price
    let success = await updateCourseDiscountRate(+course.code, discountRate);
    if (success) {
      onCourseUpdated();
    } else {
      onFail();
    }

    // update state to hide spinner
    setTimeout(() => {
      setIsLoading(false);
    }, 1500);
  };

  // ---------------------------------------
  // return component

  return (
    <div className="price-dialog-component">
      {/* Loading spinner */}
      {isLoading ? <Spinner /> : null}

      <ModalDialogTitle title={t("editPublicPrice")} />

      <ModalDialogDescription description={description} />

      {/* prices options */}

      <SelectMenu
        description={t("selectAnOption")}
        selectedItem={null}
        optionSelectedCallback={(v) => setPriceType(v)}
        options={[
          { value: priceTypes.preSale, text: t("preSalePrice") },
          { value: priceTypes.sale, text: t("salePrice") },
        ]}
      />

      <div className="buttons-wrapper">
        <RoundButton
          onClick={() => updateDiscountRate()}
          width={"100%"}
          height={"3rem"}
          outline={false}
          content={t("saveChanges")}
        />
      </div>
    </div>
  );
};
export default PriceDialog;
