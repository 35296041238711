import "./Confirmation.scss";
import RoundButton from "../../core/RoundButton/RoundButton";
import { initializeTranslator } from "../../../helpers/language.helper";
import { useSelector } from "react-redux";
import { selectLanguage } from "../../../store/appInfoSlicer";
import { getCurrencySymbol } from "../../../helpers/curreny.helper";
import { useHistory, useParams } from "react-router-dom";
import { origins} from "../../../store/enums";
import { getOriginValues } from "../../../helpers/url.helper";
import { useEffect } from "react";

/**
 * Component
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const Confirmation = (props) => {
  // get route parameters
  const params = useParams();
  const history = useHistory();

  let summary = props.transactionSummary;

  let cardBrand = summary.brand ? summary.brand.toLowerCase() : "";
  let currency = summary.currency || "";
  let currencySymbol = getCurrencySymbol(currency);

  // format amount with two decimals
  let amount = summary.amount ? (+summary.amount).toFixed(2) : 0;

  // get date and times values from string like this example:
  // 210712231650, this string is gathered from Niubiz response,
  // and generate the following format: 14/12/2011 13:34
  let date;
  if (summary.transactionDate) {
    if (summary.transactionDate.length === 12) {
      let dateString = summary.transactionDate;
      let year = dateString.substring(0, 2);
      let month = dateString.substring(2, 4);
      let day = dateString.substring(4, 6);
      let hour = dateString.substring(6, 8);
      let minutes = dateString.substring(8, 10);
      date = `${day}/${month}/20${year} ${hour}:${minutes}`;
    } else {
      date = summary.transactionDate;
    }
  }

  // get language value from Redux state
  const language = useSelector(selectLanguage);

  // initialize translator with current language
  const t = initializeTranslator(language);

  /**
   * When origin is "aula", redirect to classroom's dashboard,
   * or redirect to classroom login page otherwise
   */
  const redirectToClassroom = () => {

    const originValues = getOriginValues(params.origin);
    if (originValues.origin === origins.classroom) {

      window.location.href = props.classroomUrl;

    } else {
      window.location.href = props.companyUrl;
    }
  }

  // Lifecycle hook executed when component has been mounted

  useEffect(() => {

    // Generate  and load path for route of the success page

    const pathParts = [
      params.company,
      params.section,
      params.paymentMethod,
      params.courseCode,
      params.origin,
      'success'
    ]
    history.push(`/${pathParts.join('/')}`);
  }, []);

  // ---------------------------------------
  // return component

  return (
    <div className="confirmation-component font-main row justify-content-center m-0">
      <div
        className="panel col-12 col-sm-10 col-lg-7 d-flex flex-column"
        style={{ textAlign: "center" }}
      >
        <div className="mb-4">
          <img src="imgs/icons/check.svg" alt="" className="logo" />
        </div>

        <div className="title mb-4">{t("successfulPayment")}</div>
        <div className="description mb-4 pl-4 pr-4">
          {props.confirmationMessage}
        </div>
        <div className="row ps-4 pe-4 summary">
          <div className="col-12 col-md-6 text-start">
            <span className="title">{t("purchaseNumber")}</span>
            <br />
            <span className="description">{summary.purchaseNumber}</span>
          </div>
          <div className="col-12 col-md-6 text-start">
            <span className="title">{t("dateAndTime")}</span>
            <br />
            <span className="description">{date}</span>
          </div>
          <div className="col-12 col-md-6 mt-3 text-start">
            <span className="title">{t("paymentCard")}</span>
            <br />
            <span className="description">
              <img
                src={`imgs/cards/${cardBrand}.svg`}
                className="card-icon"
                alt=""
              />
              {summary.card}
            </span>
          </div>
          <div className="col-12 col-md-6 mt-3 text-start">
            <span className="title">{t("amount")}</span>
            <br />
            <span className="description">{`${currencySymbol} ${amount}`}</span>
          </div>
          <div className="col-12 mt-3 text-start">
            <span className="title">{t("boughtItem")}</span>
            <br />
            <span className="description">{props.courseName}</span>
          </div>
        </div>
        <div className="row ps-4 pe-4 ">
          <div className="col pt-3 pb-3">
            <div className="mt-3">
              <RoundButton
                onClick={() => redirectToClassroom()}
                width="20.625rem"
                content={t("goToHome")}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Confirmation;
