import "./SidebarPopup.scss";

/**
 * Component
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const SidebarPopup = (props) => {
  // ---------------------------------------
  // return component

  return (
    <div className="sidebar-popup-component">
      <div
        className={props.isVisible ? "sidebar-popup visible" : "sidebar-popup"}
      >
        <h2>{props.title}</h2>

        {props.content}

        <div onClick={props.closeCallback} className="close button">
          <img src="imgs/icons/close.svg" alt="" />
        </div>
      </div>

      <div className={props.isVisible ? "overlay visible" : "overlay"} />
    </div>
  );
};

export default SidebarPopup;
