import RoundButton from "../RoundButton/RoundButton";
import "./ModalDialog.scss";

/**
 * Component
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const ModalDialog = (props) => {
  // ---------------------------------------
  // return component

  return (
    <div className="modal-dialog-component d-flex justify-content-center">
      <div className="panel align-self-center" style={props.panelStyle || {}}>
        {/* Close button */}
        <div onClick={props.close} className="close button">
          <img src="imgs/icons/close.svg" alt="" />
        </div>

        {/* Dialog content */}
        {props.content}

        {/* Ok button is shown only when its callback
            and text has been set */}
        {props.okCallback && props.okText ? (
          <div className="d-flex justify-content-center mt-5">
            <RoundButton
              onClick={props.okCallback}
              width="80%"
              content={props.okText}
            />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default ModalDialog;
