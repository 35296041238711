import axios from "axios";
import { getEndpoint } from "../../../helpers/endpoint.helper";

/**
 * Fetch course information from API
 * @returns {Promise<object>}
 */
export const fetchCourse = async (
  company,
  courseCode,
  currencyCode,
  studentId,
  installmentNumber
) => {
  // generate API endpoint
  let endpoint;

  // when student id and installment number
  // are set, choose endpoint for installment,
  // or choose default course endpoint otherwise
  if (studentId && installmentNumber) {
    endpoint = getEndpoint("course-installment");
  } else {
    endpoint = getEndpoint("course");
  }

  endpoint = endpoint
    .replace(":company", company)
    .replace(":courseCode", courseCode)
    .replace(":currencyCode", currencyCode)
    .replace(":studentId", studentId)
    .replace(":installmentNumber", installmentNumber);

  // perform request to fetch course data
  try {
    let response = await axios({
      method: "get",
      url: endpoint,
    });

    // if price is zero, override name, to avoid showing course
    if (response.data.price === 0) {
      response.data.name = null;
    }

    // update state with response data
    return response.data;
  } catch (e) {
    return {
      name: null,
      type: "",
      price: 0,
    };
  }
};

/**
 * Load CSS variables file for current company
 * @param companyName
 */
export const loadCssVariablesFile = (companyName) => {
  // create a link element to load CSS variables file
  // according company. This file is loaded in this way
  // to avoid conflict in production mode, where variables
  // are overwritten because they got the same name
  let linkEl = document.createElement("link");
  linkEl.setAttribute("rel", "stylesheet");
  linkEl.setAttribute("href", `css/variables_${companyName}.css`);
  document.querySelector("head").appendChild(linkEl);
};
