import "./Spinner.scss";

const Spinner = (props) => {
  return (
    <div className="spinner-component">
      <div className="spinner">
        <div className="dot1" />
        <div className="dot2" />
      </div>
    </div>
  );
};

export default Spinner;
