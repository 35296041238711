import "./ModalDialogDescription.scss";

/**
 * Component
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const ModalDialogDescription = ({ description }) => {
  // ---------------------------------------
  // return component

  return (
    <p
      className="modal-dialog-description mb-4"
      dangerouslySetInnerHTML={{ __html: description }}
    />
  );
};

export default ModalDialogDescription;
