import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { initializeTranslator } from "../../../helpers/language.helper";
import {
  changeLanguage,
  changeCountry,
  selectCountry,
  selectLanguage,
} from "../../../store/appInfoSlicer";
import "./Header.scss";
import {companies} from "../../../store/enums";
import {useParams} from "react-router-dom";

/**
 * Header component
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const Header = (props) => {

  const dispatch = useDispatch();

  const params = useParams();
  const country = useSelector(selectCountry);
  const language = useSelector(selectLanguage);

  // initialize translator with current language
  const t = initializeTranslator(language);

  // ---------------------------------------
  // set state values

  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [configIcon, setConfigIcon] = useState('config.svg');
  const [quitIcon, setQuitIcon] = useState('quit.png');

  // ---------------------------------------
  // Lifecycle hooks

  useEffect(() => {

    if (params.company === companies.pragmma) {
      setConfigIcon('config_blue.svg');
      setQuitIcon('quit_blue.svg');
    }
  }, [params]);

  // ---------------------------------------
  // Functions

  const runTabCallback = (tabIndex) => {
    if (props.tabs[tabIndex]) {
      // run callback
      props.tabs[tabIndex].callback();
      // update active tab
      setActiveTabIndex(tabIndex);
    }
  };

  /**
   * Update country and language
   */
  const setCountryLanguage = (country, language) => {
    // update values in Redux state
    dispatch(changeLanguage(language));
    dispatch(changeCountry(country));
  };

  // return component
  return (
    <header className={
      `header-component 
      ${params.company === companies.pragmma ?  'header-shadow' : ''}`
    }>
      <div className="container" style={{ height: "inherit" }}>
        <div className="row" style={{ height: "inherit" }}>
          <div className="col-6">
            {props.logoFilename ? (
              <a
                href={props.companyUrl}
              >
                <img
                  className="logo"
                  src={`imgs/logos/${props.logoFilename}`}
                  alt=""
                />
              </a>
            ) : null}
          </div>
          <div className="col-6 d-flex justify-content-end">
            {/* Render tabs */}

            {props.tabs ? (
              <div className="tabs-wrapper">
                {props.tabs.map((tab, i) => {
                  return (
                    <div
                      onClick={() => runTabCallback(i)}
                      key={i}
                      className={activeTabIndex === i ? "tab active" : "tab"}
                    >
                      {tab.text}
                    </div>
                  );
                })}
              </div>
            ) : null}


            {/* Logout button is visible only when its callback is defined */}

            {props.configClick ? (
              <button
                onClick={() => props.configClick()}
                type="button"
                className="config"
              >
                <img src={`imgs/icons/${configIcon}`} alt="" />
              </button>
            ) : null}

            {props.logoutClick ? (
              <button
                onClick={() => {
                  props.logoutClick();
                }}
                type="button"
                className="logout"
              >
                <img src={`imgs/icons/${quitIcon}`} alt="" />
              </button>
            ) : null}
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
