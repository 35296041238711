import {getEndpoint} from "../../../helpers/endpoint.helper";
import axios from "axios";
import {getBankName} from "../helpers/helpers";

/**
 * Submit email reminder for installment
 * @param companyName
 * @param studentId
 * @param language
 * @returns {Promise<boolean|*>}
 */
export const submitEmailReminder = async (companyName, studentId, language) => {
  if (!studentId) return false;

  let endpoint = getEndpoint("notification-installment")
    .replace(":companyName", companyName)
    .replace(":studentId", studentId)
    .replace(":language", language);

  try {
    let response = await axios({
      method: "get",
      withCredentials: true,
      url: endpoint,
    });

    return response.data.success;
  } catch (ex) {
    console.log(ex);
    return false;
  }
};

/**
 * Fetch enrollment's certificates
 */
export const fetchEnrollmentPayments = async (enrollmentId) => {

  if (!enrollmentId) return [];

  let endpoint = getEndpoint('payments')
      .replace(':enrollmentId', enrollmentId);

  try {
    let response = await axios({
      method: "get",
      withCredentials: true,
      url: endpoint
    });

    return response.data;
  } catch (ex) {
    console.log(ex);
    return [];
  }
}

/**
 * Generate payments collection grouped by transaction
 */
export const generatePaymentsCollection = (_payments) => {

  let payments = {};
  _payments.forEach(p => {

    if (p.operation_number) {

      let groupName = generateTransactionGroupName(p);

      if (!payments[groupName]) {
        payments[groupName] = []
      }

      payments[groupName].push(p)

    } else {
      if (!payments.SAME_AS_COURSE) {
        payments.SAME_AS_COURSE = []
      }

      payments.SAME_AS_COURSE.push(p)
    }
  })

  return payments
}

export const generateTransactionGroupName = (payment) => {
  return `${getBankName(payment.bank)} op:${payment.operation_number}`
}
