import CheckoutPage from "./components/checkout/CheckoutPage/CheckoutPage";
import Backoffice from "./components/backoffice/Backoffice";
import Login from "./components/backoffice/Login/Login";
import { useEffect, useState } from "react";
import { isUserAuthenticated } from "./helpers/auth.helper";
import { HashRouter as Router, Route, Switch } from "react-router-dom";
import { Provider } from "react-redux";
import { store } from "./store/store";
import "./App.scss";

const PrivateRoute = ({
  component: Component,
  isAuthenticated,
  onLogin,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        props.onLogin = onLogin;
        props.isAuthenticated = isAuthenticated;

        if (isAuthenticated) {
          return <Component {...props} />;
        } else {
          return <Login {...props} />;
        }
      }}
    />
  );
};

function App() {
  // ---------------------------------------
  // set state values

  const [isAuthenticated, setIsAuthenticated] = useState(false);

  // Lifecycle hooks
  // ---------------------------------------

  // verify is user is authenticated when component
  // has been mounted
  useEffect(() => {
    (async () => {
      setIsAuthenticated(await isUserAuthenticated());
    })();
  }, []);

  // ---------------------------------------
  // return root component

  return (
    <Provider store={store}>
      <Router>
        <Switch>
          {/*
            Backoffice routes
          ----------------------------------------*/}

          <PrivateRoute
            path="/:company/backoffice/:module/:section"
            isAuthenticated={isAuthenticated}
            onLogin={() => {
              setIsAuthenticated(true);
            }}
            component={Backoffice}
          />

          <PrivateRoute
            path="/:company/backoffice/:module/:section/:param1"
            isAuthenticated={isAuthenticated}
            onLogin={() => {
              setIsAuthenticated(true);
            }}
            component={Backoffice}
          />

          <PrivateRoute
            path="/:company/backoffice/login"
            isAuthenticated={isAuthenticated}
            onLogin={() => {
              setIsAuthenticated(true);
            }}
            component={Login}
          />

          {/* Installments route */}

          <Route
            exact
            path="/:company/:section/installment/:courseCode/:studentId/:installmentNumber"
            component={CheckoutPage}
          />

          {/*
            Checkout routes
          ----------------------------------------*/}

          {/* example: enpp/checkout/tarjeta/294/website/step1 */}

          <Route
            exact
            path="/:company/:section/:paymentMethod/:courseCode/:origin/:step"
            component={CheckoutPage}
          />

          <Route
            exact
            path="/:company/:section/:courseCode"
            component={CheckoutPage}
          />

          {/* example: ipappg/pre-matricula */}

          <Route exact path="/:company/:section/" component={CheckoutPage} />

          {/* Default route, when no other has matched */}

          <Route component={CheckoutPage} />
        </Switch>
      </Router>
    </Provider>
  );
}

export default App;
