const en = {
  aProblemHasOccurred: "A problem has cccurred",
  acceptThe: "Accept the",
  actions: "Actions",
  active: "Active",
  addCertificates: "Add certificates",
  address: "Address",
  all: "All",
  paymentAmount: "Amount",
  installmentsNumber: "Number Of Installments",
  allRightsReserved: "All right reserved",
  amount: "Amount",
  amountToPay: "Amount to pay",
  april: "April",
  areYouSureSendInstallmentReminder:
    "Are you sure to submit installment reminder?",
  august: "August",
  authenticationFailed: "Login failed",
  authenticationFailedMessage: "Please verify email and password",
  back: "Back",
  bank: "Bank",
  "bank-transfer": "Bank transfer",
  bankAccounts: "Bank accounts",
  birthdate: "Birthdate",
  boughtItem: "Bought Item",
  cancel: "Cancel",
  campaignNumber: "Campaign number",
  cardHolderFirstName: "Card holder first name",
  cardHolderLastName: "Card holder last name",
  cardNumber: "Card number",
  cardType: "Card type",
  category: "Category",
  certificate: "Certificate",
  certificates: "Certificates",
  certificateUnmsm: "Certificate - UNMSM",
  certificateCost: "Certificate cost",
  certificatesDueDate: "Certificates due date",
  checkYourInbox: "Check your inbox",
  chooseAFile: "Choose a file",
  chooseCertificate: "Choose certificate",
  chooseMultipleCertificates: "Remenber you can choose multiple certificates.",
  chooseYourGift: "Choose your gift",
  clearFilters: "Clear filters",
  code: "Code",
  company: "Company",
  complaintsLog: "Complaints log",
  confirmPurchase: "Confirm purchase",
  configuration: "Configuration",
  continue: "Continue",
  cookiesPolicy: "Cookies policy",
  couldNotSave: "Could not save",
  courseDiploma: "Course, Program or Diploma",
  coursesAndDiplomas: "Courses and diplomas",
  course: "Course",
  courses: "Courses",
  courseDetails: "Course details",
  courseStartDate: "Course start date",
  courseEndDate: "Course end date",
  coursesCache: "Courses cache",
  coursesCacheIsClean: "Courses cache is clean",
  dataSentSuccessfully: "Data sent successfully",
  dateAndTime: "Date and time",
  dateOfBirth: "Date of birth",
  december: "December",
  delete: "Delete",
  deleteConfirmation:
    "Are you sure you want to delete selected record? Please note that this action cannot be undone",
  departament: "Departament",
  district: "District",
  digitalCertificateEnpp: "Digital certificate - ENPP",
  dniNeedsAtLeastEightsDigits: "DNI needs at least 8 digits",
  document: 'Document',
  documentNumber: 'Document number',
  download: "Download",
  downloadReport: "Download report",
  dueDate: "Due date",
  editStatus: "Edit status",
  editPublicPrice: "Edit public price",
  edit: "Edit",
  billing: 'Billing',
  email: "Email",
  emailIsInvalid: "Email is invalid",
  emailNotificationSent: "Email notification has been sent",
  english: "English",
  enppTermsConditionsPolicy: "ENPP - Terms and conditions policy",
  enrollmentData: "Enrollment data",
  enrollments: "Matrículas",
  enrollmentsDiscountLimit: "Enrollments discount limit",
  enter: "Enter",
  expirationDate: "Expiration date",
  faq: "FAQ",
  february: "February",
  fileFormat: "File format",
  fileIsNotAllowed: "File is not allowed",
  fileSizeIsBiggerThanLimit: "File size is bigger than limit",
  filter: "Filter",
  filterByStatus: "Filter by status",
  firstName: "First name",
  formCompletionMessage: "Thank you for filling out the form",
  free: "Free",
  giftCourse: "Gift course",
  goToHome: "Go to home",
  howDidYouHearAboutUs: "How did you hear about us?",
  howDoIPay: "How do I pay?",
  installment: "Installment",
  installments: "Installments",
  inInstallments: "In {0} installments",
  installmentToPay: "Installment to pay",
  invoiceImage: "Invoice image",
  invoiceDate: "Invoice date",
  invoiceOf: "Invoice of <br/> {0}",
  issue: "Issue",
  issueInvoice: "Issue invoice",
  ipappgTermsConditionsPolicy: "IPAPPG - Terms and conditions policy",
  january: "January",
  july: "July",
  june: "June",
  lastName: "Last name",
  lastSaved: "Last saved",
  locationData: "Location",
  login: "Login",
  march: "March",
  maximumSize: "Maximum size",
  may: "May",
  mobile: "Mobile",
  mobileNeedsAtLeatNineDigits: "Mobile needs at leat 9 digits",
  mode: "Mode",
  name: "Name",
  new: "New",
  next: "Next",
  november: "November",
  october: "October",
  ok: "Ok",
  oneHundredSecurePayment: "100% Secure payment",
  onlyJPGisAllowed: "Only JPG is allowed",
  operationNumber: "Operation number",
  others: "Others",
  otherPayments: "Other payments",
  pageDoesNotExists: "Page does not exists",
  pageDoesNotExistsMessage:
    "Make sure the url of the page is correct. We invite you to try again, or to visit our other courses and diplomas. ",
  pagoEfectivoDescription:
    "Make a bank transfer through your Bank website or Bank app, or make a cash deposit by approaching any agency, on behalf of the <strong>PagoEfectivo</strong> service.",
  password: "Password",
  pay: "Pay",
  payInInstallments: "Pay in installments",
  paymentInInstallments: "Payment in installments",
  payItBefore: "Pay it before {0}",
  payWithCard: "Pay with card",
  payWithoutCard: "Pay without card",
  payed: "Payed",
  paymentCard: "Payment card",
  paymentCodeCIP: "Payment code (CIP)",
  paymentData: "Payment data",
  paymentDate: "Payment date",
  paymentDates: "Payment dates",
  paymentInformation: "Payment information",
  paymentMethod: "Payment method",
  paymentWithInstallments: "Payment with installments",
  pending: "Pending",
  personalData: "Personal data",
  preSale: "Pre-sale",
  preSalePrice: "Pre-sale price",
  prices: "Prices",
  privacyPolicy: "Privacy policy",
  programCost: "Program cost",
  programs: "Programs",
  province: "Province",
  publicPrice: "Public price",
  purchaseNumber: "Purchase number",
  recommendation: "Recommendation",
  registrationDate: "Registration date",
  reminderWillBeSentToStudent: "An email reminder will be sent: {0}",
  reportMonth: "Report month",
  return: "Return",
  sale: "Sale",
  salesManager: "Carlos Alberto Mendoza Pinillos - Sales Manager",
  salePrice: "Sale price",
  savedSuccessfully: "Saved successfully",
  savedButFileNotUploaded: "Saved record, but image can not be uploaded",
  saveChanges: "Save changes",
  saved: "Saved",
  search: "Buscar",
  searchByCourseName: "Search by course name",
  searchByStudentNameEmail: "Search by student name or email",
  seeDetails: "See details",
  sendPaymentReminder: "Send payment reminder",
  selectAnOption: "Select an option",
  selectOptions: "Select options",
  selected: "Selected",
  september: "September",
  service: "Service",
  showingResults: "Showing {0} results",
  show: "Show",
  showInPreenrollment: "Show in pre-enrollment",
  spanish: "Spanish",
  status: "Status",
  student: "Student",
  studentData: "Student data",
  studentDetails: "Students details",
  students: "Students",
  submitData: "Submit data",
  submitReminder: "Submit reminder",
  successfulPayment: "Successful Payment",
  successfulPaymentMessageEnpp:
    "We have sent you an email (also check your spam inbox) with the credentials for you to access the virtual classroom. ",
  successfulPaymentMessageIpappg:
    "We have sent you an email (also check your spam inbox) with the registration confirmation. ",
  termsConditions: "Terms and conditions",
  thisValueWillBeUsedInYourCertficate:
    "This value will be used in your certificate",
  time: "Time",
  totalCost: "Total cost",
  transfer: "Transfer",
  tryAgain: "Try again",
  typeHere: "Type here",
  type: "Type",
  weSentYouEmailOrderDetails:
    "We have just sent you an email with the order details ",
  whatsappInvoiceMessage:
    "Send us a screenshot or photo of the operation to our Whatsapp:",
  whatsappMessage: "Hi, this is the screenshot/photo of my payment.",
  withDiscount: "With discount",
  yapeDescription:
    "From your Yape app, scan the QR and make the payment. Send us a screenshot of the operation to our official contact, by Whatsapp message.",
  youAreAboutToChangeStatus:
    'You are about to change <strong>"{0}"</strong> status',
  youAreAboutToFinishPurchaseIn: "You are about to finish purchase in {0}!",
  youAreAboutToChangeCoursePrice: "You are about to change {0}",
  youAreOneStepToFinishPurchase: "You are one step to finish purchase!",
};

export default en;
