import { languages } from "../store/enums";

/**
 * Trim start and end char
 * @param string
 * @param charToRemove
 * @returns {*}
 */
export const trim = function (string, charToRemove) {
  while (string.charAt(0) === charToRemove) {
    string = string.substring(1);
  }

  while (string.charAt(string.length - 1) === charToRemove) {
    string = string.substring(0, string.length - 1);
  }

  return string;
};

/**
 * Get installment ordinal number
 * 1st, 2nd, etc according language
 */
export const getInstallmentNumber = (installmentNumber, language) => {
  if (language === languages.en) {
    switch (+installmentNumber) {
      case 1:
        return "1st";
      case 2:
        return "2nd";
      case 3:
        return "3rd";
      default:
        return "1st"
    }
  }

  if (language === languages.es) {
    switch (+installmentNumber) {
      case 1:
        return "1ra";
      case 2:
        return "2da";
      case 3:
        return "3ra";
      default:
        return '1ra'
    }
  }
};
