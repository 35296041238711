import { initializeTranslator } from "../../../helpers/language.helper";
import { useSelector } from "react-redux";
import {
  selectLanguage,
  selectPaymentMethod,
} from "../../../store/appInfoSlicer";
import "./PaymentSelector.scss";
import {useParams} from "react-router-dom";
import {companies} from "../../../store/enums";

export const tabs = Object.freeze({
  withCard: "with-card",
  withoutCard: "without-card",
  transfer: "transfer",
});

/**
 * Initialize style values for tabs
 * @returns {{transferIcon: string, cardIcon: string, withoutCardIcon: string, classes: ({withoutCard: string, card: string}|{withoutCard: string, card: string})}}
 */
const initializeTabsValues = (activeTab) => {
  let cardIcon;
  let withoutCardIcon;
  let transferIcon;
  let classes;

  if (activeTab === tabs.withCard) {
    cardIcon = "card-white";
    withoutCardIcon = "bill-black";
    transferIcon = "transfer-black";
    classes = {
      card: "button with-card active",
      withoutCard: "button without-card",
      transfer: "button transfer",
    };
  }

  if (activeTab === tabs.withoutCard) {
    cardIcon = "card-black";
    withoutCardIcon = "bill-white";
    transferIcon = "transfer-black";
    classes = {
      card: "button with-card",
      withoutCard: "button without-card active",
      transfer: "button transfer",
    };
  }

  if (activeTab === tabs.transfer) {
    cardIcon = "card-black";
    withoutCardIcon = "bill-black";
    transferIcon = "transfer-white";
    classes = {
      card: "button with-card",
      withoutCard: "button without-card",
      transfer: "button transfer active",
    };
  }

  return {
    cardIcon: cardIcon,
    withoutCardIcon: withoutCardIcon,
    transferIcon: transferIcon,
    classes: classes,
  };
};

/**
 * PaymentSelector component
 * @returns {JSX.Element}
 * @constructor
 */
const PaymentSelector = (props) => {

  // get route parameters
  const params = useParams();

  // get values from Redux state
  const language = useSelector(selectLanguage);
  const currency = useSelector(selectPaymentMethod);

  // initialize translator with current language
  const t = initializeTranslator(language);

  // initialize styles
  let { cardIcon, withoutCardIcon, transferIcon, classes } =
    initializeTabsValues(props.activeTab);

  // return component

  return (
    <div className="payment-selector-component row">
      <div className="col button-wrapper d-flex justify-content-center justify-content-sm-start">
        {props.tabs.includes(tabs.withCard) ? (
          <button onClick={() => props.onCardClick()} className={classes.card}>
            <img src={`imgs/${cardIcon}.svg`} alt="" />
            <br />
            {t("payWithCard")}
          </button>
        ) : null}

        {props.tabs.includes(tabs.withoutCard) && params.company !== companies.pragmma ? (
          <button
            onClick={() => props.onWithoutCardClick()}
            className={classes.withoutCard}
          >
            <img src={`imgs/${withoutCardIcon}.svg`} alt="" />
            <br />
            {t("payWithoutCard")}
          </button>
        ) : null}

        {props.tabs.includes(tabs.transfer) ? (
          <button
            onClick={() => props.onTransferClick()}
            className={classes.transfer}
          >
            <img src={`imgs/${transferIcon}.svg`} alt="" />
            <br />
            {t("transfer")}
          </button>
        ) : null}
      </div>
    </div>
  );
};

export default PaymentSelector;
