import { createRef, useEffect } from "react";
import { useSelector } from "react-redux";
import { selectLanguage } from "../../../../store/appInfoSlicer";
import { initializeTranslator } from "../../../../helpers/language.helper";
import { downloadAndShowS3Image } from "../EnrollmentStatusForm/helpers";
import RoundButton from "../../../core/RoundButton/RoundButton";
import "./Voucher.scss";
/**
 * Component
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const Voucher = (props) => {
  // Reference of image's element
  let imageRef = createRef();

  // get values from Redux state
  const language = useSelector(selectLanguage);

  // initialize translator with current language
  const t = initializeTranslator(language);

  // ---------------------------------------
  // Lifecycle hooks

  // execute when component is UPDATED
  useEffect(() => {
    // show invoice image from S3 bucket in preview
    downloadAndShowS3Image(
      imageRef.current,
      props.bank + "-" + props.operationNumber + ".jpg",
        props.uploadType
    );
  }, [props.operationNumber]);

  // ---------------------------------------
  // return component

  return (
    <div className="invoice-dialog-content-component">
      <h2
        className="invoice-dialog-title"
        dangerouslySetInnerHTML={{
          __html: t("invoiceOf").replace("{0}", props.studentName),
        }}
      ></h2>
      <img ref={imageRef} src="" alt="" />

      {/* Close button */}
      <RoundButton
        onClick={() => props.close()}
        width={"100%"}
        height={"2.5rem"}
        outline={false}
        content={t("ok")}
      />
    </div>
  );
};

export default Voucher;
