import React from "react";
import ReactDOM from "react-dom";
import { Toaster } from 'react-hot-toast';
import "./index.css";
import "bootstrap/dist/css/bootstrap.min.css";
import App from "./App";
import { HashRouter as Router } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import { RecoilRoot } from 'recoil';
require("dotenv").config();

ReactDOM.render(
  <Router>
      <RecoilRoot>
        <App />
      </RecoilRoot>
    <Toaster />
  </Router>,
  document.getElementById("root")
);

// add Niubiz scripts to DOM, this can not be included
// as a regular React module, since they are loaded
// dynamically from Niubiz server

let scriptElm = document.createElement("script");
scriptElm.src = process.env.REACT_APP_NIUBIZ_PAYFORM_URL;
document.body.appendChild(scriptElm);

let scriptElm2 = document.createElement("script");
scriptElm2.src = "js/niubiz.js";
document.body.appendChild(scriptElm2);

// add dLocal scripts to DOM, this can not be included
// as a regular React module, since they are loaded
// dynamically from dLocal server

let scriptElm3 = document.createElement("script");
scriptElm3.src = process.env.REACT_APP_DLOCAL_LIBRAY_URL;
document.body.appendChild(scriptElm3);

let scriptElm4 = document.createElement("script");
scriptElm4.src = "js/dlocal.js";
document.body.appendChild(scriptElm4);

// define global variables with niubiz endpoints, this is
// done this way, since our Niubiz script (niubiz.js) is executed outside REACT

window.REACT_APP_API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
window.REACT_APP_NIUBIZ_AUTHORIZATION_URL =
  process.env.REACT_APP_NIUBIZ_AUTHORIZATION_URL;

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

reportWebVitals();
