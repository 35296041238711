/**
 * Initialize Niubiz payform
 */
export const initializeNiubizPayform = (
  gatewayToken,
  amount,
  generatedCallback,
  cardNumberErrorCallback,
  cardExpiryErrorCallback,
  cardCvcErrorCallback,
  binCallback,
  language
) => {
  if (!gatewayToken.tokens) return;
  if (!gatewayToken.tokens.sessionToken) return;

  window.initializeNiubizPayform({
    sessionkey: gatewayToken.tokens.sessionToken,
    channel: "web",
    merchantid: gatewayToken.niubizMerchantId,
    purchasenumber: gatewayToken.purchaseNumber,
    amount: amount,
    language: language,
    font: "https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap",
  });

  // generate inputs for card values,
  // this style values apply for all
  // inputs (card number, expiration date and cvc code)
  window.generateNiubizInputs(
    gatewayToken.purchaseNumber,
    {
      base: {
        color: "#252525",
        fontWeight: 500,
        fontFamily: "'Montserrat', sans-serif",
        fontSize: "16px",
        fontSmoothing: "antialiased",
        placeholder: {
          color: "#788086",
        },
        autofill: {
          color: "#e39f48",
        },
      },
      invalid: {
        color: "#E25950",
        "::placeholder": {
          color: "#FFCCA5",
        },
      },
    },
    generatedCallback,
    cardNumberErrorCallback,
    cardExpiryErrorCallback,
    cardCvcErrorCallback,
    binCallback
  );
};

/**
 * Request transaction token from Niubiz webservice
 */
export const tokenizeCardAndFinishNiubizTransaction = (
  company,
  merchantId,
  purchaseNumber,
  amount,
  courseCode,
  firstName,
  lastName,
  email,
  installments,
  currency,
  successCallback,
  errorCallback
) => {
  let userData = {
    name: firstName,
    lastName: lastName,
    email: email,
    alias: "user",
  };

  window.tokenizeCardAndFinishTransaction(
    company,
    userData,
    merchantId,
    purchaseNumber,
    amount,
    courseCode,
    installments,
    currency,
    successCallback,
    errorCallback
  );

};
