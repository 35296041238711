import "./ErrorPage.scss";
import RoundButton from "../RoundButton/RoundButton";
import { initializeTranslator } from "../../../helpers/language.helper";
import { useSelector } from "react-redux";
import { selectLanguage } from "../../../store/appInfoSlicer";

const ErrorPage = (props) => {
  // get language value from Redux state
  const language = useSelector(selectLanguage);

  // initialize translator with current language
  const t = initializeTranslator(language);

  return (
    <div className="error-page-component container font-main">
      <div className="row justify-content-center">
        <div className="col-12 col-md-6 col-lg-8 order-last order-md-first message">
          <h1>{t("pageDoesNotExists")}</h1>
          <p>{t("pageDoesNotExistsMessage")}</p>

          <RoundButton
            onClick={() => {
              window.location.href = props.companyUrl;
            }}
            content={t("coursesAndDiplomas")}
          />
        </div>
        <div className="col-12 col-md-6 col-lg-4 d-flex order-first order-md-last justify-content-center align-content-center">
          <img className="" src="imgs/404.svg" alt="" />
        </div>
      </div>
    </div>
  );
};

export default ErrorPage;
