import { useSelector } from "react-redux";
import { selectLanguage } from "../../../store/appInfoSlicer";
import { initializeTranslator } from "../../../helpers/language.helper";
import { useEffect, useState } from "react";
import {
  deleteCacheFile,
  fetchConfiguration,
  submitConfigurationValues,
} from "./helper";
import { formatTimestampToDatetimeString } from "../helpers/helpers";
import RoundButton from "../../core/RoundButton/RoundButton";
import "./ConfigurationModal.scss";

/**
 * Component
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const ConfigurationModal = ({ companyName }) => {

  // get language value from Redux state

  const language = useSelector(selectLanguage);

  // initialize translator with current language

  const t = initializeTranslator(language);

  // ---------------------------------------
  // set state values

  const [configuration, setConfiguration] = useState(null);
  const [successNotificationIsVisible, setSuccessNotificationIsVisible] =
    useState(false);
  const [cacheIsClean, setCacheIsClean] = useState(false);
  const [enrollmentDiscountLimit, setEnrollmentDiscountLimit] = useState(0);
  const [certificatesDueDate, setCertificatesDueDate] = useState("");

  // Lifecycle hooks
  // ---------------------------------------

  // hook to be executed when component has been MOUNTED

  useEffect(() => {
    (async () => {
      let _configuration = await fetchConfiguration(companyName);
      setConfiguration(_configuration);

      // When no cache datetime is defined,
      // hide delete button, because cache is clean

      if (!_configuration.coursesCacheDatetime) {
        setCacheIsClean(true);
      }

      // update component state with configuration values

      if (_configuration.enrollmentsDiscountLimit) {
        setEnrollmentDiscountLimit(_configuration.enrollmentsDiscountLimit);
      }

      if (_configuration.certificatesDueDate) {
        setCertificatesDueDate(_configuration.certificatesDueDate);
      }
    })();
  }, []);

  // ---------------------------------------
  // functions

  /**
   * Submit request to delete cache file in server
   * @returns {Promise<void>}
   */
  const deleteCoursesCache = async () => {
    let deleted = await deleteCacheFile();

    // update component state

    setCacheIsClean(deleted);
  };

  /**
   * Submit request to save configuration values
   * @returns {Promise<void>}
   */
  const saveConfiguration = async () => {
    let values = {
      enrollmentsDiscountLimit: enrollmentDiscountLimit,
      certificatesDueDate: certificatesDueDate,
    };

    let saved = await submitConfigurationValues({
      values: values,
      companyName: companyName,
    });

    // update component state

    setSuccessNotificationIsVisible(saved);

    // after two seconds update "visible" flag

    setTimeout(() => {
      setSuccessNotificationIsVisible(false);
    }, 2000);
  };

  // ---------------------------------------
  // return component

  return (
    <div>
      <h2>{t("configuration")}</h2>

      <form className="default-form">
        <div className="row">
          <div className="col-12 pt-4">
            <span className="control-label">{t("coursesCache")}</span>

            {!cacheIsClean && configuration ? (
              <div>
                <span style={{ fontSize: "0.9rem" }}>
                  {t("lastSaved")}:{" "}
                  {formatTimestampToDatetimeString(
                    configuration.coursesCacheDatetime
                  )}
                </span>
                <br />
                <RoundButton
                  width={"7rem"}
                  height={"1.8rem"}
                  onClick={() => deleteCoursesCache()}
                  content={t("delete")}
                />
              </div>
            ) : null}

            {cacheIsClean ? (
              <span style={{ fontSize: "0.9rem" }}>
                {t("coursesCacheIsClean")}
              </span>
            ) : null}
          </div>

          <div className="col-6 pt-4">
            <span className="control-label">
              {t("enrollmentsDiscountLimit")}
            </span>
            <div className="input-wrapper">
              <input
                type="text"
                name="enrollment-discount-limit"
                value={enrollmentDiscountLimit}
                onChange={(e) => setEnrollmentDiscountLimit(e.target.value)}
              />
            </div>
          </div>

          <div className="col-6 pt-4">
            <span className="control-label">{t("certificatesDueDate")}</span>
            <div className="input-wrapper">
              <input
                type="text"
                name="certificates-due-date"
                value={certificatesDueDate}
                onChange={(e) => setCertificatesDueDate(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-6 pt-5 d-flex justify-content-center">
            <RoundButton
              width={"12rem"}
              height={"2.8rem"}
              onClick={() => saveConfiguration()}
              content={t("saveChanges")}
            />
          </div>
          <div className="col-6 pt-4">
            {successNotificationIsVisible ? (
              <div className="notification mt-2">
                <img className="icon" src={`imgs/icons/check.svg`} alt="" />

                {t("savedSuccessfully")}
              </div>
            ) : null}
          </div>
        </div>
      </form>
    </div>
  );
};

export default ConfigurationModal;
