import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectLanguage } from "../../../store/appInfoSlicer";
import { initializeTranslator } from "../../../helpers/language.helper";
import { companies } from "../../../store/enums";
import {
  facebookPixelEvents,
  triggerFacebookPixelEvent,
} from "../../../helpers/facebook-pixel.helper";
import { useParams } from "react-router-dom";
import Message, { messageIcons } from "../../core/Message/Message";
import BankAccountsInfo from "./BankAccountsInformation/BankAccountsInformation";
import Stepper from "../Stepper/Stepper";
import StudentInformationForm from "./StudentInformationForm/StudentInformationForm";

/**
 * PaymentTransfer component
 * @returns {JSX.Element}
 * @constructor
 */
const StudentFormAndBankAccounts = (props) => {
  // get language value from Redux state
  const language = useSelector(selectLanguage);

  // initialize translator with current language
  const t = initializeTranslator(language);

  // get route parameters
  const params = useParams();

  // ---------------------------------------
  // set state values

  // step number
  const [stepNumber, setStepNumber] = useState(1);
  const [stepsLabels, setStepsLabels] = useState([]);
  const [centeredButtons, setCenteredButtons] = useState(false);
  const [messageIsShown, setMessageIsShown] = useState(false);

  // ---------------------------------------
  // Lifecycle hooks

  // execute when component is MOUNTED
  useEffect(() => {
    let personalDataLabel = t("personalData");
    let bankAccontsLabel = t("bankAccounts");
    let _stepsLabels = [];

    if (props.companyName === companies.enpp) {
      _stepsLabels = [personalDataLabel];
    }

    if (props.companyName === companies.ipappg) {
      _stepsLabels = [personalDataLabel];
    }

    if (props.companyName === companies.pragmma) {
      _stepsLabels = [personalDataLabel];
    }

    // add step label for bank accounts
    if (props.showBankAccounts) {
      _stepsLabels.push(bankAccontsLabel);

      // trigger facebook pixel event
      triggerFacebookPixelEvent(
        facebookPixelEvents.initiateCheckout,
        props.courseName,
        props.companyName
      );
    }

    // udpate component state
    setStepsLabels(_stepsLabels);
  }, []);

  // execute when component is UPDATED
  useEffect(() => {
    // when form is being only to retrieve student
    // data, and bank information is not being
    // shown, buttons should be centered
    if (!props.showBankAccounts) setCenteredButtons(true);
  }, [props.showBankAccounts]);

  // execute when component is UPDATED
  useEffect(() => {
    // when 2, 3, or higher installment is being paid,
    // hide student since is no longer necessary
    if (
      params.studentId &&
      params.installmentNumber >= 2 &&
      props.companyName === companies.ipappg
    ) {
      setStepNumber(2);
    }
  }, [params.studentId, params.installmentNumber]);

  // ---------------------------------------
  // functions

  /**
   * Event handler for PageNumberChange event in page of
   * StudentInformationForm
   */
  const onPageNumberChange = (pageNumber) => {
    setStepNumber(pageNumber);

    // run parent callback
    if (props.onSizeChange) {
      setTimeout(() => {
        props.onSizeChange();
      }, 300);
    }
  };

  /**
   * Event handler for SubmitData event in StudentInformationForm
   */
  const onSubmitData = () => {
    // set last step (bank accounts)
    if (props.showBankAccounts) onPageNumberChange(stepsLabels.length);
    else setMessageIsShown(true);
  };

  // return component

  return (
    <div>
      {/* Steps indicator */}
      {stepsLabels.length > 1 ? (
        <div className="pt-5 pb-5">
          <Stepper
              step={stepNumber}
              steps={stepsLabels}
              stepSuffix={(params.company === companies.pragmma) ? '_purple' : ''} />
        </div>
      ) : null}

      {/* Notification message, to be shown after form was submitted */}
      {messageIsShown ? (
        <Message
          icon={messageIcons.check}
          title={t("dataSentSuccessfully")}
          description={t("formCompletionMessage")}
          okText={t("goToHome")}
          okCallback={() => {
            window.location.href = props.companyUrl;
          }}
        />
      ) : null}

      {/* Student and bank information */}
      <div>
        <div className="form-wrapper">
          {/* Student information form */}
          {(stepNumber < stepsLabels.length && props.showBankAccounts) ||
          (stepNumber <= stepsLabels.length && !props.showBankAccounts) ? (
            <StudentInformationForm
              showCourseSelector={props.showCourseSelector}
              centeredButtons={centeredButtons}
              companyName={props.companyName}
              courseName={props.courseName}
              courseCode={props.courseCode}
              withInstallments={props.withInstallments}
              certificates={props.certificates}
              submitDataButtonLabel={
                props.showBankAccounts ? t("next") : t("submitData")
              }
              onSubmitData={onSubmitData}
              onPageNumberChange={onPageNumberChange}
            ></StudentInformationForm>
          ) : null}
        </div>
        <div className="accounts-wrapper">
          {/* Bank accounts details */}
          {stepNumber === stepsLabels.length && props.showBankAccounts ? (
            <BankAccountsInfo
              installmentNumber={params.installmentNumber}
              companyName={props.companyName}
            ></BankAccountsInfo>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default StudentFormAndBankAccounts;
