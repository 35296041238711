import { companies } from "../store/enums";

export const facebookPixelEvents = Object.freeze({
  purchase: "Purchase",
  botonSiguienteTarjeta: "Botón siguiente Tarjeta",
  botonPagarTarjeta: "Botón pagar ahora Tarjeta",
  botonContinuarPagoEfectivo: "Boton continuar PagoEfectivo",
  botonSiguienteTransferencia: "Boton siguiente Transferencia",
  initiateCheckout: "InitiateCheckout",
  submitApplication: "SubmitApplication",
});

/**
 * Trigger Facebook Pixel's events
 */
export const triggerFacebookPixelEvent = (
  eventName,
  courseName,
  companyName
) => {
  if (
    companyName !== companies.enpp ||
    courseName !== "TRANSFORMACIÓN DIGITAL" ||
    courseName !== "OFIMÁTICA PROFESIONAL" ||
    courseName !== "BUSINESS ANALYTICS" ||
    courseName !== "EXCEL PROFESIONAL" ||
    courseName !== "ANÁLISIS DE DATOS  CON POWER BI" ||
    courseName !== "MICROSOFT EXCEL PROGRAMACIÓN VBA" ||
    courseName !== "EXCEL AVANZADO" ||
    courseName !== "EXCEL INTERMEDIO" ||
    courseName !== "EXCEL BÁSICO" ||
    courseName !== "POWER BI BÁSICO" ||
    courseName !== "MICROSOFT POWERPOINT" ||
    courseName !== "MICROSOFT EXCEL" ||
    courseName !== "REDACCION EFICAZ" ||
    courseName !== "MICROSOFT WORD"
  ) {
    return;
  }

  try {
    if (eventName === facebookPixelEvents.purchase) {
      window.fbq("track", "Purchase", {
        currency: "PEN",
        value: "1",
        content_name: courseName,
      });
    }

    if (eventName === facebookPixelEvents.botonSiguienteTarjeta) {
      window.fbq("trackCustom", "Botón siguiente Tarjeta", {
        content_name: courseName,
      });
    }

    if (eventName === facebookPixelEvents.botonPagarTarjeta) {
      window.fbq("trackCustom", "Botón pagar ahora Tarjeta", {
        content_name: courseName,
      });
    }

    if (eventName === facebookPixelEvents.botonContinuarPagoEfectivo) {
      window.fbq("trackCustom", "Boton continuar PagoEfectivo", {
        content_name: courseName,
      });
    }

    if (eventName === facebookPixelEvents.botonSiguienteTransferencia) {
      window.fbq("trackCustom", "Boton siguiente Transferencia", {
        content_name: courseName,
      });
    }

    if (eventName === facebookPixelEvents.initiateCheckout) {
      window.fbq("track", "InitiateCheckout", {
        content_name: courseName,
      });
    }

    if (eventName === facebookPixelEvents.submitApplication) {
      window.fbq("track", "SubmitApplication", {
        content_name: courseName,
      });
    }
  } catch (ex) {
    console.log(ex);
  }
};
