import axios from "axios";
import { getEndpoint } from "./endpoint.helper";

/**
 * Check whether user is authenticated or not
 * @returns {Promise<boolean>}
 */
export const isUserAuthenticated = async () => {
  // get base endpoint
  let endpoint = getEndpoint("authenticated-user");

  try {
    let response = await axios({
      method: "get",
      withCredentials: true,
      url: endpoint,
    });

    return !!response.data.user;
  } catch (ex) {
    return false;
  }
};

/**
 * Submit user credentials to server to authenticate user
 * @returns {Promise<boolean>}
 */
export const login = async (email, password, company) => {
  let success = false;
  let endpoint = getEndpoint("login");
  try {
    let response = await axios({
      method: "post",
      url: endpoint,
      // this configuration value is necessary to
      // generate or access session cookie
      withCredentials: true,
      data: {
        email: email,
        password: password,
        companyName: company,
      },
    });

    // get success value from result
    success = response.data.success;
  } catch (ex) {}

  return success;
};

/**
 * Destroy session cookie
 * @returns {Promise<boolean>}
 */
export const logout = async () => {
  let success = false;
  let endpoint = getEndpoint("logout");
  try {
    let response = await axios({
      method: "get",
      url: endpoint,
      // this configuration value is necessary to
      // generate or access session cookie
      withCredentials: true,
    });

    // get success value from result
    success = response.data.success;
  } catch (ex) {}

  return success;
};
