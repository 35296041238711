import {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {selectLanguage} from "../../../store/appInfoSlicer";
import {initializeTranslator} from "../../../helpers/language.helper";
import {fetchCourses} from "../../../helpers/courses.helper";
import {useParams} from "react-router-dom";
import {isCourseInformationComplete} from "./helper";
import Spinner from "../../core/Spinner/Spinner";
import ActionsMenu from "../../core/ActionsMenu/ActionsMenu";
import ModalDialog from "../../core/ModalDialog/ModalDialog";
import PriceDialog from "./PriceDialog/PriceDialog";
import {getInstallmentNumber} from "../../../helpers/string.helper";
import {formatTimestampToDateString} from "../helpers/helpers";
import RoundButton from "../../core/RoundButton/RoundButton";
import "./Courses.scss";

/**
 * Component
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const Courses = ({ activeCourseChanged }) => {

  // get values from Redux state
  const language = useSelector(selectLanguage);

  // initialize translator with current language
  const t = initializeTranslator(language);

  // get route parameters
  const params = useParams();

  // ---------------------------------------
  // set state values

  const [nextCourseCode, setNextCourseCode] = useState(null)
  const [isLoading, setIsLoading] = useState(false);
  const [criteria, setCriteria] = useState('');
  const [courses, setCourses] = useState([]);
  const [activeCourse, setActiveCourse] = useState(null);
  const [filteredCourses, setFilteredCourses] = useState([]);
  const [priceDialogIsVisible, setPriceDialogIsVisible] = useState(false);

  // ---------------------------------------
  // Lifecycle hooks

  // hook to be executed when component has been MOUNTED
  useEffect(() => {
    (async () => {
      await fetchAndSortCourses();
    })();
  }, []);

  useEffect(() => {
    filterCourses();
  }, [criteria]);

  // ---------------------------------------
  // Functions

  /**
   * Event handler for Search event
   */
  const onSearch = () => {
    // refresh enrollments
    //fetchCoursesFromServer();
  };

  /**
   * Filter courses according criteria
   */
  const filterCourses = () => {
    let _criteria = criteria.toLowerCase().trim();
    let _courses = courses.filter((c) => {
      return c.name.toLowerCase().includes(_criteria);
    });
    setFilteredCourses(_courses);
  };

  /**
   * Fetch courses list from server
   * @returns {Promise<void>}
   */
  const fetchAndSortCourses = async () => {

    // update state to show spinner

    setIsLoading(true);

    // fetch courses the latest data (ignoring cache)

    let _courses = await fetchCourses(params.company, false);
    setNextCourseCode(_courses.nextCourseCode)
    _courses = _courses.companyCourses.sort((a, b) => {
      let stringA = a.name.toUpperCase().trim();
      let stringB = b.name.toUpperCase().trim();
      return stringA < stringB ? -1 : stringA > stringB ? 1 : 0;
    });

    // udpate state

    setCourses(_courses);
    setFilteredCourses(_courses);

    // update state to hide spinner

    setIsLoading(false);

    // update state to hide dialog

    setPriceDialogIsVisible(false);
  };

  /**
   * Generate description for installments schedule
   * @return {string}
   */
  const generateScheduleDescription = (schedule) => {

    let description = '';

    schedule.forEach((installment) => {
      let label =
        getInstallmentNumber(installment.installmentNumber, language) +
        ' ' +
        t("installment");
      let date = formatTimestampToDateString(installment.date);
      description += `${label}: ${date}<br/>`;
    });

    return description;
  };

  // ---------------------------------------
  // return component

  return (
    <div className="courses-component container-lg">

      {/* Loading spinner */}

      {isLoading ? <Spinner /> : null}

      <div className="row justify-content-between">

        {/*-----------------------------------------------------------------
            Search area */}

        <div className="col-8">
          {/* Title */}

          <div className="section-title-wrapper">
            <h1>{t("courses")}</h1>
          </div>

          {/* Search input wrapper */}

          <div className="search-field-wrapper">
            <img className="icon" src="imgs/icons/search.svg" alt="" />
            <input
              type="text"
              value={criteria}
              onChange={(e) => setCriteria(e.target.value)}
              placeholder={t("searchByCourseName")}
            />
            {criteria ? (
              <img
                onClick={onSearch}
                className="search"
                src="imgs/icons/arrow-right-red.svg"
                alt=""
              />
            ) : null}
          </div>
        </div>

        <div className="col-3 d-flex align-items-end">

          {/* Edit button area */}

          <RoundButton
              onClick={() => activeCourseChanged({
                id: null,
                code: nextCourseCode,
                is_active: true,
                is_featured: true,
                name: '',
                price: 0.00,
                category_id: null,
                type_id: null,
                mode: 'vivo'
              })}
              width={"100%"}
              height={"2.5rem"}
              outline={false}
              content={t("new")}
          />
        </div>

        {/*-----------------------------------------------------------------
            Table */}

        <div className="col-12 mt-5">
          {/* Table wrapper */}
          <div className="content-wrapper">
            <table className="table">
              <thead>
              <tr>
                <th className="column-name" scope="col">
                  {t("name")}
                </th>
                <th className="column-price" scope="col">
                  {t("prices")}
                </th>
                <th className="column-status" scope="col">
                  {t("status")}
                </th>
                <th className="column-featured" scope="col">
                  {t("showInPreenrollment")}
                </th>
                <th className="column-actions" scope="col">
                  {t("actions")}
                </th>
              </tr>
              </thead>
              <tbody>
              {filteredCourses.map((c) => {
                return (
                    <tr key={c.code}>
                      <td className="column-name">
                        {c.name}<br/>
                        <span className="chip purple me-1">
                         {c.mode.charAt(0).toUpperCase() + c.mode.slice(1)}
                        </span>

                        <span className="chip blue me-1">
                         {c.type.charAt(0).toUpperCase() + c.type.slice(1)}
                        </span>

                        <span className="chip orange">
                          <strong>Código:</strong> {c.code}
                        </span>
                      </td>

                      <td className="column-price">
                        {+c.price > 0 ? (
                          t("sale") + `: S/` + (+c.price).toFixed(2)
                        ) : (
                          <button type="button" className="switch-price-button">
                            {t("giftCourse")}
                          </button>
                        )}
                      </td>

                      <td className="column-status">
                        {isCourseInformationComplete(c) ? (
                          <img
                            src={"imgs/icons/check.svg"}
                            className="status-icon"
                          />
                        ) : (
                          <img
                            src={"imgs/icons/error.svg"}
                            className="status-icon"
                          />
                        )}
                      </td>

                      <td className="column-featured">
                        {c.is_featured ? (
                            <img
                                src={"imgs/icons/check.svg"}
                                className="status-icon"
                            />
                        ) : (
                            <img
                                src={"imgs/icons/error.svg"}
                                className="status-icon"
                            />
                        )}
                      </td>

                      <td className="column-actions ps-3 pt-3 border-0">
                        <ActionsMenu
                          options={[
                            // edit status option is going to be available only when enrollment is not paid yet
                            {
                              text: t("edit"),
                              isEnabled: true,
                              callback: () => activeCourseChanged(c),
                            },
                          ]}
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {/* ---------------------------------------
        Components for notifications, dialogs, popups, etc */}

      {/* Modal dialog to confirm price change */}

      {priceDialogIsVisible ? (
        <ModalDialog
          close={() => setPriceDialogIsVisible(false)}
          content={
            <PriceDialog
              course={activeCourse}
              onFail={() => setPriceDialogIsVisible(false)}
              onCourseUpdated={() => fetchAndSortCourses()}
            />
          }
        />
      ) : null}
    </div>
  );
};

export default Courses;
