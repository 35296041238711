import axios from "axios";
import { getEndpoint } from "../../../../helpers/endpoint.helper";
import { fetchGatewayValues } from "../../../../helpers/payment-gateway.helper";
import {
  currencies,
  languages,
  paymentMethods,
  uploadTypes
} from "../../../../store/enums";
import {generateUnixTimestamp} from "../../helpers/helpers";
require("dotenv").config();

/**
 * Download an image file from S3 bucket, and show it in the
 * provided IMG element
 * @param imgEl IMG HTML element (<img src="">)
 * @param filename Image filename to download
 * @param uploadType
 */
export const downloadAndShowS3Image = async (imgEl, filename, uploadType) => {
  // fetch download temporary url
  try {
    let url = uploadType === uploadTypes.courseBankInvoice
      ? getEndpoint("bank-invoice-download").replace("{0}", filename)
      : getEndpoint("bank-invoice-certificate-download").replace("{0}", filename)
    let response = await axios({
      method: "get",
      url: url,
      withCredentials: true,
    });

    if (response.data.url) {
      // show file to S3 using temporary URL
      imgEl.src = response.data.url;
    }
  } catch (ex) {
    console.log(ex);
  }
};

/**
 * Uplaod image to AWS S3 bucket
 * @param {object} file object from File HTML Input (<input type="file">)
 * @param {string} filename the name of the file when upload has finished
 * @param {function} successCallback function to be called after upload has
 * finished successfully
 * @param {function} failCallback function to be called when upload operation
 * failed
 */
export const uploadImageToS3 = async (
  file,
  filename,
  uploadType,
  successCallback,
  failCallback
) => {
  // when file object is not defined, stop method execution
  if (!file) {
    if (failCallback) {
      failCallback("File is not defined", null);
    }
    return;
  }

  // generate path relative to bucket where file will be uploaded
  const extension = "jpg";
  const key = `${filename}.${extension}`;

  // fetch upload temporary url
  try {
    let url = getEndpoint(uploadType).replace("{0}", key);
    let response = await axios({
      method: "get",
      url: url,
      withCredentials: true,
    });

    if (response.data.url) {
      // upload file to S3 using temporary URL
      const uploadUrl = response.data.url;
      const response2 = await axios.put(uploadUrl, file, {
        headers: { "Content-Type": file.type },
      });

      if (successCallback) {
        successCallback();
      }
    }
  } catch (ex) {
    if (failCallback) {
      failCallback();
    }
    console.log(ex);
  }
};

/**
 * Finish student enrollment
 * @param {object} enrollment
 * @param {object} operationNumber
 * @param {string} bankOperationNumber operation number generated by bank
 * @param {date} operationDatetime
 * @param {string} bank name
 * @param {number} amount payed amount
 * @returns {Promise<object>}
 */
export const updateEnrollmentStatus = async (
  enrollment,
  operationNumber,
  bankOperationNumber,
  operationDatetime,
  bank,
  amount
) => {
  // generate API endpoint
  let endpoint = getEndpoint("bank-transfer-enrollment");

  // convert datetime to timestamp
  let timestamp = generateUnixTimestamp(operationDatetime.getTime());

  // perform request to update enrollment payment
  try {
    let response = await axios({
      method: "post",
      url: endpoint,
      withCredentials: true,
      data: {
        // custom operation number generated by backend app
        operationNumber: operationNumber,
        // operation number generated by bank
        bankOperationNumber: bankOperationNumber,
        operationDatetime: timestamp,
        amount: amount,
        bank: bank,
      },
    });

    return {
      updated: response.data.success,
      operationNumber: response.data.operationNumber,
    };
  } catch (e) {
    console.log(e);
    return { updated: false };
  }
};

/**
 * Add certificates to existing enrollment
 */
export const submitCertificatesForEnrollment = async (
    enrollment, bankOperationNumber,
    operationDatetime, bank, amount, certificates
) => {

  // Perform request to add certificates for existing enrollment

  try {
    let response = await axios({
      method: "post",
      url: getEndpoint('bank-transfer-certificates'),
      withCredentials: true,
      data: {
        studentCourseId: enrollment.enrollment_id,
        bankOperationNumber: bankOperationNumber,
        operationDatetime: generateUnixTimestamp(operationDatetime),
        amount: amount,
        bank: bank,
        certificates,
        currencyCode: currencies.pen
      }
    });

    return response.data.success

  } catch (e) {
    console.log(e);
    return false
  }
}

/**
 * Create payment log for installment 2,3,4...
 * @param {number} installmentNumber
 * @param {object} student student values
 * @param {string} companyName
 * @param {string} courseCode
 * @returns {Promise<string|*|null>}
 */
export const createPaymentLogForInstallment = async (
  installmentNumber,
  student,
  companyName,
  courseCode
) => {
  if (installmentNumber >= 2) {
    let gatewayToken = await fetchGatewayValues(
      paymentMethods.transfer,
      currencies.pen,
      languages.es,
      companyName,
      courseCode,
      student,
      () => {},
      true,
      installmentNumber,
      [],
      student.id
    );

    return gatewayToken.operationNumber;
  }

  return null;
};
