import { useEffect, useState } from "react";
import {useHistory, useParams} from "react-router-dom";
import { generateBrandingValues } from "../../../helpers/branding.helper";
import { useSelector } from "react-redux";
import { selectLanguage } from "../../../store/appInfoSlicer";
import { initializeTranslator } from "../../../helpers/language.helper";
import { loadCssVariablesFile } from "../../checkout/CheckoutPage/helpers";
import { valuesHaveContent } from "../../../helpers/validation.helper";
import { login } from "../../../helpers/auth.helper";
import Header from "../../core/Header/Header";
import Footer from "../Footer/Footer";
import RoundButton from "../../core/RoundButton/RoundButton";
import ToastNotification from "../../core/ToastNotification/ToastNotification";
import "../style.scss";
import "./Login.scss";

/**
 * Component
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const Login = (props) => {
  // Update page title
  document.querySelector("head title").innerHTML = "Backoffice - Login";

  // get routes history instance
  let history = useHistory();

  // get route parameters
  const params = useParams();

  // get values from Redux state
  const language = useSelector(selectLanguage);

  // initialize translator with current language
  const t = initializeTranslator(language);

  // ---------------------------------------
  // set state values

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [formIsValid, setFormIsValid] = useState(false);

  // notification
  const [notificationTitle, setNotificationTitle] = useState("");
  const [notificationMessage, setNotificationMessage] = useState("");
  const [notificationIsVisible, setNotificationIsVisible] = useState(false);

  // ---------------------------------------
  // get company name from url, set logo filename,
  // and background color according company

  let { logoFilename, companyUrl } = generateBrandingValues(params.company);

  // load CSS variables
  if (params.company) loadCssVariablesFile(params.company);

  // ---------------------------------------
  // Lifecycle hooks

  // execute when component is MOUNTED
  useEffect(() => {
    // set body background color
    document.body.style.backgroundColor = "#F9F9F9";

    // if user is already authenticated, redirect
    if (props.isAuthenticated) {
      goToDefaultPage();
    }
  }, []);

  // execute when component is UPDATED
  useEffect(() => {
    validateForm();
  }, [email, password]);

  // execute when component is UPDATED
  useEffect(() => {
    if (props.isAuthenticated) {
      goToDefaultPage();
    }
  }, [props.isAuthenticated]);

  // ---------------------------------------
  // Functions

  /**
   * Redirect to default page
   */
  const goToDefaultPage = () => {
    history.push(`/${params.company}/backoffice/enrollments/list`);
  };

  /**
   * Validate login  form
   */
  const validateForm = () => {
    // check whether required fields have content
    let formIsValid = valuesHaveContent([email, password]);
    setFormIsValid(formIsValid);
  };

  /**
   * Callback to be called after notification has been closed
   */
  const onNotificationClose = () => {
    setNotificationIsVisible(false);
  };

  /**
   * Event handler click event in Login button
   */
  const onLoginClick = async () => {
    // try to login user with provided credentials
    let loggedId = await login(email, password, params.company);
    if (loggedId) {
      // update component state
      setNotificationIsVisible(false);

      // raise login event from parent component
      props.onLogin();

      // redirect to default page
      goToDefaultPage();
    } else {
      // update component state to show notification
      setNotificationTitle(t("authenticationFailed"));
      setNotificationMessage(t("authenticationFailedMessage"));
      setNotificationIsVisible(true);
    }
  };

  // ---------------------------------------
  // return component

  return (
    <div className="login-component font-main">
      {/* Page header */}
      <Header
        logoFilename={logoFilename}
        companyUrl={companyUrl}
      ></Header>

      {/* Page content wrapper */}
      <div className="container-lg">
        <div className="row justify-content-center">
          <div className="col-12 col-sm-10 col-lg-7 col-xl-6">
            <div className="login-panel">
              <h1>{t("login")}</h1>

              {/* Login Form */}
              <form className="default-form">
                <div className="row justify-content-center">
                  <div className="col-10 col-lg-8">
                    <label>{t("email")}</label>
                    <div className="input-wrapper mb-4 mt-2">
                      <input
                        type="text"
                        name="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder={t("typeHere")}
                      />
                    </div>
                  </div>
                </div>
                <div className="row justify-content-center">
                  <div className="col-10 col-lg-8">
                    <label>{t("password")}</label>
                    <div className="input-wrapper mt-2 position-relative">
                      <input
                        type={showPassword ? "text" : "password"}
                        name="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        placeholder={t("typeHere")}
                      />

                      <img
                        className="show-password-icon"
                        onClick={() => setShowPassword(!showPassword)}
                        src="imgs/icons/eye.svg"
                        alt=""
                      />
                    </div>
                  </div>
                </div>

                <div className="row justify-content-center">
                  <div className="col-10 col-lg-8 button-wrapper d-flex justify-content-center">
                    <RoundButton
                      isDisabled={!formIsValid}
                      onClick={onLoginClick}
                      width={"100%"}
                      outline={false}
                      content={t("enter")}
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      {/* Page footer */}
      <Footer companyName={params.company} transparent={true} />

      {/* Toast notification */}
      <ToastNotification
        title={notificationTitle}
        message={notificationMessage}
        isVisible={notificationIsVisible}
        closeCallback={onNotificationClose}
      />
    </div>
  );
};

export default Login;
