import "./ActionsMenu.scss";
import { useEffect, useState } from "react";

/**
 * Component
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const ActionsMenu = (props) => {
  // ---------------------------------------
  // set state values

  const [menuIsVisible, setMenuIsVisible] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [items, setItems] = useState([]);

  // lifecycle hooks
  // ---------------------------------------

  // hook to be executed when component has been UPDATED
  useEffect(() => {
    setSelectedItem(props.selectedItem);
  }, [props.selectedItem]);

  // hook to be executed when component has been UPDATED
  useEffect(() => {
    generateItemsCollection();
  }, [props.options]);

  // ---------------------------------------
  // functions

  /**
   * Event handler for click event in menu options
   */
  const onOptionClick = (item) => {
    // update component state
    setMenuIsVisible(false);

    // run item callback
    item.callback();
  };

  /**
   * Generate items collection, update Checked
   * property if there are selected items
   */
  const generateItemsCollection = () => {
    let _items = [];
    props.options.forEach((option) => {
      if (option)
        _items.push(option);
    });
    setItems(_items);
  };

  // ---------------------------------------
  // return component

  return (
    <div
      className={
        menuIsVisible
          ? "actions-menu-component active"
          : "actions-menu-component"
      }
    >
      <img
        onClick={() => {
          setMenuIsVisible(!menuIsVisible);
        }}
        src="imgs/icons/options.svg"
        alt=""
      />

      {/* Menu */}
      {items ? (
        <ul className="menu">
          {/* Render menu options */}
          {items.map((o) => {
            return (
              <li
                onClick={() => onOptionClick(o)}
                className={o.isEnabled ? "" : "disabled"}
                key={o.text}
              >
                {/* Menu option's text */}
                {o.text}
              </li>
            );
          })}
        </ul>
      ) : null}
    </div>
  );
};

export default ActionsMenu;
