import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  selectCurrency,
  selectLanguage,
} from "../../../../../store/appInfoSlicer";
import { initializeTranslator } from "../../../../../helpers/language.helper";
import {
  mailLooksValid,
  valuesHaveContent,
} from "../../../../../helpers/validation.helper";
import { fetchGatewayValues } from "../../../../../helpers/payment-gateway.helper";
import { paymentMethods } from "../../../../../store/enums";
import RoundButton from "../../../../core/RoundButton/RoundButton";
import {
  facebookPixelEvents,
  triggerFacebookPixelEvent,
} from "../../../../../helpers/facebook-pixel.helper";
import "./PagoefectivoForm.scss";
import { useParams } from "react-router-dom";

/**
 * Validate student form
 */
const isFormValid = (firstName, lastName, email, mobile, dni) => {
  // check whether required fields have content
  let requiredValid = valuesHaveContent([
    firstName,
    lastName,
    email,
    mobile,
    dni,
  ]);

  // mobile number should have at least 9 numbers
  if (mobile.length < 9) requiredValid = false;

  // email address looks valid
  let emailLooksValid = mailLooksValid(email);

  return {
    form: requiredValid && emailLooksValid,
    email: emailLooksValid,
  };
};

/**
 * Component
 * @returns {JSX.Element}
 * @constructor
 */
const PagoefectivoForm = (props) => {
  // get route parameters
  const params = useParams();

  // get values from Redux state
  const language = useSelector(selectLanguage);
  const currency = useSelector(selectCurrency);

  // initialize translator with current language
  const t = initializeTranslator(language);

  // ---------------------------------------
  // set state values

  // user data
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [mobile, setMobile] = useState("");
  const [dni, setDni] = useState("");

  // validation
  const [formIsValid, setFormIsValid] = useState(false);
  const [emailLooksValid, setEmailLooksValid] = useState(false);

  // ---------------------------------------
  // lifecycle hooks

  // form validation when component is UPDATED
  useEffect(() => {
    // validate form
    let valid = isFormValid(firstName, lastName, email, mobile, dni);
    let emailErrorMessage = "";
    if (email) {
      if (!valid.email) emailErrorMessage = t("emailIsInvalid");
    }

    // update state
    setFormIsValid(valid.form);
    setEmailLooksValid(valid.email);
    setEmailError(emailErrorMessage);
  }, [firstName, lastName, email, mobile, dni]);

  // ---------------------------------------
  // Functions

  // generate CIP code for PagoEfectivo service
  const generatePagoEfectivoCip = async () => {
    // trigger facebook pixel event
    triggerFacebookPixelEvent(
      facebookPixelEvents.botonContinuarPagoEfectivo,
      props.courseName,
      props.companyName
    );

    // show spinner before request
    props.showSpinner();

    // fetch payment gateway values
    let student = {
      first_name: firstName,
      last_name: lastName,
      email: email,
      dni: dni,
      mobile: mobile,
    };

    let values = await fetchGatewayValues(
      paymentMethods.pagoefectivo,
      currency,
      language,
      props.companyName,
      props.courseCode,
      student,
      () => {
        props.hideSpinner();
        props.showNotification("", true);
      },
      props.withInstallments,
      params.installmentNumber,
      props.certificates,
      params.studentId
    );

    // hide spinner since request has finished
    props.hideSpinner();

    // show cip code
    if (values) {
      if (values.cipCode) {
        // trigger facebook pixel event
        triggerFacebookPixelEvent(
          facebookPixelEvents.submitApplication,
          props.courseName,
          props.companyName
        );

        // execute parent callback
        props.onCipCodeGenerated(
          values.cipCode,
          values.cipUrl,
          values.coursePrice,
          values.expiration
        );
      }
    }
  };

  // return component

  return (
    <div>
      <div className="row mt-1 pt-4">
        <p dangerouslySetInnerHTML={{ __html: t("pagoEfectivoDescription") }} />
        <div className="pagoefectivo-logos-wrapper">
          <img src="imgs/logos/banks-pagoefectivo.png" alt="" />
        </div>
      </div>
      <form className="pagoefectivo-form payment-form row mt-4 ps-2 pe-3 ps-sm-0 pe-sm-0">
        <div className="col-12 col-lg-6">
          <label>{t("cardHolderFirstName")}</label>
          <div className="input-wrapper mb-4 mt-2">
            <input
              type="text"
              name="first-name"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              placeholder={t("typeHere")}
            />
          </div>
        </div>
        <div className="col-12 col-lg-6">
          <label>{t("cardHolderLastName")}</label>
          <div className="input-wrapper mb-4 mt-2">
            <input
              type="text"
              name="last-name"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              placeholder={t("typeHere")}
            />
          </div>
        </div>

        <div className="col-12 col-lg-6 position-relative">
          <label>{t("email")}</label>
          <div className="input-wrapper mb-4 mt-2">
            <input
              type="text"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder={t("typeHere")}
            />
          </div>
          <span className="email error">{emailError}</span>
        </div>

        <div className="col-12 col-lg-6 position-relative">
          <label>{t("mobile")}</label>
          <div className="input-wrapper mb-4 mt-2">
            <input
              type="text"
              name="mobile"
              value={mobile}
              onChange={(e) => setMobile(e.target.value)}
              placeholder={t("typeHere")}
            />
          </div>
        </div>

        <div className="col-12 col-lg-6 position-relative">
          <label>DNI</label>
          <div className="input-wrapper mb-4 mt-2">
            <input
              type="text"
              name="dni"
              value={dni}
              onChange={(e) => setDni(e.target.value)}
              placeholder={t("typeHere")}
            />
          </div>
        </div>

        <div className="col-12 pt-4">
          <RoundButton
            onClick={generatePagoEfectivoCip}
            isDisabled={!formIsValid || !emailLooksValid}
            width={"20.625rem"}
            content={t("continue")}
          />
        </div>
      </form>
    </div>
  );
};

export default PagoefectivoForm;
