import "./ModalDialogTitle.scss";

/**
 * Component
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const ModalDialogTitle = ({ title }) => {
  // ---------------------------------------
  // return component

  return (
    <div className="modal-dialog-title mb-3">
      <h2>{title}</h2>
    </div>
  );
};

export default ModalDialogTitle;
