import "../style.scss";

/**
 * Component
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const TermsConditionsIPAPPG = (props) => {
  return (
    <div className="terms-conditions-component p-3">
      <h3 className=" text-center" align="center">
        INDICE
      </h3>

      <ol start="1" type="1">
        <li className=" text-justify">
          <span>
            Política de Contrataciones - Descripción general de los bienes
          </span>
        </li>
        <li className=" text-justify">
          <span>
            Política de Devoluciones, reembolso y cancelación de servicios
            adquiridos
          </span>
        </li>
        <li className=" text-justify">
          <span>Política de Recepción de reclamos</span>
        </li>
        <li className=" text-justify">
          <span>Política de Privacidad de Información al consumidor</span>
        </li>
      </ol>

      <h2>1. POLÍTICA DE CONTRATACIONES</h2>

      <p className=" text-justify mb-3">
        <span>
          Esta política de devoluciones, solo es aplicable si ya has comprado en
          IPAPPG SAC, a través de nuestro sitio web. Por eso, el presente
          documento contiene los términos de las políticas de devoluciones, bajo
          los cuales en IPAPPG SAC nos comprometemos a respetar y cuidar tus
          derechos como consumidor, según los lineamientos establecidos en el
          código de protección al consumidor; así que, tenemos la satisfacción
          de informar los aspectos legales, tus derechos y obligaciones desde el
          momento en que compras en esta web.
        </span>
      </p>

      <p className=" text-justify mb-3">
        <span>
          El internet también tenemos el deber de respetar las normas que
          tutelan tus derechos y los nuestros, por eso, en Defensa de los
          Consumidores y Usuarios te informamos que, en lo referido a cursos,
          por ser un producto digital, sin soporte material de entrega
          presencial, se realizan devoluciones, solo bajo las siguientes
          situaciones, además debes de tener presente lo siguiente:
        </span>
      </p>

      <h3>Del cliente</h3>

      <p className=" text-justify mb-3">
        <span>
          Desde el momento en que un usuario realiza una compra en IPAPPG SAC,
          pasa a convertirse de un usuario visitante a un cliente.
        </span>
      </p>

      <h3>Cursos digitales</h3>

      <p className=" text-justify mb-3">
        <span>
          El cliente desde el momento que realiza la compra de los cursos de
          descarga digital que son de venta final, es decir, los que al ser
          pagados automáticamente se realiza la entrega digitalmente, una vez
          facilitado el acceso a curso digital es tuyo para siempre para tu uso
          y disfrute por lo que no podemos ofrecer devolución del importe
          pagado.
        </span>
      </p>

      <p className=" text-justify mb-3">
        <span>
          Solo los cursos y/o productos que especifiquen claramente en la
          descripción del mismo que tienen garantía de devolución son
          susceptibles de dicha devolución si se cumplen las condiciones citadas
          en la misma garantía.
        </span>
      </p>

      <p className=" text-justify mb-3">
        <span>
          Esta siempre será claramente visible en la página de compra y
          descripción del producto, servicio o curso por lo que el comprador
          puede y debe leer la garantía antes de realizar el pago.
        </span>
      </p>

      <p className=" text-justify mb-3">
        <span>
          Si has adquirido un producto digital por error y es demostrable que no
          lo has descargado o accedido aún, puedes pedir la devolución. En este
          caso escribe a <u>vhuamani@ipappg.edu.pe</u> con tu número de pedido
          para solicitarlo.
        </span>
      </p>

      <p className=" text-justify">
        <span>Datos del comercio</span>
      </p>

      <p className=" text-justify">
        <span>Correo electrónico: </span>
        <span>
          <a href="mailto:vhuamani@ipappg.edu.pe">
            <span>vhuamani@ipappg.edu.pe</span>
          </a>
        </span>
      </p>

      <p className=" text-justify">
        <span>Teléfono: +51 949483153</span>
      </p>

      <p className=" text-justify">
        <span>
          Dirección: Av. República de Chile 476, Oficina 201, Jesús María, Lima
          - Perú
        </span>
      </p>

      <h3>
        <a name="_71dx22wfufj9" />
      </h3>

      <h3>
        <a name="_m14z2z93vc1d" />
        <b>
          <u>
            <span className="title">Descargo de responsabilidad</span>
          </u>
        </b>
      </h3>

      <p className=" text-justify mb-3">
        <span>
          En reciprocidad a tu confianza, somos totalmente honestos contigo,
          explicándote a detalle todo lo que sucede desde el momento en que
          contratas los servicios de IPAPPG SAC o uno de mis infoproductos, a
          través del sitio web.
        </span>
      </p>

      <p className=" text-justify mb-3">
        <span>
          Primero debes saber que no creemos ni propiciamos promesas de hacerte
          rico fácil y rápido y sin ningún esfuerzo de tu parte.
        </span>
      </p>

      <p className=" text-justify mb-3">
        <span>
          Somos conscientes que todo éxito para lograr tus objetivos conlleva un
          trabajo detrás y los resultados no solo dependen de nosotros, sino
          también del tiempo que empleas y si pones en práctica o no nuestros lo
          aprendido en cada una de las lecciones del curso y recomendaciones.
        </span>
      </p>

      <p className=" text-justify mb-3">
        <span>
          Sin embargo, por sí solas no pueden ofrecerte resultados, si no las
          usas estratégicamente como te enseñamos y de la misma forma si solo
          aplicamos nuestros consejos y no tomas en cuenta las herramientas que
          te sugerimos, no podemos tener garantizar los mismos resultados.
        </span>
      </p>

      <h3>
        <a name="_tiul4r3xjwtv" />
        <b>
          <u>
            <span className="title">Propiedad intelectual</span>
          </u>
        </b>
      </h3>

      <p className=" text-justify mb-3">
        <span>
          El sitio web de propiedad de IPAPPG SAC y todo su contenido
          (incluyendo – pero no limitado a – posts, imprimibles, ebooks, cursos,
          vídeos, etc.) contienen propiedad intelectual de propiedad de IPAPPG
          SAC, incluidas marcas de comercio, derechos de autor, información
          propietaria y otros derechos.
        </span>
      </p>

      <p className=" text-justify mb-3">
        <span>
          Por lo tanto, de los productos que adquieres no puedes modificar,
          publicar, transmitir, participar en la transferencia o venta, crear
          obras derivativas, plagiar, copiar, hacer pasar el trabajo como tuyo,
          distribuir, exhibir, reproducir, o de ninguna manera explotar de
          ninguna forma y en ningún formato ninguna parte del contenido de este
          sitio, sus servicios o cualquier propiedad intelectual, en todo ni en
          parte, sin nuestro consentimiento previo.
        </span>
      </p>

      <p className=" text-justify mb-3">
        <span>
          El contenido que incluye cada uno de los cursos de IPAPPG SAC es de
          autoría de sus tutores y solo ellos pueden disponer de los derechos
          morales y considerar y se exhiben en la plataforma de IPAPPG SAC por
          la cesión de derechos patrimoniales sobre el contenido creado
          exclusivamente para IPAPPG SAC.
        </span>
      </p>

      <p className=" text-justify mb-3">
        <span>
          A consecuencia en IPAPPG SAC si te encontramos transgrediendo los
          derechos de propiedad intelectual, nos reservamos el derecho de
          remover inmediatamente de nuestros cursos, sin tener la obligación de
          reembolso, si eres descubierto violando nuestra propiedad intelectual
          y demandarte antes los tribunales correspondientes.
        </span>
      </p>

      <h3>
        <a name="_dqmlsv4gbamj" />
        <b>
          <u>
            <span className="title">Del servicio de post venta</span>
          </u>
        </b>
      </h3>

      <p className=" text-justify mb-3">
        <span>
          Este servicio es brindado por nuestro equipo de atención y bienestar
          del consumidor y nació con la finalidad de brindar una mejor
          experiencia al cliente y atender las necesidades que tienen nuestros
          clientes después de haber realizado compras en nuestras webs.
        </span>
      </p>

      <p className=" text-justify mb-3">
        <span>
          El personal asignado para la atención post venta, es asignado
          aleatoriamente en IPAPPG SAC y no será exclusivo de una persona en
          específico, por lo tanto, el usuario puede darse por satisfecho, con
          la prestación del servicio prestado por cualquier persona asignada de
          nuestro equipo de atención y satisfacción del usuario.
        </span>
      </p>

      <p className=" text-justify mb-3">
        <span>
          Si tienes alguna pregunta sobre mi disclaimer de productos y
          servicios, la información que te he recopilado en línea, las prácticas
          de mi sitio o sus relaciones con este sitio web, contáctame en correo
          electrónico:
          <u>vhuamani@ipappg.edu.pe</u>
        </span>
      </p>

      <h2>2. POLÍTICA DE DEVOLUCIONES</h2>

      <p className=" text-justify mb-3">
        <span>
          Recuerda que solo los cursos y/o productos que especifiquen claramente
          en la descripción del mismo que tienen garantía de devolución son
          susceptibles de dicha devolución si se cumplen las condiciones citadas
          en la misma garantía.
        </span>
      </p>

      <p className=" text-justify mb-3">
        <span>
          En otros casos, la devolución solo será aceptada si se cumplen todas
          las siguientes condiciones: el curso fue comprado por error, no se
          descargó ni consumió ningún tipo de contenido ni ninguna clase, han
          pasado menos de 7 días naturales desde la compra hasta la solicitud de
          devolución. Si alguna de estas condiciones no se cumple, no será
          posible concretar la devolución.
        </span>
      </p>

      <p className=" text-justify mb-3">
        <span>
          Si tienes alguna pregunta sobre mi disclaimer de cursos, la
          información que te he recopilado en línea, las prácticas de mi sitio o
          sus relaciones con este sitio web, contáctame en: Correo electrónico:
        </span>
        <span>
          <a href="mailto:vhuamani@ipappg.edu.pe">
            <span>vhuamani@ipappg.edu.pe</span>
          </a>
        </span>
      </p>

      <h2>3. POLÍTICA DE RECLAMOS/QUEJAS</h2>

      <p>
        <span>
          El cliente ante cualquier inconformidad además de la atención de
          nuestro servicio Postventa, podrá generar su propio reclamo o queja
          dependiendo de la casuística en el siguiente enlace
          <a href="http://librodereclamacionesperu.com/65431777.html">
            http://librodereclamacionesperu.com/65431777.html
          </a>
          o ubicar su enlace en la parte inferior de la página oficial donde
          deberá completar los campos solicitados.Para lo cual se le generará un
          código de reclamo que podrá verificar su historial en el buscador del
          enlace, así como podrá obtener respuesta dependiendo la severidad de
          la inconformidad, sea queja para aquella disconformidad directamente
          relacionado con los servicios brinda este proveedor y queja para todo
          lo directamente relacionado como la atención preventa o postventa.
          Siendo los plazos de atención para reclamos hasta 7 días útiles,
          mientras que para quejas hasta 7 días hábiles.
        </span>
      </p>

      <h2>4. POLÍTICA DE PRIVACIDAD</h2>

      <p className=" text-justify mb-3">
        <span>
          La presente política de privacidad en https://ipappg.edu.pe es
          conforme lo dispuesto en la normativa vigente de protección de tus
          derechos, en aplicación de la Ley Nº 29733 que regula la Protección de
          Datos Personales, su reglamento y la Ley N° 28493 que regula el uso de
          correo electrónico comercial no solicitado de la legislación peruana;
          así que, puedes estar tranquilo que nuestro sitio respeta totalmente
          tus derechos.
        </span>
      </p>

      <h3>
        <a name="_3dga54cxmmh7" />
        <span className="title">
          Identificación del responsable de los datos personales
        </span>
      </h3>

      <p className=" text-justify mb-3">
        <span>
          El dominio y el sitio web https://ipappg.edu.pe/ es propiedad de
          IPAPPG SAC, identificado con RUC: 20565399561, en Lima – Perú, en lo
          siguiente IPAPPG SAC, manifiesta en la presente POLÍTICA DE PRIVACIDAD
          que, la presente es con la finalidad de darte a conocer el tipo de
          tratamiento de los datos personales que recolectamos.
        </span>
      </p>

      <p className=" text-justify mb-3">
        <span>
          Este aviso de política de privacidad, regula el acceso, navegación y
          utilización de IPAPPG SAC, se reserva el derecho a modificar la
          presentación, la configuración y el contenido del mismo, así como las
          condiciones requeridas para su acceso y uso. El acceso y uso de los
          contenidos de IPAPPG SAC después de la entrada en vigor de sus
          modificaciones o cambios suponen la aceptación de los mismos.
        </span>
      </p>

      <p className=" text-justify mb-3">
        <span>
          IPAPPG SAC, se compromete a garantizar el derecho fundamental a la
          protección de datos personales de los usuarios registrados en nuestra
          lista de correos, clientes, y demás usuarios, con ello he implementado
          mecanismos y medidas técnicas, organizativas y legales que me permiten
          proteger y resguardar su información personal. De esta forma, IPAPPG
          SAC se alinea a la Ley No 29733. Ley de Protección de Datos
          Personales.
        </span>
      </p>

      <p className=" text-justify mb-3">
        <span>
          En tal sentido, te informamos que los responsables de los datos
          personales es, IPAPPG SAC, y solo recolectamos los datos que nos
          proporcionas voluntariamente y serán almacenados por MAILCHIMP, que es
          la plataforma de email marketing que usamos actualmente, este
          proveedor de email marketing se encuentra ubicado en Atlanta y la
          hemos elegido por su seriedad con el tratamiento de tus datos
          personales, para saber más de ellos y su política de privacidad puedes
          leerla
        </span>
        <span>
          <a href="https://mailchimp.com/legal/privacy/">
            <span>aquí.</span>
          </a>
        </span>
      </p>

      <h3>
        <a name="_6drxun3e161e" />
        <span className="title">¿Cómo se recolectan tus datos personales?</span>
      </h3>

      <p className=" text-justify mb-3">
        <span>En los formularios de suscripción a contenidos:</span>
      </p>

      <p className=" text-justify mb-3">
        <span>
          En IPAPPG SAC, vas a encontrar varios formularios para activar la
          suscripción, en los cuales puedes elegir suscribirte según tus
          preferencias y necesidades, por ello es necesario que sepas que uso el
          doble optin, por lo tanto cada vez que ingreses tus datos debes
          verificar si te llegó el correo de confirmación de suscripción tanto
          en tu bandeja de spam o de promociones si usas gmail, posterior a tu
          confirmación te va a llegar automáticamente el regalo por el cual te
          suscribiste, además de ello por favor te pido que añadas el correo
          vhuamani@ipappg.edu.pe a tu lista de remitentes permitidos, para poder
          estar en contacto contigo, si no verificas el email nunca te va a
          llegar nuestro regalo que solicitaste, además quiero que sepas que no
          guardamos las direcciones que no se han confirmado, por lo tanto, no
          te llegará nada que no nos solicites previamente.
        </span>
      </p>

      <p className=" text-justify mb-3">
        <span>Función de comentarios del contenido:</span>
      </p>

      <p className=" text-justify mb-3">
        <span>
          Si abrimos comentarios en algún contenido, cuenta con un formulario
          que puedes usar para publicar tus comentarios o preguntas, por lo
          tanto, antes de ser públicos los comentarios pasan por un filtro de
          aprobación, además de ello tienes la opción de elegir si se guarda o
          no tu nombre, correo electrónico y sitio web en este navegador para la
          próxima vez que hagas un comentario, si quieres recibir un email con
          los siguientes comentarios a la entrada, y si deseas recibir o no un
          email con cada nueva entrada, además los datos que proporcionas para
          realizar comentarios solo son exclusivamente usados para ese fin.
        </span>
      </p>

      <p className=" text-justify mb-3">
        <span>El uso de Cookies:</span>
      </p>

      <p className=" text-justify mb-3">
        <span>
          Al momento de navegar por nuestra web, se almacenan “cookies” en tu
          ordenador, por eso debes leer nuestra política de cookies para ampliar
          información sobre el uso de estas y cómo puedes gestionarlas.
        </span>
      </p>

      <p className=" text-justify mb-3">
        <span>Al activar tu suscripción, debes saber que:</span>
      </p>

      <p className=" text-justify mb-3">
        <span>
          Desde tu suscripción donde nos facilitas tu nombre de usuario y correo
          electrónico, asumimos que los datos personales facilitados a través de
          los diferentes formularios son veraces, puedes comunicar cualquier
          modificación de los mismos. Asimismo asumimos que toda la información
          facilitada corresponde a tu situación real, que está puesta al día y
          es exacta.
        </span>
      </p>

      <h3>
        <a name="_9q8b2ckngtc" />
        <span className="title">Legitimidad de los datos recogidos</span>
      </h3>

      <p className=" text-justify mb-3">
        <span>
          IPAPPG SAC recoge y utiliza muchos tipos de información procedentes de
          un amplio rango de fuentes para diferentes propósitos.
        </span>
      </p>

      <p className=" text-justify mb-3">
        <span>
          Entre ellos se encuentran incluidos, pero no limitados, a los
          siguientes:
        </span>
      </p>

      <p>
        <span className="bullet">●</span>
        <span>
          {" "}
          Promover y prestar los servicios contratados, siempre salvaguardando
          la privacidad de los interesados
        </span>
      </p>

      <p>
        <span className="bullet">●</span>
        <span>
          Innovar y mejorar los servicios que ofrecemos a los interesados
          ofreciéndoles una experiencia de uso más personalizada, así como la
          oferta de cursos y servicios que mejor se ajuste a sus necesidades y
          hábitos del interesado.
        </span>
      </p>

      <p>
        <span className="bullet">●</span>
        <span>
          {" "}
          Seleccionar y gestionar a nuestros empleados y colaboradores.
        </span>
      </p>

      <p>
        <span className="bullet">●</span>
        <span>
          Eventualmente, recoger información con otros fines, que será
          comunicado de forma simple y transparente, dando siempre al cliente la
          posibilidad de oponerse a los nuevos tratamientos, siempre de acuerdo
          con la legislación aplicable.
        </span>
      </p>

      <h3>
        <a name="_162tcussekh4" />
        <b>
          <u>
            <span className="title">
              Seguridad e integridad de los datos personales.
            </span>
          </u>
        </b>
      </h3>

      <p className=" text-justify mb-3">
        <span>
          Gestionamos y almacenamos de forma responsable la información de
          nuestros clientes, manteniéndola segura.
        </span>
      </p>

      <p className=" text-justify mb-3">
        <span>
          Nuestra política de privacidad establece los niveles de seguridad
          requeridos dentro de los procesos de creación, lanzamiento de nuestros
          cursos e inscripción de nuestros alumnos en los mismos, para preservar
          la protección y la integridad de los datos de nuestros clientes e
          interesados.
        </span>
      </p>

      <p className=" text-justify mb-3">
        <span>
          Esta Política se persigue la adopción de acciones destinadas a
          preservar los cuatro componentes básicos de la seguridad aplicados a
          la información:
        </span>
      </p>

      <p>
        <span className="bullet">●</span>
        <span>
          Confidencialidad: Permitimos el acceso a los datos y a nuestros
          sistemas solo a aquellas personas debidamente autorizadas siguiendo el
          “principio de necesidad de uso”.
        </span>
      </p>

      <p>
        <span className="bullet">●</span>
        <span>
          Integridad: Preservamos la exactitud de la información y de los
          sistemas contra cualquier tipo de alteración, pérdida o destrucción,
          ya sea de forma accidental o fraudulenta.
        </span>
      </p>

      <p>
        <span className="bullet">●</span>
        <span>
          Disponibilidad: Establecemos los mecanismos necesarios para que la
          información y
        </span>
      </p>

      <p>
        <span className="bullet">●</span>
        <span>
          Auditabilidad: Facilitamos que cualquier acción o transacción pueda
          ser relacionada unívocamente asegurando el cumplimiento de controles
          clave establecidos en las correspondientes normativas.
        </span>
      </p>

      <h3>
        <a name="_xj9c0h33e2at" />
        <span className="title">
          Ejercicio del derecho de acceso, rectificación, cancelación u
          oposición.
        </span>
      </h3>

      <p className=" text-justify mb-3">
        <span>
          Asimismo, puedes ejercer tu derecho de acceso, rectificación,
          cancelación, oposición y otros contemplados en la ley de Protección de
          Datos Personales, a través de los procedimientos implementados por
          IPAPPG SAC, para la atención de los mismos.
        </span>
      </p>

      <p className=" text-justify mb-3">
        <span>
          Para tal efecto, ponemos a su disposición el correo electrónico:
          vhuamani@ipappg.edu.pe, para que puedas requerir información sobre los
          procedimientos en mención.
        </span>
      </p>

      <p className=" text-justify mb-3">
        <span>
          Finalmente, hago de tu conocimiento que puedes acudir ante la
          Autoridad Nacional de Protección de Datos Personales en vía de
          reclamación o al Poder Judicial, o en la entidad correspondiente si te
          encuentras fuera del Perú, cuando IPAPPG SAC deniegue total o
          parcialmente el ejercicio de los derechos establecidos en la normativa
          de protección de datos personales.
        </span>
      </p>

      <p className=" text-justify mb-3">
        <span>
          Si tienes alguna pregunta sobre mi política de privacidad, la
          información que hemos recopilado en línea, las prácticas de este sitio
          o sus relaciones con este sitio web, contáctanos:
        </span>
      </p>

      <p className=" text-justify mb-3">
        <span>Correo electrónico: </span>
        <span>
          <a href="mailto:vhuamani@ipappg.edu.pe">
            <span>vhuamani@ipappg.edu.pe</span>
          </a>
        </span>
      </p>

      <p className=" text-justify mb-3">
        <b>
          <u>
            <span>SOBRE  COOKIES</span>
          </u>
        </b>
      </p>

      <p className=" text-justify mb-3">
        <span>
          Aquí en IPAPPG SAC mi prioridad es respetar tus derechos, para ello
          necesito mantenerte informado de las cookies que tengo en mi sitio
          web, como podrás haberte dado cuenta cookies propias y de terceros
          para mejorar tu experiencia de usuario y ofrecerte los mejores
          servicios.
        </span>
      </p>

      <p className=" text-justify mb-3">
        <span>
          Las cookies que se consideran como propias son imprescindibles para
          poder navegar con normalidad por nuestro blog. No obstante, tienes la
          opción de deshabilitar y eliminar las cookies en el navegador. Para
          saber cómo configurar estas opciones, puedes consultar nuestro
          apartado ¿Cómo administrar las cookies en el navegador?
        </span>
      </p>

      <p className=" text-justify mb-3">
        <span>
          Y no, las cookies no son simples galletas, tienen una misión
          importante y es contarme tus hábitos en mi web, para saber cómo puedo
          ayudarte a tener una mejor experiencia de usuario.
        </span>
      </p>

      <p className=" text-justify mb-3">
        <span>
          En caso de que bloquees las cookies en tu navegador, puedes seguir
          navegando por el sitio, pero es posible que algunos servicios no estén
          disponibles, y la experiencia de navegación puede verse limitada.
        </span>
      </p>

      <h3>
        <a name="_d06o50mm2fi8" />
        <span className="title">¿Qué son las cookies?</span>
      </h3>

      <p className=" text-justify mb-3">
        <span>
          Las cookies son pequeños ficheros de datos que se almacenan en el
          dispositivo desde el que te conectas a nuestra web. Permiten que el
          sitio recuerde información sobre tu visita, como puede ser el idioma,
          que se recuperará posteriormente para mejorar y facilitar la
          navegación, cómo ya te lo había dicho, solo que lo repito por si te
          sonó a chino.
        </span>
      </p>

      <p className=" text-justify mb-3">
        <span>
          Se asocian únicamente a tu navegador y no proporcionan por sí mismas
          datos personales. Las cookies no pueden dañar tu dispositivo y además
          son muy útiles, ya que nos ayudan a identificar y resolver errores.
        </span>
      </p>

      <p className=" text-justify mb-3">
        <span>
          Esta política puede ser actualizada a medida que nuestras cookies
          evolucionan así que, te sugiero la revises cada cierto tiempo, para
          obtener información actualizada de sus cambios.
        </span>
      </p>

      <h3>
        <a name="_fi221waviow0"></a>
        <span className="title">¿Con qué finalidad usamos las cookies?</span>
      </h3>

      <p className=" text-justify mb-3">
        <u>
          <span>En este sitio web uso las cookies para:</span>
        </u>
      </p>

      <p>
        <span className="bullet">●</span>
        <span>Asegurar que la página web pueda funcionar correctamente.</span>
      </p>

      <p>
        <span className="bullet">●</span>
        <span>
          Recopilar información estadística anónima, como qué páginas has visto
          o cuánto tiempo has estado en el sitio web.
        </span>
      </p>

      <p>
        <span className="bullet">●</span>
        <span>Analizar el comportamiento de las visitas.</span>
      </p>

      <p>
        <span className="bullet">●</span>
        <span>
          Hacer marketing segmentado por intereses en las redes sociales y
          plataformas de publicidad contratada.
        </span>
      </p>

      <p className=" text-justify mb-3">
        <u>
          <span>Clasificación de las cookies:</span>
        </u>
      </p>

      <p>
        <span className="bullet">●</span>
        <span>
          Necesarias: Las cookies necesarias ayudan a hacer una página web
          utilizable activando funciones básicas como la navegación en mi página
          y el acceso a áreas seguras de mi página web. La página web no puede
          funcionar adecuadamente sin estas cookies.
        </span>
      </p>

      <p>
        <span className="bullet">●</span>
        <span>
          Preferencias: Las cookies de preferencias permiten a la página web
          recordar información que cambia la forma en que la página se comporta
          o el aspecto que tiene, como su idioma preferido o la región en la que
          te encuentras
        </span>
      </p>

      <p>
        <span className="bullet">●</span>
        <span>
          Estadísticas: Son aquellas tratadas por terceros, me permiten
          cuantificar el número de usuarios y así realizar la medición y
          análisis estadístico de la utilización que hacen los usuarios de mi
          web y de los servicios ofertados. Para ello se analiza tu navegación
          en mi página web con el fin de mejorar la oferta de productos o
          servicios que ofrezco.
        </span>
      </p>

      <p>
        <span className="bullet">●</span>
        <span>
          Marketing y publicidad: Las cookies de marketing se utilizan para
          rastrear a los visitantes en esta web. La intención es mostrar
          anuncios relevantes y atractivos para el usuario individual, y, por lo
          tanto, más valiosos para los editores y terceros anunciantes.
        </span>
      </p>

      <p className=" text-justify mb-3">
        <span>
          Esta actividad se realiza rastreando los Datos de Uso y utilizando
          Cookies, información que es transferida a los partners que gestionan
          el remarketing y la actividad de remarketing.
        </span>
      </p>

      <p className=" text-justify mb-3">
        <span>
          Además de cualquier opción de “opt out” ofrecida por cualquiera de los
          servicios que se indican a continuación, el Usuario también puede
          optar por la exclusión del uso de cookies por servicios de terceros
          accediendo a la página de exclusión de Network Advertising Initiative.
        </span>
      </p>

      <p className=" text-justify mb-3">
        <u>
          <span>
            Entre las cookies publicitarias utilizadas en esta web están:
          </span>
        </u>
      </p>

      <p className=" text-justify mb-3">
        <span>
          Facebook Ads Remarketing (Facebook, Inc.) Facebook Remarketing es un
          servicio de remarketing prestado por Facebook, Inc. que conecta la
          actividad de esta Aplicación con la red de publicidad de Facebook.
          IPAPPG SAC es usuario de su propia plataforma programada a medida. A
          tales efectos, los usos de tales cookies por los sistemas nunca están
          bajo control o gestión de la responsable de la web, pueden cambiar su
          función en cualquier momento, y entrar cookies nuevas. Estas cookies
          tampoco reportan a la responsable de esta web beneficio alguno.
        </span>
      </p>

      <p className=" text-justify mb-3">
        <span>
          Cookies de redes sociales: Pueden almacenarse en su navegador mientras
          navega por www.ipappg.edu.pe por ejemplo, cuando utiliza el botón de
          compartir contenidos de www.ipappg.edu.pe en alguna red social.
        </span>
      </p>

      <p className=" text-justify mb-3">
        <u>
          <span>
            Las empresas que generan estas cookies correspondientes a las redes
            sociales que utiliza esta web tienen sus propias políticas de
            cookies.
          </span>
        </u>
      </p>

      <p>
        <span className="bullet">●</span>
        <span>Cookie de Twitter, según lo dispuesto en su </span>
        <span>
          <a href="https://twitter.com/es/privacy">
            <span>política de privacidad y uso de cookies.</span>
          </a>
        </span>
      </p>

      <p>
        <span className="bullet">●</span>
        <span>Cookie de Linkedin, según lo dispuesto en su </span>
        <span>
          <a href="https://es.linkedin.com/legal/cookie-policy">
            <span>Política de cookies</span>
          </a>
        </span>
      </p>

      <p>
        <span className="bullet">●</span>
        <span>Cookie de Facebook, según lo dispuesto en su </span>
        <span>
          <a href="https://www.facebook.com/policies/cookies/">
            <span>Política de cookies</span>
          </a>
        </span>
      </p>

      <p>
        <span className="bullet">●</span>
        <span>Cookies de Whatsapp, Según lo dispuesto en su </span>
        <span>
          <a href="https://www.whatsapp.com/privacy">
            <span>Política de cookies</span>
          </a>
        </span>
      </p>

      <p className="mt-3">Para conocer qué políticas uso descargue el PDF.</p>

      <h3>
        <a name="_mlcu4qdi2hyr" />
        <span className="title">
          ¿Cómo administrar las cookies en el navegador?
        </span>
      </h3>

      <p className=" text-justify mb-3">
        <span>
          Si bien es cierto tienes el derecho de elegir desactivar las cookies
          instaladas en tu ordenador, es importante que sepas que al
          desactivarlas, algunas funciones de nuestra web se verán de manera
          alterada.
        </span>
      </p>

      <p>
        <span className="bullet">●</span>
        <span>Cómo desactivarlas si estás en </span>
        <span>
          <a href="https://support.microsoft.com/es-es/windows?ui=es-ES&amp;rs=es-ES&amp;ad=ES">
            <span>Google Chrome</span>
          </a>
        </span>
      </p>

      <p>
        <span className="bullet">●</span>
        <span>Cómo desactivarlas si estás en </span>
        <span>
          <a href="https://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-sitios-web-rastrear-preferencias?redirectlocale=es&amp;redirectslug=habilitar-y-deshabilitar-cookies-que-los-sitios-we">
            <span>Firefox</span>
          </a>
        </span>
      </p>

      <p>
        <span className="bullet">●</span>
        <span>Cómo desactivarlas si estás en </span>
        <span>
          <a href="https://support.apple.com/es-es/HT201265">
            <span>Safari</span>
          </a>
        </span>
      </p>

      <p>
        <span className="bullet">●</span>
        <span>Cómo desactivarlas si estás en </span>
        <span>
          <a href="https://support.microsoft.com/es-es/windows?ui=es-ES&amp;rs=es-ES&amp;ad=ES">
            <span>Internet Explorer</span>
          </a>
        </span>
      </p>

      <p className=" text-justify mt-3 mb-3">
        <span>
          Aprovecho para hacerte recordar que yo, como representante legal, es
          decir, yo, no soy responsable legal ni del contenido, ni de la
          veracidad de las políticas de privacidad que puedan tener los terceros
          mencionados en la tabla de cookies detallada en esta política de
          cookies.
        </span>
      </p>

      <p className=" text-justify mb-3">
        <span>
          Otro aspecto importante que debes entender es que los navegadores web
          son las herramientas encargadas de almacenar las cookies y desde este
          lugar debe efectuar su derecho a eliminación o desactivación de las
          mismas. Ni esta web ni yo puedo garantizar la correcta o incorrecta
          manipulación de las cookies por parte de los mencionados navegadores.
        </span>
      </p>

      <p className=" text-justify mb-3">
        <span>
          En algunos casos es necesario instalar cookies para que el navegador
          no olvide su decisión de no aceptación de las mismas.
        </span>
      </p>

      <p className=" text-justify mb-3">
        <span>
          En el caso de las cookies de Google Analytics, esta empresa almacena
          las cookies en servidores ubicados en Estados Unidos y se compromete a
          no compartirla con terceros, excepto en los casos en los que sea
          necesario para el funcionamiento del sistema o cuando la ley obligue a
          tal efecto.
        </span>
      </p>

      <p className=" text-justify mb-3">
        <span>
          Según Google no guarda su dirección IP. Google Inc. es una compañía
          adherida al Acuerdo de Puerto Seguro que garantiza que todos los datos
          transferidos serán tratados con un nivel de protección acorde a la
          normativa europea.
        </span>
      </p>

      <p className=" text-justify mb-3">
        <span>
          Si desea información sobre el uso que Google da a las cookies le
          adjuntamos este enlace. Debes recordar que siempre puedes permitir,
          bloquear o eliminar las cookies instaladas en tu equipo mediante la
          configuración de las opciones del navegador instalado en tu ordenador.
        </span>
      </p>

      <p className=" text-justify mb-3">
        <span>
          Este sitio web utiliza enlaces de afiliados de algunos productos que
          recomiendo, pero la compra se regula bajo y únicamente las normas de
          las empresas a las cuales pertenecen los links de afiliados.
        </span>
      </p>

      <p className=" text-justify mb-3">
        <span>
          Si tienes alguna pregunta sobre mi política de cookies, la información
          que te he recopilado en línea, las prácticas de mi sitio o sus
          relaciones con este sitio web, contáctame en: Correo electrónico:
          wsucasaire@backofficelima.com.pe
        </span>
      </p>
    </div>
  );
};

export default TermsConditionsIPAPPG;
