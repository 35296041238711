import axios from "axios";
import { useSelector } from "react-redux";
import {
  selectCurrency,
  selectLanguage,
} from "../../../../store/appInfoSlicer";
import { initializeTranslator } from "../../../../helpers/language.helper";
import { useEffect, useState } from "react";
import { companies, paymentMethods, sections } from "../../../../store/enums";
import { fetchGatewayValues } from "../../../../helpers/payment-gateway.helper";
import { getEndpoint } from "../../../../helpers/endpoint.helper";
import { useParams } from "react-router-dom";
import Spinner from "../../../core/Spinner/Spinner";
import departments from "../../../../store/departments";
import RoundButton from "../../../core/RoundButton/RoundButton";
import SelectMenu from "../../../core/SelectMenu/SelectMenu";
import toast from "react-hot-toast";
import {
  filterProvinces,
  generateDaysList,
  generateYearsList,
  isLocationFormValid,
  isPersonalFormValid,
  submitStudent,
  getDepartamentId,
  getProvinceId,
} from "./helpers";
import {
  fetchCourses,
  sortAndFilterCourses,
  filterGiftCourses,
} from "../../../../helpers/courses.helper";
import {
  facebookPixelEvents,
  triggerFacebookPixelEvent,
} from "../../../../helpers/facebook-pixel.helper";
import "./StudentInformationForm.scss";

/**
 * Component
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const StudentInformationForm = (props) => {

  // get route parameters
  const params = useParams();
  // get values from Redux state
  const language = useSelector(selectLanguage);
  const currency = useSelector(selectCurrency);

  // initialize translator with current language
  const t = initializeTranslator(language);

  // ---------------------------------------
  // set state values

  // Flag: component is processing data
  const [isLoading, setIsLoading] = useState(false);

  // student and location data
  const [dniAPI, setDniAPI] = useState("");
  const [studentData, setStudentData] = useState({});
  const [dni, setDni] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [day, setDay] = useState("01");
  const [month, setMonth] = useState("01");
  const [year, setYear] = useState("2021");
  const [department, setDepartment] = useState("");
  const [province, setProvince] = useState("");
  const [giftCourse, setGiftCourse] = useState("");
  const [howDidYouHearAboutUs, setHowDidYouHearAboutUs] = useState("");

  // locations
  const [filteredProvinces, setFilteredProvinces] = useState([]);

  // pages
  const [pageNumber, setPageNumber] = useState(1);

  // buttons
  const [buttonWidth, setButtonWidth] = useState("");

  // courses
  const [courses, setCourses] = useState([]);
  const [filteredCourses, setFilteredCourses] = useState([]);
  const [giftCourses, setGiftCourses] = useState([]);
  const [filteredGiftCourses, setFilteredGiftCourses] = useState([]);
  const [selectedCourseCode, setSelectedCourseCode] = useState("");
  const [isIpappgPlus, setIsIpappgPlus] = useState(false);

  // validation
  const [personalFormIsValid, setPersonalFormIsValid] = useState(false);
  const [mobileError, setMobileError] = useState("");
  const [dniError, setDniError] = useState("");

  // ---------------------------------------
  // Lifecycle hooks

  // execute when component is UPDATED
  useEffect(() => {
    (async () => {
      if (courses.length === 0) {

        let _courses = await fetchCourses(params.company);
        if (_courses) {
          setCourses(_courses.companyCourses);
          setFilteredCourses(sortAndFilterCourses(_courses.companyCourses));
          setGiftCourses(_courses.giftsCourses);
        }
      }
    })();
  }, [props.companyName]);

  // execute when component is UPDATED
  useEffect(() => {
    if (props.centeredButtons) {
      setButtonWidth("20.625rem");
    } else {
      setButtonWidth("100%");
    }
  }, [props.centeredButtons]);

  // form validation when component is UPDATED
  useEffect(() => {

    // validate personal data form
    let valid1 = isPersonalFormValid(dni, firstName, lastName, email, mobile);
    let emailErrorMessage = "";
    if (email) {
      if (!valid1.email) emailErrorMessage = t("emailIsInvalid");
    }

    // validate location data form
    let valid2 = isLocationFormValid(
      filteredGiftCourses,
      department,
      province,
      giftCourse,
      howDidYouHearAboutUs,
      isIpappgPlus
    );

    // if course is required and not set,
    // both forms are considered invalid
    if (props.showCourseSelector) {
      if (!selectedCourseCode) {
        valid1.form = false;
        valid2.form = false;
      }
    }

    // update state

    setMobileError(
      mobile.length > 1 && mobile.length < 9
        ? t("mobileNeedsAtLeatNineDigits")
        : ""
    );

    setDniError(
      dni.length > 1 && dni.length < 8 ? t("dniNeedsAtLeastEightsDigits") : ""
    );

    setPersonalFormIsValid(valid1.form);

    setEmailError(emailErrorMessage);
  }, [
    selectedCourseCode,
    dni,
    firstName,
    lastName,
    email,
    mobile,
    department,
    province,
    giftCourse,
    howDidYouHearAboutUs,
  ]);

  // Hook executed when component is UPDATED
  useEffect(() => {
    initializeFilteredGiftCourses(props.courseCode);
  }, [props.courseCode, giftCourses]);

  // ---------------------------------------
  // Functions

  /**
   * Fetch user data from external webservice
   */
  const onDniChange = async (dni) => {
    // when DNI value is not eight characters long,
    // update component and stop function execution
    if (dni.length !== 8 || isNaN(dni)) {
      setDni(dni);
      // clear name
      setFirstName("");
      setLastName("");
      return;
    }

    // fetch user data
    try {
      // update component state to show spinner
      setIsLoading(true);

      // perform request to external webservice in order
      // to get student information
      let endpoint = getEndpoint("dni").replace("{0}", dni);
      let response = await axios({
        method: "get",
        url: endpoint,
      });

      // update component state to hide spinner
      setIsLoading(false);

      if (response.data.success) {
        const result = response.data;

        // set student name
        setFirstName(result.name);
        setLastName(`${result.surname} ${result.lastname}`);

        // set date of birth
        if (result.dateOfBirth.length === 10) {
          let _dateOfBirth = result.dateOfBirth.split("/");

          if (_dateOfBirth.length === 3) {
            setDay(_dateOfBirth[0]);
            setMonth(_dateOfBirth[1]);
            setYear(_dateOfBirth[2]);
          } else {
            // set current year as default year
            // when date of birth is not available
            setYear(new Date().getFullYear().toString());
          }
        }

        // set location (departament, province, district, address)
        let departmentId = getDepartamentId(result.department);
        let provinceId = getProvinceId(result.province);

        // set department
        setDepartment(departmentId);
        onDepartmentChange(departmentId);
        // set province after a delay, since provinces list
        // are filtered and rendered after department change
        setTimeout(() => {
          setProvince(provinceId);
          onProvinceChange(provinceId);
        }, 1500);
      }
    } catch (ex) {
      // clear name
      setFirstName("");
      setLastName("");
    }

    // update component state
    setDni(dni);
  };

  /**
   * Event handler for CourseChange event
   * @param courseCode
   */
  const onCourseChange = (courseCode) => {

    // 1000, 1001, 1002 are the course codes
    // of IPAPPG PLUS

    let isPlus = [1000, 1001, 1002].includes(+courseCode);
    setIsIpappgPlus(isPlus);

    initializeFilteredGiftCourses(courseCode);
  };

  /**
   * Initialize list of gift courses
   */
  const initializeFilteredGiftCourses = (courseCode) => {
    // update component state
    setSelectedCourseCode(courseCode);

    // filter gift courses
    if (courseCode && giftCourses) {
      let _filteredGiftCourses = filterGiftCourses(
        courseCode,
        courses,
        giftCourses
      );
      setFilteredGiftCourses(_filteredGiftCourses);
    }
  };

  /**
   * Event handler for DepartmentChange event
   */
  const onDepartmentChange = (departmentId) => {
    // filter provinces
    setFilteredProvinces(filterProvinces(departmentId));
    // set selected department
    setDepartment(departmentId);
  };

  /**
   * Event handler for ProvinceChange event
   */
  const onProvinceChange = (provinceId) => {
    // set selected province
    if (provinceId !== studentData.province) {
      setProvince(provinceId);
    }
  };
  /**
   * Event handler for Submit event
   */
  const onSubmit = async (e) => {
    // instance a new Date object with birthdate values,
    // for month substract 1 since months are zero based.
    // Date is going to be converted through a division to
    // Unix timestamp, so add arbitrary time in order to
    // avoid date change when timestamp is rounded
    let birthdate = new Date(+year, +month - 1, +day, 8, 8, 8);
    let birthdateTimestamp = Math.floor(birthdate.getTime() / 1000);

    let student = {
      first_name: firstName,
      last_name: lastName,
      dni: dniAPI,
      mobile: "51 " + mobile, // append Peru's telephone code
      email: email,
      birthdate: birthdateTimestamp,
      department: department,
      province: province,
      gift_course: giftCourse,
      how_did_you_hear_about_us: howDidYouHearAboutUs,
    };

    // submit pre-enrollment values to server, since no operation
    // is gonna be performed for Bank transfer, ignore the response
    if (
      (props.courseCode || selectedCourseCode) &&
      params.section !== sections.completarMatricula
    ) {
      // submit pre-enrollment values to server
      if (props.courseCode) {
        await fetchGatewayValues(
          paymentMethods.transfer,
          currency,
          language,
          props.companyName,
          props.courseCode,
          student,
          () => {},
          props.withInstallments,
          params.installmentNumber,
          props.certificates,
          params.studentId
        );
      }

      // submit pre-enrollment values to server
      if (selectedCourseCode) {
        let selectedCurse = courses.find(
          (c) => +c.code === +selectedCourseCode
        );
        if (selectedCurse) {
          await fetchGatewayValues(
            paymentMethods.transfer,
            currency,
            language,
            props.companyName,
            selectedCurse.code,
            student,
            () => {},
            props.withInstallments,
            params.installmentNumber,
            props.certificates,
            params.studentId
          );
        }
      }
    } else if (params.section === sections.completarMatricula) {
      // submit student values to be updated
      await submitStudent(student, props.companyName);
    }

    // trigger facebook pixel event
    triggerFacebookPixelEvent(
      facebookPixelEvents.botonSiguienteTransferencia,
      props.courseName,
      props.companyName
    );

    // call parent callback
    props.onSubmitData();
  };

  const handleDniChange = (e) => {
    setDniAPI(e.target.value);
    if (e.target.value.length === 8) {
      handleDniCall(e.target.value);
      // configProvince();
      configAge();
    }
  };

  const handleDniCall = async (currentDNI) => {
    // e.preventDefault()
    setIsLoading(true);
    setDni(currentDNI);

    let res = {}
    res.data = {}
    try {
      let endpoint = getEndpoint('dni').replace('{0}', currentDNI);
      res = await axios({
        method: 'get',
        url: endpoint
      });

    } catch (ex) {
      console.log(ex)
    }

    if (res.data.success) {

      const firstname = res.data.data.nombres;
      const lastname = res.data.data.apellido_paterno + " " + res.data.data.apellido_materno
      setFirstName(firstname);
      setLastName(lastname);

      // Set birthdate state

      setDay(res.data.data.fecha_nacimiento.substring(0, 2));
      setMonth(res.data.data.fecha_nacimiento.substring(3, 5));
      setYear(res.data.data.fecha_nacimiento.substring(6, 10));

      setStudentData(res.data.data);
      const departmentFound = await departments.find(
        (data) => data.name.toUpperCase() === res.data.data.departamento
      );
      if (departmentFound) {
        setFilteredProvinces(filterProvinces(departmentFound.id));
        setDepartment(departmentFound.id);
      }

      // Notify user about data filling

      toast.success(`Autocompletado de datos de ${firstname} ${lastname}`, {
        position: "bottom_center",
        duration: 5000,
      });
    }

    // Log error message

    if (!res.data.success) {
      console.log(res.data.message)
    }

    setIsLoading(false);
  }

  const configAge = () => {
    const { fecha_nacimiento: Fecha } = studentData;

    if (Fecha) {
      const Day = Fecha.substring(0, 2);
      setDay(Day);
      const Month = Fecha.substring(3, 5);
      setMonth(Month);
      const Year = Fecha.substring(6, 10);
      setYear(Year);
    }
  };
  // set selected province
  useEffect(() => {
    const provinceFound = filteredProvinces.filter(
      (item) => item.name.toUpperCase().trim() === studentData.provincia
    );
    if (provinceFound) {
      const provinceId = provinceFound.length > 0 ? provinceFound[0].id : "";
      setProvince(provinceId);
    }
  }, [filteredProvinces]);

  useEffect(() => {
    // configProvince();
    configAge();
  }, []);

  return (
    <form className="student-form student-information-form-commponent">
      {/* Loading spinner */}
      {isLoading ? <Spinner /> : null}

      <div>
        {/* ---------------------------------------
            Courses selector
            */}
        {props.showCourseSelector ? (
          <div className="row justify-content-center">
            <div className="col-12 col-lg-6">
              <label>{t("courseDiploma")}</label>
              <div className="mb-4 mt-2">
                <SelectMenu
                  description={t("selectAnOption")}
                  selectedItem={null}
                  optionSelectedCallback={(v) => onCourseChange(v)}
                  filterIsvisible={true}
                  options={filteredCourses}
                />
              </div>
            </div>
          </div>
        ) : null}

        {/* ---------------------------------------
            Personal data
            */}

        <div className={pageNumber === 1 ? "row" : "d-none"}>
          <div className="col-12 col-lg-6 position-relative">
            <label>DNI</label>
            <div className="input-wrapper mb-2 mt-2">
              <input
                type="text"
                name="dni"
                value={dniAPI}
                onChange={handleDniChange}
                placeholder={t("typeHere")}
              />
              <span className="card error">{dniError}</span>
            </div>
            <div className="d-flex">
              <p
                className="text-muted ml-2"
                style={{ fontSize: "10px", marginLeft: "7px" }}
              >
                * Coloca el DNI solicitado y se autorellenarán los datos
                automáticamente.
              </p>
            </div>
          </div>
          <div className="col-12 col-lg-6 position-relative">
            <label>{t("firstName")}</label>
            <div className="input-wrapper mb-4 mt-2">
              <input
                type="text"
                name="first-name"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                placeholder={t("typeHere")}
              />
              <span className="card message error">
                {t("thisValueWillBeUsedInYourCertficate")}
              </span>
            </div>
          </div>
          <div className="col-12 col-lg-6 position-relative">
            <label>{t("lastName")}</label>
            <div className="input-wrapper mb-4 mt-2">
              <input
                type="text"
                name="last-name"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                placeholder={t("typeHere")}
              />
              <span className="card message error">
                {t("thisValueWillBeUsedInYourCertficate")}
              </span>
            </div>
          </div>


          <div className="col-12 col-lg-6 position-relative">
            <label>{t("email")}</label>
            <div className="input-wrapper mb-4 mt-2">
              <input
                type="text"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder={t("typeHere")}
              />
            </div>
            <span className="email error">{emailError}</span>
          </div>
          <div className="col-12 col-lg-6 position-relative">
            <label>{t("mobile")}</label>
            <div className="input-wrapper mb-4 mt-2">
              <input
                type="text"
                name="mobile"
                value={mobile}
                onChange={(e) => setMobile(e.target.value)}
                placeholder={t("typeHere")}
              />
              <span className="card error">{mobileError}</span>
            </div>
          </div>

        </div>
      </div>

      {/* ---------------------------------------
            Buttons wrapper
        */}

      <div className="row buttons-wrapper" style={{ justifyContent: "center" }}>
        <div
          className={
            props.centeredButtons
              ? "col-12 pb-4 pt-4 d-flex justify-content-center"
              : "col-12 pb-4 col-lg-6 pt-4"
          }
        >
          {/* Button to submit student data */}

          {pageNumber === 1 ? (
            <div className={props.centeredButtons ? "order-1" : "order-0"}>
              <RoundButton
                isDisabled={!personalFormIsValid}
                width={buttonWidth}
                onClick={onSubmit}
                content={props.submitDataButtonLabel}
              />
            </div>
          ) : null}
        </div>
      </div>
    </form>
  );
};

export default StudentInformationForm;
