import "./RoundButton.scss";

/**
 * RoundButton component
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const RoundButton = (props) => {
  let style = {
    width: props.width ?? "18rem",
    height: props.height ?? "3rem",
  };

  let classes = props.outline
    ? "round-button-component outline"
    : "round-button-component";

  // return component
  return (
    <button
      className={classes}
      style={style}
      onClick={props.onClick}
      disabled={props.isDisabled ? true : null}
      type="button"
    >
      {props.content}
    </button>
  );
};

export default RoundButton;
