import { useSelector } from "react-redux";
import { selectLanguage } from "../../../store/appInfoSlicer";
import { initializeTranslator } from "../../../helpers/language.helper";
import "./Footer.scss";

/**
 * Component
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const Footer = (props) => {
  // get current year
  const year = new Date().getFullYear();

  // get values from Redux state
  const language = useSelector(selectLanguage);

  // initialize translator with current language
  const t = initializeTranslator(language);

  // CSS classes
  const transparent = "backoffice-footer-component container-lg transparent";
  const filled = "backoffice-footer-component container-lg filled";

  // ---------------------------------------
  // return component

  return (
    <footer className={props.transparent ? transparent : filled}>
      <div className="row">
        <div className="col-12 text-center footer-content">
          <p className="copyright">
            &copy;{year} {props.companyName.toUpperCase()}
          </p>
          <p className="rights-reserved">{t("allRightsReserved")}</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
