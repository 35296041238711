import { initializeTranslator } from "../../../helpers/language.helper";
import { useSelector } from "react-redux";
import { selectLanguage } from "../../../store/appInfoSlicer";
import "./Stepper.scss";
import {useParams} from "react-router-dom";

/**
 * Component
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const Stepper = (props) => {

  // get route parameters
  const params = useParams();

  // set CSS clases according steps count
  let stepOneClasses;
  let stepTwoClasses;
  let stepThreeClasses;
  if (props.steps.length === 2) {
    stepOneClasses = "col-6 p-0 step one";
    stepTwoClasses = "col-6 p-0 step two";
  }

  if (props.steps.length === 3) {
    stepOneClasses = "col-4 p-0 step one";
    stepTwoClasses = "col-4 p-0 step two endline";
    stepThreeClasses = "col-4 p-0 step three";
  }

  return (
    <div className="stepper ms-0 me-0 row justify-content-center">
      {/* First step */}

      <div className={stepOneClasses}>
        {props.step === 1 ? <img src={`imgs/steps/1_active${props.stepSuffix || ''}.svg`} /> : null}
        {props.step !== 1 ? <img src="imgs/steps/ok.svg" /> : null}
        <br />
        <span>{props.steps[0]}</span>
      </div>

      {/* Second step */}

      <div className={stepTwoClasses}>
        {props.step === 1 ? <img src="imgs/steps/2.svg" /> : null}
        {props.step === 2 ? <img src={`imgs/steps/2_active${props.stepSuffix || ''}.svg`} /> : null}
        {props.step === 3 ? <img src="imgs/steps/ok.svg" /> : null}
        <br />
        <span>{props.steps[1]}</span>
      </div>

      {/* Third step is shown only when its label is defined */}

      {props.steps[2] ? (
        <div className={stepThreeClasses}>
          {props.step === 3 ? (
            <img src={`imgs/steps/3_active${props.stepSuffix || ''}.svg`} />
          ) : (
            <img src="imgs/steps/3.svg" />
          )}
          <br />
          <span>{props.steps[2]}</span>
        </div>
      ) : null}
    </div>
  );
};

export default Stepper;
