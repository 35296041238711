import "./Message.scss";
import RoundButton from "../RoundButton/RoundButton";
import { useSelector } from "react-redux";
import {
  selectCurrentPayedCourseInfo,
  selectLanguage,
} from "../../../store/appInfoSlicer";
import { initializeTranslator } from "../../../helpers/language.helper";
import { useEffect, useState } from "react";

export const messageIcons = Object.freeze({
  error: "error",
  check: "check",
});

/**
 * Component
 * @returns {JSX.Element}
 */
const Message = (props) => {
  // get language value from Redux state
  const language = useSelector(selectLanguage);
  const [fialureTransaction, setFialureTransaction] = useState({});
  const [failureTransactionTime, setFailureTransactionTime] = useState("");
  let summary = localStorage.getItem("transaction");
  useEffect(() => {
    setFialureTransaction(JSON.parse(summary));
  }, [summary]);

  const getTransactionDate = () => {
    let date = new Date().toLocaleString("es-PE", { timeZone: "America/Lima" });
    let time = date.substring(0, date.length - 3);
    setFailureTransactionTime(time);
    console.log(time);
  };

  useEffect(() => {
    getTransactionDate();
  }, [fialureTransaction]);

  // initialize translator with current language
  const t = initializeTranslator(language);

  // ---------------------------------------
  // set state values

  const [icon, setIcon] = useState(messageIcons.error);

  // ---------------------------------------
  // lifecycle hooks

  // execute when component is UPDATED
  useEffect(() => {
    // update state
    if (props.icon) setIcon(props.icon);
  }, [props.icon]);

  // ---------------------------------------
  // return component

  return (
    <div className="message-component d-flex justify-content-center">
      <div className="panel align-self-center">
        {props.showCloseButton ? (
          <div onClick={props.onClose} className="close button">
            <img src="imgs/icons/close.svg" alt="" />
          </div>
        ) : null}
        {fialureTransaction && (
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <p
              style={{ color: "#9f9f9f", fontWeight: "bold", fontSize: "15px" }}
            >
              Nro. de Orden:{" "}
              <span style={{ fontStyle: "italic" }}>
                #{fialureTransaction.purchaseNumber}
              </span>
            </p>
            <p
              style={{ color: "#9f9f9f", fontWeight: "bold", fontSize: "15px" }}
            >
              {failureTransactionTime}
            </p>
          </div>
        )}
        <div className="d-flex justify-content-center">
          <div className="icon d-flex justify-content-center align-content-center">
            <img src={`imgs/icons/${icon}.svg`} alt="" />
          </div>
        </div>
        <div className="text-center mt-4">
          <span className="title">{props.title}</span>
        </div>
        <div className="text-center mt-3">
          <p className="description" dangerouslySetInnerHTML={{__html: props.description}}></p>
        </div>

        {/* Ok button is shown only when its callback and text has been set */}
        {props.okCallback && props.okText ? (
          <div className="d-flex justify-content-center mt-5">
            <RoundButton
              onClick={props.okCallback}
              width="80%"
              content={props.okText}
            />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default Message;
