import { useEffect, useState } from "react";
import "./Pagination.scss";

/**
 * Component
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const Pagination = (props) => {
  // ---------------------------------------
  // set state values

  const [pages, setPages] = useState([]);
  const [activePage, setActivePage] = useState(-1);

  // ---------------------------------------
  // lifecycle hooks

  // hook to be executed when
  // component has been updated
  useEffect(() => {
    // generate pages collection
    generatePagesCollection(props.pagesCount, props.activePage, false);

    // update component state
    setActivePage(props.activePage);
  }, [props.pagesCount, props.activePage]);

  // ---------------------------------------
  // functions

  /**
   * Update active page number
   */
  const generatePagesCollection = (
    pagesCount,
    _activePage,
    runCallback = true
  ) => {
    // generate pages collection
    let _pages = [];
    if (pagesCount <= 10) {
      for (let i = 1; i <= pagesCount; i++) {
        _pages.push({
          number: i,
          active: _activePage === i,
        });
      }
    } else {
      let startDotsAdded = false;
      let endDotsAdded = false;
      // when there are more than 10 pages, add only
      // pages from 1 to 4, and the last 4 pages. Also
      // for middle pages add dots, if an active page
      // is in the middle, add its number
      for (let i = 1; i <= pagesCount; i++) {
        if (i <= 4 || i > pagesCount - 4) {
          _pages.push({
            number: i,
            active: _activePage === i,
          });

          if (i === 4) {
            _pages.push({
              number: "...",
              active: false,
            });
          }
        } else {
          // add active page
          if (_activePage === i) {
            _pages.push({
              number: i,
              active: _activePage === i,
            });
          }

          if (i === pagesCount - 4) {
            _pages.push({
              number: "...",
              active: false,
            });
          }
        }
      }
    }

    // update component state
    setPages(_pages);

    // call parent callback
    if (runCallback) props.pageChange(_activePage);
  };

  // ---------------------------------------
  // return component

  return (
    <div className="pagination-component">
      {/* Previous page button */}
      <button
        type="button"
        onClick={() => generatePagesCollection(pages.length, activePage - 1)}
        className={activePage === 1 ? "previous inactive" : "previous"}
      >
        <img src="imgs/icons/arrow-left-black.svg" alt="" />
      </button>

      {/* Pages list */}
      <ul className="pages">
        {pages.map((p, i) => {
          return p.number !== "..." ? (
            <li
              onClick={() => generatePagesCollection(pages.length, p.number)}
              key={i}
              className={p.active ? "active" : ""}
            >
              {p.number}
            </li>
          ) : (
            <li key={i}>...</li>
          );
        })}
      </ul>

      {/* Next page button */}
      <button
        type="button"
        onClick={() => generatePagesCollection(pages.length, activePage + 1)}
        className={props.pagesCount === activePage ? "next inactive" : "next"}
      >
        <img src="imgs/icons/arrow-right-black.svg" alt="" />
      </button>
    </div>
  );
};

export default Pagination;
