import {useSelector} from "react-redux";
import {selectCurrency, selectLanguage,} from "../../../../store/appInfoSlicer";
import {initializeTranslator} from "../../../../helpers/language.helper";
import {useEffect, useState} from "react";
import RoundButton from "../../../core/RoundButton/RoundButton";
import "./CertificateSelector.scss";

/**
 * Component
 * @returns {JSX.Element}
 * @constructor
 */
const CertificateSelector = ({
  closeModal,
  closeModalAfterCertificateChoosen,
  certificates,
  symbol,
  chosenCertificates,
}) => {
  // ! Sort prices lower to higher
  certificates = certificates.sort((a, b) => a.price - b.price);

  // get values from Redux state
  const language = useSelector(selectLanguage);
  useSelector(selectCurrency);

// initialize translator with current language
  const t = initializeTranslator(language);

  // ---------------------------------------
  // set state values

  const [menuIsVisible, setMenuIsVisible] = useState(false);
  const [certificatesIds, setCertificatesIds] = useState([]);

  // ---------------------------------------
  // lifecycle hooks

  // execute when component has been UPDATED
  useEffect(() => {
    if (chosenCertificates.length > 0) setCertificatesIds(chosenCertificates);
  }, [chosenCertificates]);

  // ---------------------------------------
  // Functions

  const saveChosenCertificates = () => {
    closeModalAfterCertificateChoosen(certificatesIds);
  };

  /**
   * Add certificate id to chosenCertificates array
   * @param certificateId
   */
  const addIdToChosenCertificates = (certificateId) => {
    // add id if no exists
    if (!certificatesIds.includes(certificateId)) {
      // add id
      setCertificatesIds([...certificatesIds, certificateId]);
    } else {
      // remove id
      setCertificatesIds(
        certificatesIds.filter((id) => {
          return id !== certificateId;
        })
      );
    }

    // update state
    setMenuIsVisible(false);
  };

  // ---------------------------------------
  // Return component

  return (
    <div className="certificate-selector-component">
      <form className="default-form">
        <div className="col-12 text-center title-description">
          <h2>{t("chooseCertificate")}</h2>
          <p>{t("chooseMultipleCertificates")}</p>
        </div>

        <div className="selector-wrapper">
          <span className="label">{t("certificate")}</span>

          {/* Certificate selector button */}

          <div className="certificate-selector">
            <div
              onClick={() => setMenuIsVisible(!menuIsVisible)}
              className="description-wrapper"
            >
              {/* description text */}
              {certificatesIds.length === 0 ? (
                <div className="description">{t("selectOptions")}</div>
              ) : null}

              {/* Button to clear selected items */}
              {certificatesIds.length > 0 ? (
                <button type="button" className="button clear-selected">
                  <span className="count">{certificatesIds.length}</span>
                  {t("selected")}
                  <img
                    onClick={() => setCertificatesIds([])}
                    src="imgs/icons/close-red.svg"
                    alt=""
                  />
                </button>
              ) : null}

              {/* arrows */}
              <img
                className="arrow down"
                src="imgs/icons/arrow-down.png"
                alt=""
              />
              <img className="arrow up" src="imgs/icons/arrow-up.png" alt="" />
            </div>

            {/* Certificate selector menu */}

            <ul
              className="menu"
              style={{ display: menuIsVisible ? "block" : "none" }}
            >
              {certificates.map(c => {
                return (<>
                  {c.is_public
                  ? <li key={+c.id}>
                      <span className="name">
                        <input
                            type="checkbox"
                            onChange={() =>
                                addIdToChosenCertificates(
                                    +c.id
                                )
                            }
                            checked={certificatesIds.includes(
                                +c.id
                            )}
                            className="form-check-input red certificate"
                        />
                        {c.name}
                      </span>
                            <span className="price">
                        {symbol} {c.price.toFixed(2)}
                      </span>
                    </li>
                  : null}
                </>)
              })}
            </ul>
          </div>

          <RoundButton
            onClick={() => saveChosenCertificates()}
            isDisabled={false}
            width={"100%"}
            height={"3rem"}
            outline={false}
            content={t("saveChanges")}
          />
        </div>
      </form>
    </div>
  );
};

export default CertificateSelector;
