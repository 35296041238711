import "./Content.scss";
import "./animations.css";
import Items from "../Items/Items";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import PaymentSelector, { tabs } from "../PaymentSelector/PaymentSelector";
import PaymentWithoutCard from "../PaymentWithoutCard/PaymentWithoutCard";
import PaymentWithCard from "../PaymentWithCard/PaymentWithCard";
import Spinner from "../../core/Spinner/Spinner";
import Message from "../../core/Message/Message";
import TermsModal from "../TermsAndConditions/Modal/TermsModal";
import { useSelector } from "react-redux";
import {
  selectCurrency,
  selectLanguage,
  selectPaymentMethod,
} from "../../../store/appInfoSlicer";
import { initializeTranslator } from "../../../helpers/language.helper";
import StudentFormAndBankAccounts from "../StudentFormAndBankAccounts/StudentFormAndBankAccounts";
import { currencies } from "../../../store/enums";

/** CSS id of active payment information block */
let paymentMethodCSSId = "";

/**
 * Resize wrapper according its content height
 */

/**
 * Execute a callback after a delay
 * @param func
 * @returns {(function(*=): void)|*}
 */
const debounce = (func) => {
  let timer;
  return function (event) {
    if (timer) clearTimeout(timer);
    timer = setTimeout(func, 100, event);
  };
};

/**
 * Content component
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const Content = (props) => {
  
  const [paymentData, setPaymentData] = useState(false);
  useEffect(() => {
    setPaymentData(paymentData)
  }, [paymentData]);
  const resizePaymentInformationWrapper = () => {
    setTimeout(() => {
      // update wrapper height
      let paymentMethodEl = document.getElementById(paymentMethodCSSId);
      if (paymentMethodEl) {
        const cardHeight = paymentMethodEl.clientHeight;
        const wrapper = document.querySelector(".payment-information-wrapper");
        if (!paymentData) {
          wrapper.style.height = `${cardHeight + 120}px`;
        } else if(paymentData) {
          wrapper.style.height = `${cardHeight}px`;
        }
      }
    }, 100);
  };
  // get route parameters
  const params = useParams();

  // get values from Redux state
  const language = useSelector(selectLanguage);
  const currency = useSelector(selectCurrency);

  // initialize translator with current language
  const t = initializeTranslator(language);

  // ---------------------------------------
  // set state values

  // tabs for payment methods
  const [paymentMethodsTabs, setPaymentMethodsTabs] = useState([]);

  useEffect(() => {

  }, [paymentData]);

  // CSS classes for animation
  const [cardAnimationClasses, setCardAnimationClasses] = useState([]);
  const [withoutCardAnimationClasses, setWithoutCardCardAnimationClasses] =
    useState([]);
  const [transferAnimationClasses, setTransferAnimationClasses] = useState([]);
  const [activePaymentMethodTab, setActivePaymentMethodTab] = useState("");
  // loading flag
  const [isLoading, setIsLoading] = useState(false);
  // notification message
  const [errorMessageIsVisible, setErrorMessageIsVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  // terms and conditions
  const [termsAreVisible, setTermsAreVisible] = useState(false);
  // installments and certificates
  const [withInstallments, setWithInstallments] = useState(false);
  const [certificates, setCertificates] = useState([]);
  // prices
  const [priceIsEditable, setPriceIsEditable] = useState(true);

  // ---------------------------------------
  // functions

  // update CSS classes to start animations in tabs content
  const activateTab = (tab, removePrevious) => {
    // when tab is already active, stop method execution
    if (tab === activePaymentMethodTab) return;

    // remove previous tabs
    if (removePrevious) {
      setCardAnimationClasses(["animate-slide-out-down"]);
      setWithoutCardCardAnimationClasses(["animate-slide-out-down"]);
      setTransferAnimationClasses(["animate-slide-out-down"]);
    }

    // resize wrapper according its content height
    switch (tab) {
      case tabs.withCard:
        paymentMethodCSSId = "payment-with-card";
        resizePaymentInformationWrapper();
        setCardAnimationClasses(["animate-slide-in-up"]);
        break;
      case tabs.withoutCard:
        paymentMethodCSSId = "payment-without-card";
        resizePaymentInformationWrapper();
        setWithoutCardCardAnimationClasses(["animate-slide-in-up"]);
        break;
      case tabs.transfer:
        paymentMethodCSSId = "payment-transfer";
        resizePaymentInformationWrapper();
        setTransferAnimationClasses(["animate-slide-in-up"]);
        break;
    }

    // set active payment method
    setActivePaymentMethodTab(tab);

    // make price editable
    setPriceIsEditable(true);
  };

  // ---------------------------------------
  // lifecycle hooks

  // execute when component is MOUNTED
  useEffect(() => {
    // update size of payment wrapper
    paymentMethodCSSId = "payment-with-card";
    resizePaymentInformationWrapper();

    // set payment method tabs according current currency

    if (currency === currencies.usd || currency === currencies.mxn) {
      setPaymentMethodsTabs([tabs.withCard]);
    }

    if (currency === currencies.pen) {
      setPaymentMethodsTabs([tabs.withCard, tabs.withoutCard, tabs.transfer]);
    }
  }, []);

  // ---------------------------------------
  // callbacks for props

  const showTerms = () => setTermsAreVisible(true);
  const showSpinner = () => setIsLoading(true);
  const hideSpinner = () => setIsLoading(false);
  const hideTerms = () => setTermsAreVisible(false);
  const hideErrorMessage = () => setErrorMessageIsVisible(false);
  const showNotification = (message) => {
    setErrorMessage(message);
    setErrorMessageIsVisible(true);
  };

  // ---------------------------------------
  // functions

  /**
   * Update state with data from Child component
   */
  const updateInstallmentsAndCertificates = (
    _certificates,
    _withInstallments
  ) => {
    setCertificates(_certificates);
    setWithInstallments(_withInstallments);
  };

  // ---------------------------------------
  // event handlers

  window.removeEventListener(
    "resize",
    debounce(resizePaymentInformationWrapper)
  );
  window.addEventListener("resize", debounce(resizePaymentInformationWrapper));

  // return component
  return (
    <article className="content-component font-main mt-5">
      {/* Loading spinner */}
      {isLoading ? <Spinner /> : null}

      {/* Content */}
      <div className="container-xl">
        <div className="row">
          {/* -----------------------------------------
            Payment information
          */}
          <div className="col-12 col-md-6 col-lg-6 col-xl-7 m-0 p-1 order-last order-md-first payment-information-wrapper">
            {/*  Wrapper for payment with card */}
            <div
              style={{ position: "absolute", opacity: 1 }}
              id="payment-with-card"
              className={cardAnimationClasses}
            >
              {/* Payment method selector */}
              <PaymentSelector
                activeTab={tabs.withCard}
                tabs={paymentMethodsTabs}
                onCardClick={() => activateTab(tabs.withCard, true)}
                onWithoutCardClick={() => activateTab(tabs.withoutCard, true)}
                onTransferClick={() => activateTab(tabs.transfer, true)}
              />

              {/* Form for payments with card */}
              <PaymentWithCard
                paymentData={setPaymentData}
                price={props.price}
                company={params.company}
                courseName={props.name}
                courseCode={params.courseCode}
                course={props.course}
                withInstallments={withInstallments}
                certificates={certificates}
                showNotification={showNotification}
                showTerms={showTerms}
                showSpinner={showSpinner}
                hideSpinner={hideSpinner}
                onFormSizeChange={resizePaymentInformationWrapper}
                onLockPrice={() => {
                  setPriceIsEditable(false);
                }}
                onTransactionFinished={props.onTransactionFinished}
              />
            </div>

            {/* Wrapper for payment without card */}
            <div
              style={{ position: "absolute", opacity: 0 }}
              id="payment-without-card"
              className={withoutCardAnimationClasses}
            >
              {/* Payment method selector */}
              <PaymentSelector
                activeTab={tabs.withoutCard}
                tabs={paymentMethodsTabs}
                onCardClick={() => activateTab(tabs.withCard, true)}
                onWithoutCardClick={() => activateTab(tabs.withoutCard, true)}
                onTransferClick={() => activateTab(tabs.transfer, true)}
              />

              {/* Information for payment without card */}
              <PaymentWithoutCard
                companyName={params.company}
                courseName={props.name}
                courseCode={params.courseCode}
                course={props.course}
                withInstallments={withInstallments}
                certificates={certificates}
                showSpinner={showSpinner}
                hideSpinner={hideSpinner}
                showNotification={showNotification}
                onCipCodeGenerated={props.onCipCodeGenerated}
                onSizeChange={resizePaymentInformationWrapper}
              />
            </div>

            {/* Wrapper for payment with bank transfer */}
            <div
              style={{ position: "absolute", opacity: 0 }}
              id="payment-transfer"
              className={transferAnimationClasses}
            >
              {/* Payment method selector */}
              <PaymentSelector
                activeTab={tabs.transfer}
                tabs={paymentMethodsTabs}
                onCardClick={() => activateTab(tabs.withCard, true)}
                onWithoutCardClick={() => activateTab(tabs.withoutCard, true)}
                onTransferClick={() => activateTab(tabs.transfer, true)}
              />

              {/* Payment information for payment via bank transfer */}
              {activePaymentMethodTab === tabs.transfer ? (
                <StudentFormAndBankAccounts
                  showTermsConditions={true}
                  showBankAccounts={true}
                  courseName={props.name}
                  companyName={params.company}
                  courseCode={params.courseCode}
                  course={props.course}
                  withInstallments={withInstallments}
                  certificates={certificates}
                  onLockPrice={() => {
                    setPriceIsEditable(false);
                  }}
                  onSizeChange={resizePaymentInformationWrapper}
                />
              ) : null}
            </div>
          </div>

          {/* -----------------------------------------
            Line division
          */}
          <div className="d-none d-lg-flex col-lg-1 justify-content-center">
            <hr className="vertical-line" width="1" />
          </div>

          {/* -----------------------------------------
            Card items
          */}
          <div className="col-12 col-md-6 col-lg-5 col-xl-4 order-first order-md-last">
            <Items
              onUpdateInstallmentsAndCertificates={
                updateInstallmentsAndCertificates
              }
              course={props.course}
              isEditable={priceIsEditable}
            />
          </div>
        </div>
      </div>

      {/* error message */}
      {errorMessageIsVisible ? (
        <Message
          title={t("aProblemHasOccurred")}
          description={errorMessage}
          closeCallback={hideErrorMessage}
          okText={t("tryAgain")}
          okCallback={() => {
            window.location.reload(true)
            localStorage.removeItem("transaction");
          }}
        />
      ) : null}

      {/* terms and conditions */}
      {termsAreVisible ? (
        <TermsModal companyName={props.companyName} hide={hideTerms} />
      ) : null}
    </article>
  );
};

export default Content;
